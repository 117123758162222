import moment from 'moment';

const transactionTransformer = (transaction) => {
  const {
    amount,
    comment,
    timestamp,
    status,
  } = transaction.data();

  return {
    uuid: transaction.id,
    name: 'Placeholder',
    amount,
    comment,
    timestamp: moment(timestamp.seconds * 1000),
    status,
  };
};

export default transactionTransformer;
