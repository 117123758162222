import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import AppText from './AppText';

const styles = makeStyles((theme) => ({
  card: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    alignItems: 'center',
    textAlign: 'center',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(),
  },
  icon: {
    width: 80,
    height: 80,
  },
  title: {
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(),
    textAlign: 'center',
  },
  content: {
    color: theme.palette.text.secondary,
    textAlign: 'justify',
  },
}));

const AboutCard = ({ icon, content }) => {
  const classes = styles();
  const { t } = useTranslation();
  return (
    <div className={classes.card}>
      <div className={classes.iconContainer}>
        <img className={classes.icon} src={icon} alt={`${content}.title`} />
      </div>
      <AppText className={classes.title} header variant="h6">{ t(`${content}.title`) }</AppText>
      <AppText className={classes.content} subtitle variant="body1">{ t(`${content}.content`) }</AppText>
    </div>
  );
};

AboutCard.propTypes = {
  icon: PropTypes.node.isRequired,
  content: PropTypes.string.isRequired,
};

export default AboutCard;
