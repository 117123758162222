import React from 'react';
import PropTypes from 'prop-types';
import StoryCardTitle from '../../StoryCardTitle';

const LeaderBoardItemTitle = ({ className, text }) => (
  <StoryCardTitle className={className} text={text} />
);

LeaderBoardItemTitle.defaultProps = {
  className: null,
};

LeaderBoardItemTitle.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  text: PropTypes.string.isRequired,
};

export default LeaderBoardItemTitle;
