import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  Card,
  CardMedia,
} from '@material-ui/core';

const styles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(4),
  },
  card: {
    display: 'inline-block',
    width: 300,
    background: '#1C1C1C',
    marginRight: theme.spacing(2)
  },
}));

const AuthorVideos = ({ videos }) => {
  const classes = styles();

  // temporarily set videos to another source.
  videos = [
    'https://res.cloudinary.com/dwz4eycy1/video/upload/v1564789068/videos/the_green_tea_master_1080.mp4'
  ];

  return (
    <div className={classes.container}>
      {videos.map(url => (
        <Card className={classes.card}>
          <CardMedia
            className={classes.cardVideo}
            component="video"
            src={url}
            controls
          />
        </Card>
      ))}
    </div>
  );
};

AuthorVideos.defaultProps = {
  videos: [],
};

AuthorVideos.propTypes = {
  videos: PropTypes.array,
};

export default AuthorVideos;
