import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  InputLabel,
  FormControl,
  Select as MaterialSelect,
  MenuItem, makeStyles,
} from '@material-ui/core';

const styles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    marginTop: theme.spacing(2),
  },
}));

const Select = ({
  className,
  label,
  onChange,
  options,
  placeholder,
  value,
  renderItem,
  disabled,
  disableUnderline,
  ...other
}) => {
  const classes = styles();

  if (label) {
    return (
      <FormControl
        className={classNames([classes.formControl, className])}
        disabled={disabled}
      >
        <InputLabel shrink>{label}</InputLabel>
        <MaterialSelect
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...other}
          onChange={(e) => onChange(e.target.value)}
          value={value}
          displayEmpty
        >
          {placeholder && (
            <MenuItem value="" disabled>
              {placeholder}
            </MenuItem>
          )}
          {options.map((item) => (renderItem ? renderItem(item) : (
            <MenuItem key={item} value={item}>{item}</MenuItem>
          )))}
        </MaterialSelect>
      </FormControl>
    );
  }

  return (
    <MaterialSelect
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
      className={className}
      onChange={!disabled ? (e) => onChange(e.target.value) : null}
      value={value}
      disableUnderline={disableUnderline}
      displayEmpty
    >
      {placeholder && (
        <MenuItem value="" disabled>
          {placeholder}
        </MenuItem>
      )}
      {options.map((item) => (renderItem ? renderItem(item) : (
        <MenuItem key={item} value={item}>{item}</MenuItem>
      )))}
    </MaterialSelect>
  );
};

Select.defaultProps = {
  placeholder: null,
  className: null,
  label: null,
  renderItem: null,
  disabled: false,
  disableUnderline: false,
};

Select.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  placeholder: PropTypes.string,
  renderItem: PropTypes.func,
  disabled: PropTypes.bool,
  disableUnderline: PropTypes.bool,
};

export default Select;
