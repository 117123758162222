import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AppText from '../AppText';

const styles = makeStyles(() => ({
  text: {
    fontWeight: 600,
    color: '#151515',
  },
}));

const StoryContentHeader = ({ className, text }) => {
  const { t } = useTranslation();
  const classes = styles();
  return (
    <AppText
      className={classNames([classes.text, className])}
      variant="body2"
      header
    >
      { t(text) }
    </AppText>
  );
};

StoryContentHeader.defaultProps = {
  className: null,
};

StoryContentHeader.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
};

export default StoryContentHeader;
