import React, { forwardRef, useEffect, useState } from 'react';
import { storage } from 'firebase';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import Header from '../Header';
import Constants from '../../constants';

const firebaseStorage = storage();

const styles = makeStyles((theme) => ({
  container: {
    marginLeft: 'auto',
    marginRight: 'auto',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: 1,
    position: 'relative',
    display: 'block',
    flexDirection: 'column',
    marginTop: Constants.headerHeight,
    paddingBottom: theme.spacing(4),
  },
  bgProps: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center top',
    backgroundSize: 'contain',
    [theme.breakpoints.down('sm')]: {
      backgroundSize: '200%',
    },
  },
}));


const StandardLayout = forwardRef(({ background, banner, children }, ref) => {
  const classes = styles();
  const backgroundString = background && background();
  const [bannerString, setBannerString] = useState(null);

  useEffect(() => {
    if (banner && banner.indexOf('http') < 0) {
      // Get image because partial url
      const getImage = async () => {
        const url = await firebaseStorage.ref(
          banner,
        ).getDownloadURL();

        if (url) {
          setBannerString(url);
        }
      };

      getImage();
    }
  }, [banner]);

  return (
    <div
      className={classNames([
        classes.container,
        classes.bgProps,
      ])}
      style={{
        backgroundImage: `url('${bannerString || backgroundString}')`,
      }}
    >
      <Header />
      <div ref={ref} className={classes.content}>
        { children }
      </div>
    </div>
  );
});

StandardLayout.defaultProps = {
  background: null,
  banner: null,
};

StandardLayout.propTypes = {
  background: PropTypes.func,
  banner: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default StandardLayout;
