import { actions } from '../actions/Reader';

const initialState = {
  loading: false,
  uuid: null,
  story: null,
  chapterReference: null,
  previousChapter: null,
  chapter: null,
  nextChapter: null,
};

const readerReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case actions.STORY.LOAD:
      return {
        ...state,
        story: action.payload,
        chapter: null,
      };
    case actions.CHAPTER.LOAD:
      return {
        ...state,
        loading: false,
        uuid: action.payload.chapter.id,
        chapterReference: action.payload.chapterReference,
        previousChapter: action.payload.previousChapter,
        chapter: action.payload.chapter,
        nextChapter: action.payload.nextChapter,
      };
    case actions.CHAPTER.NEXT:
    case actions.CHAPTER.PREVIOUS:
      return {
        ...state,
        loading: true,
      };
    case actions.CHAPTER.GOTO:
    case actions.CHAPTER.REFERENCE:
      return {
        ...state,
        loading: true,
        previousChapter: null,
        chapter: null,
        nextChapter: null,
      };
    default:
      return state;
  }
};

export default readerReducer;
