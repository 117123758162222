import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Card, CardContent, makeStyles,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import StoryContentHeader from '../StoryContentHeader';
import StoryChaptersRow from './StoryChaptersRow';
import Loading from '../../Loading';
import StoryChaptersEmpty from './StoryChaptersEmpty';

const styles = makeStyles((theme) => ({
  card: {
    boxShadow: '0px 3px 10px #00000029',
    position: 'relative',
    padding: 0,
  },
  content: {
      paddingTop: theme.spacing(3),
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
  },
  chapters: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '-12px'
  },
  header: {
      marginBottom: theme.spacing(),
  },
}));

const StoryChapters = ({ className, storyId, chapters }) => {
  const classes = styles();
  return (
    <Card className={classNames([classes.card, className])}>
      <CardContent className={classes.content}>
        <StoryContentHeader className={classes.header} text="story.chapters" />
        <Grid className={classes.chapters} spacing={1} container>
          { !chapters && <Loading /> }
          { chapters && chapters.map((snapshot) => {
            const { id } = snapshot;
            const { title, number, created_at: createdAt } = snapshot.data();
            return (
              <Grid item xs={12} sm={4} md={4}>
                <StoryChaptersRow
                  key={number}
                  storyId={storyId}
                  id={id}
                  title={title}
                  number={number}
                  createdAt={createdAt}
                />
              </Grid>
            );
          })}
          { chapters && chapters.length === 0 && <StoryChaptersEmpty /> }
        </Grid>
      </CardContent>
    </Card>
  );
};

StoryChapters.defaultProps = {
  className: null,
  chapters: null,
};

StoryChapters.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  storyId: PropTypes.string.isRequired,
  chapters: PropTypes.array,
};

export default StoryChapters;
