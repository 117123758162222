import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormLabel, makeStyles, Dialog } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AppText from '../AppText';
import UploadZone from '../UploadZone';
import UploadFilesRow from './UploadFilesRow';
import UploadFilesHeader from './UploadFilesHeader';
import UploadFilesPreview from './UploadFilesPreview';
import Constants from '../../constants';

const regex = /[0-9]{1,2}/g;

const chapterParser = (name) => {
  const found = name.match(regex);
  return found[0] ? parseInt(found[0], 10).toString() : null;
};

const useStyles = makeStyles((theme) => ({
  upload: {
    marginTop: theme.spacing(2),
  },
  uploaded: {
    fontFamily: '\'Montserrat\', sans-serif',
    fontSize: '0.85em',
    color: '#151515',
    marginTop: theme.spacing(5),
  },
  header: {
    fontFamily: '\'Montserrat\', sans-serif',
    fontWeight: 600,
    fontSize: '0.85em',
    color: '#151515',
  },
  rows: {
    display: 'flex',
    flexDirection: 'column',
  },
  warning: {
    marginTop: theme.spacing(2),
    color: '#C83232',
  },
}));

const UploadFiles = ({
  files, setFiles, errors, warning,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [preview, setPreview] = useState(null);

  const onChangeFile = (newFile, index) => {
    const newFiles = [...files];
    newFiles[index] = newFile;
    setFiles(newFiles);
  };

  const removeFile = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  return (
    <div>
      <Dialog
        onClose={() => setPreview(null)}
        open={preview !== null}
        scroll="body"
        maxWidth="lg"
        fullWidth
      >
        <div>
          { preview && (
            <UploadFilesPreview
              source={preview}
            />
          )}
        </div>
      </Dialog>
      <FormLabel className={classes.header} required>
        {t('discussions.upload')}
      </FormLabel>
      <UploadZone
        className={classes.upload}
        setFiles={(newFiles) => {
          const newArray = newFiles.map((file) => ({
            name: file.name,
            captions: '',
            file,
          }));
          setFiles(newArray);
        }}
        mimeTypes={Constants.materials}
      />
      {warning && (
        <AppText className={classes.warning} variant="caption">
          { warning }
        </AppText>
      )}
      {files.length > 0 && (
        <div className={classes.uploaded}>
          <FormLabel
            className={classes.header}
            style={{ fontSize: '1em' }}
            required
          >
            {t('publish.fields.upload_documents')}
          </FormLabel>
          <div className={classes.rows}>
            <UploadFilesHeader />
            {files.map((file, index) => (
              <UploadFilesRow
                key={file.name}
                index={index}
                file={file}
                onChange={(newFile) => onChangeFile(newFile, index)}
                remove={() => removeFile(index)}
                alternate={index % 2 === 1}
                errors={errors[index]}
                setPreview={setPreview}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

UploadFiles.defaultProps = {
  errors: {},
  warning: null,
};

UploadFiles.propTypes = {
  setFiles: PropTypes.func.isRequired,
  files: PropTypes.array.isRequired,
  warning: PropTypes.string,
  errors: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default UploadFiles;
