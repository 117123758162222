import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import StandardLayout from '../components/Layout/StandardLayout';
import StoryTitle from '../components/Story/StoryTitle';
import Loading from '../components/Loading';
import { storiesItemGet } from '../providers/redux/actions/Stories';
import ButtonBack from '../components/Button/ButtonBack';
import StorySummary from '../components/Story/StorySummary';
import StoryChapters from '../components/Story/StoryChapters';
import StoryCover from '../components/Story/StoryCover';
import ButtonRead from '../components/Button/ButtonRead';
import ResponsiveWrapper from '../components/ResponsiveWrapper';
import {
  readerStoryLoad,
} from '../providers/redux/actions/Reader';
import Constants from '../constants';
import useRouter from '../services/useRouter';
import StoryAuthors from '../components/Story/StoryAuthors';
import Rating from '../components/Rating/Rating';
import AppText from '../components/AppText';
import ButtonShareWithIcon from '../components/Button/ButtonShareWithIcon';
import ButtonBookmarkWithIcon
  from '../components/Button/ButtonBookmarkWithIcon';
import ButtonRateWithIcon from '../components/Button/ButtonRateWithIcon';

const styles = makeStyles((theme) => ({
  header: {
    overflow: 'hidden',
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(5.5),
      paddingBottom: theme.spacing(20.5),
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      paddingTop: theme.spacing(30),
      paddingBottom: theme.spacing(15),
    },
  },
  authors: {
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(),
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  back: {
    [theme.breakpoints.up('sm')]: {
      marginBottom: 68.47,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  headerContent: {
    position: 'relative',
  },
  content: {
    backgroundColor: theme.palette.page.main,
  },
  meta: {
    display: 'inline-block',
    marginTop: '1.63rem',
  },
  title: {
    marginTop: theme.spacing(1.5),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.5rem',
      marginBottom: theme.spacing(),
    },
  },
  summary: {
    position: 'relative',
    top: -theme.spacing(12.5),
    marginBottom: theme.spacing(4),
  },
  chapters: {
    top: -theme.spacing(12.5),
  },
  readButton: {
    display: 'inline-flex',
    marginBottom: theme.spacing(),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(2),
      paddingLeft: 118,
      paddingRight: 118,
    },
  },
  cover: {
    flex: 1,
  },
  space: {
    flex: 3,
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      flexDirection: 'row',
    },
  },
  ratings: {
    padding: 2,
  },
  views: {
    fontWeight: 'bold',
    color: theme.palette.common.white,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(),
    lineHeight: '1.5rem',
  },
  stats: {
    background: 'rgba(255,255,255,.11)',
    borderRadius: 4,
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(),
    marginBottom: theme.spacing(),
  },
  favouriteIcon: {
    fontSize: '1.5rem',
    marginRight: theme.spacing(),
    color: theme.palette.common.white,
  },
  interactionText: {
    fontSize: '1.125rem',
    lineHeight: '1.38rem',
    color: theme.palette.common.white,
  },
  interactions: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: -theme.spacing(2),
      marginRight: -theme.spacing(2),
    },
    boxSizing: 'border-box',
    display: 'none',
    flex: 1,
    flexDirection: 'row',
  },
  interactionButton: {
    boxSizing: 'border-box',
    flex: 1,
  },
}));

const StoryScreen = ({
  match,
}) => {
  const dispatch = useDispatch();
  const { params: { uuid } } = match;
  const classes = styles();
  const [chapters, setChapters] = useState(null);
  const { history } = useRouter();
  const { lastRead, loading, story } = useSelector((state) => ({
    lastRead: state.storiesReducer.lastRead,
    loading: state.storiesReducer.item.loading,
    story: state.storiesReducer.item.data,
  }));

  const {
    title,
    authors,
    genres,
    synopsis,
    chapters: chaptersCollection,
  } = story || {};

  useEffect(() => {
    dispatch(readerStoryLoad(story));
  }, [dispatch, story]);

  useEffect(() => {
    if (chaptersCollection) {
      const unsubscribe = chaptersCollection.orderBy('number', 'desc').onSnapshot(
        (snapshot) => setChapters(snapshot.docs),
      );
      return () => {
        unsubscribe();
      };
    }

    return undefined;
  }, [chaptersCollection]);

  useEffect(() => {
    dispatch(storiesItemGet({ uuid }));
  }, [uuid]);

  if (!story || loading) {
    return (
      <StandardLayout>
        <Loading size="large" />
      </StandardLayout>
    );
  }

  const startReading = () => {
    if (lastRead) {
      history.push(`${Constants.routes.Reader}/${uuid}/${lastRead.id}`);
    } else if (chapters && chapters.length > 0) {
      history.push(`${Constants.routes.Reader}/${uuid}/${chapters[0].id}`);
    }
  };

  return (
    <StandardLayout>
      <div className={classes.header}>
        <StoryCover uuid={uuid} />
        <ResponsiveWrapper className={classes.headerContent} maxWidth={1490}>
          <ButtonBack
            className={classes.back}
            white
          />
          <StoryTitle className={classes.title} title={title} />
          <div className={classes.stats}>
            <Rating className={classes.ratings} ratings={5} size={20} />
              {/*<AppText className={classes.views}>1,400,294 views</AppText>*/}
          </div>
          <StoryAuthors className={classes.authors} authors={authors} />
          <div className={classes.meta}>
            <div className={classes.actions}>
              <ButtonRead
                className={classes.readButton}
                onPress={startReading}
                continueReading={lastRead !== null}
              />
            </div>
            <div className={classes.interactions}>
              <ButtonShareWithIcon
                className={classes.interactionButton}
                onPress={() => {}}
              />
              <ButtonBookmarkWithIcon
                className={classes.interactionButton}
                onPress={() => {}}
              />
              <ButtonRateWithIcon
                className={classes.interactionButton}
                onPress={() => {}}
              />
            </div>
          </div>
        </ResponsiveWrapper>
      </div>
      <div className={classes.content}>
        <ResponsiveWrapper maxWidth={1490}>
          <StorySummary
            className={classes.summary}
            synopsis={synopsis}
            genres={genres}
          />
          <StoryChapters
            className={classes.chapters}
            storyId={uuid}
            chapters={chapters}
          />
        </ResponsiveWrapper>
      </div>
    </StandardLayout>
  );
};

StoryScreen.propTypes = {
  match: PropTypes.object.isRequired,
};

export default StoryScreen;
