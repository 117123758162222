import {
  fork, all,
} from 'redux-saga/effects';
import { firestore } from 'firebase';

import firebase from '../../firebase';
import {
  putGenres,
} from '../actions/Genres';
import Constants from '../../../constants';

const transformer = (collection) => {
  const data = [];
  collection.forEach(async (genre) => {
    const {
      created_on: createdOn,
    } = genre.data();

    data.push({
      name: genre.id,
      createdOn,
    });
  });

  return [...data];
};

const collection = firestore().collection(Constants.firebase.collections.genres);

export default function* Genres() {
  yield all([
    fork(
      firebase.firestore.syncCollection,
      collection.orderBy(firestore.FieldPath.documentId(), 'asc'),
      {
        successActionCreator: putGenres,
        transform: transformer,
      },
    ),
  ]);
}
