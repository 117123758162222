import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  CardContent,
  CardHeader,
  makeStyles,
  Popover,
  IconButton,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Close } from '@material-ui/icons';
import ButtonShareWithIcon from '../Button/ButtonShareWithIcon';
import ButtonBookmarkWithIcon from '../Button/ButtonBookmarkWithIcon';
import ButtonRateWithIcon from '../Button/ButtonRateWithIcon';
import ButtonDonate from '../Button/ButtonDonate';
import ShareDialog from '../Share/ShareDialog';
import Rating from '../Rating/Rating';
import Loading from '../Loading';
import { getPaymentMethods } from '../../providers/redux/actions/Payment';

const useStyles = makeStyles((theme) => ({
  container: {
    background: '#212121',
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      paddingTop: theme.spacing(),
      paddingBottom: theme.spacing(),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  actions: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  support: {
    marginLeft: theme.spacing(4),
    background: '#5D9140',
    textTransform: 'uppercase',
    color: theme.palette.common.white,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(),
    },
  },
  action: {
    marginTop: 0,
    marginRight: 0,
    marginLeft: theme.spacing(2),
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem',
    },
  },
}));

const ReaderActionBar = ({ author, authorName }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [ratingAnchor, setRatingAnchor] = useState(null);

  const [open, setOpen] = useState(false);

  const handleClose = () => {
      setOpen(false);
  };

  const share = () => setOpen(true);

  const bookmark = () => { };

  const rate = (e) => setRatingAnchor(e.currentTarget);

  const closeRating = () => setRatingAnchor(null);

  const {
    paymentLoading, paymentMethodResponse,
  } = useSelector(
    (state) => ({
      paymentLoading: state.paymentsReducer.payment_loading,
      paymentMethodResponse: state.paymentsReducer.payment_methods,
    }),
  );

  useEffect(() => {
    dispatch(getPaymentMethods());
  }, []);

  if (!author || paymentLoading) {
    return (
      <Loading />
    );
  }

  return (
    <div className={classes.container}>
      <Popover
        open={ratingAnchor !== null}
        anchorEl={ratingAnchor}
        onClose={closeRating}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <CardHeader
          title={t('Please rate this title')}
          action={(
            <IconButton onClick={closeRating}>
              <Close />
            </IconButton>
          )}
          classes={{
            action: classes.action,
          }}
        />
        <CardContent>
          <Rating ratings={5} />
        </CardContent>
      </Popover>
      <ShareDialog open={open} handleClose={handleClose} />
      <div className={classes.actions}>
        <ButtonShareWithIcon className={classes.button} onPress={share} />
          {/*<ButtonBookmarkWithIcon className={classes.button} onPress={bookmark} />
        <ButtonRateWithIcon className={classes.button} onPress={rate} />*/}
      </div>
      <ButtonDonate authorName={authorName} paymentMethodResponse={paymentMethodResponse} authorId={author.id} />
    </div>
  );
};

ReaderActionBar.defaultProps = {
  author: null,
  authorName: '',
};

ReaderActionBar.propTypes = {
  author: PropTypes.object,
  authorName: PropTypes.string,
};

export default ReaderActionBar;
