export const actions = {
  GET: 'GENRES_GET',
  PUT: 'GENRES_PUT',
  RESET: 'GENRES_RESET',
};

export const getGenres = () => ({
  type: actions.GET,
});

export const resetGenres = () => ({
  type: actions.RESET,
});

export const putGenres = (genres) => ({
  type: actions.PUT,
  payload: genres,
});
