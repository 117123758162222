import React from 'react';
import PropTypes from 'prop-types';
import { BookmarkBorder } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';
import Button from './index';
import AppText from '../AppText';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(),
    color: theme.palette.common.white,
  },
  text: {
      fontSize: '1rem',
      lineHeight: '1.38rem',
      color: theme.palette.common.white,
  },
}));

const ButtonBookmarkWithIcon = ({ className, onPress }) => {
  const classes = useStyles();
  return (
    <Button className={className} onPress={onPress}>
      <BookmarkBorder className={classes.icon} />
      <AppText className={classes.text}>Bookmark</AppText>
    </Button>
  );
};

ButtonBookmarkWithIcon.defaultProps = {
  className: null,
};

ButtonBookmarkWithIcon.propTypes = {
  className: PropTypes.string,
  onPress: PropTypes.func.isRequired,
};

export default ButtonBookmarkWithIcon;
