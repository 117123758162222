import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import Toolbar from '@material-ui/core/Toolbar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AppText from '../AppText';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  toolbar: {
    paddingLeft: 0,
  },
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    fontWeight: 'bold',
    color: '#151515',
  },
  description: {
    color: '#656565',
    marginLeft: theme.spacing(2),
  },
}));

const List = ({
  className, title, header, description, actions, children,
}) => {
  const classes = useStyles();
  return (
    <div className={className}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.header}>
          <div className={classes.container}>
            <AppText className={classes.title} variant="h5" header>
              { title }
            </AppText>
            <AppText className={classes.description} variant="caption">
              { description }
            </AppText>
          </div>
          { actions && (
            <div className={classes.actions}>
              { actions }
            </div>
          )}
        </div>
      </Toolbar>
      <Table>
        {header}
        {children}
      </Table>
    </div>
  );
};

List.defaultProps = {
  className: null,
  description: null,
  actions: null,
};

List.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  header: PropTypes.node.isRequired,
  actions: PropTypes.node,
  children: PropTypes.node.isRequired,
};

export default List;
