import { fork, all, takeLatest, takeEvery } from 'redux-saga/effects';
import firebase from '../../firebase';
import {
  actions,
  createWithdrawalHistoryRequestSuccess
} from '../actions/Withdrawal';

const historyTransformer = collection => {
  const data = [];
  collection.forEach(transactionHistory =>
    data.push(transactionHistory.data())
  );
  return [...data];
};

function* createWithdrawalSaga({ payload }) {
  yield fork(
    firebase.firestore.addDocument,
    `/withdrawals/${payload.user_id}/transactions`,
    {
      ...payload
    }
  );
}

function* createWithdrawalHistorySaga({ payload }) {
  yield fork(
    firebase.firestore.syncCollection,
    `/withdrawals/${payload}/transactions`,
    {
      successActionCreator: createWithdrawalHistoryRequestSuccess,
      transform: historyTransformer
    }
  );
}

export default function* Withdrawal() {
  yield all([
    takeEvery(actions.WITHDRAWAL.REQUEST, createWithdrawalSaga),
    takeEvery(actions.WITHDRAWAL_HISTORY.REQUEST, createWithdrawalHistorySaga)
  ]);
}
