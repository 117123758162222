import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import StatisticsCard from '../../StatisticsCard';
import BadgeGenre from '../../Badge/BadgeGenre';
import AppText from '../../AppText';
import DraftImages from '../../DraftImages';
import LeaderBoardItemTitle from './LeaderBoardItemTitle';
import '../../../providers/firebase';
import useRouter from '../../../services/useRouter';
import Button from '../../Button';
import Constants from '../../../constants';

const styles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    borderBottom: '1px solid #484848',
  },
  number: {
    color: theme.palette.common.white,
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
  },
  draft: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  synopsis: {
    marginBottom: theme.spacing(5),
  },
  text: {
    marginTop: theme.spacing(1),
    color: '#B2B2B2',
  },
  author: {
    marginBottom: theme.spacing(2),
  },
  genre: {
    padding: theme.spacing(),
    fontSize: 13,
  },
  pledge: {
    paddingLeft: theme.spacing(6),
  },
  read: {
    display: 'block',
    background: 'transparent',
  },
  images: {
    flex: 1,
  },
}));

const LeaderBoardItem = ({
  uuid, number, amount, goal, genres, title, author, synopsis,
}) => {
  const { history } = useRouter();
  const { t } = useTranslation();
  const classes = styles();

  const onPress = () => history.push(`${Constants.routes.Draft}/${uuid}`);

  return (
    <div className={classes.container}>
      <AppText className={classes.number} header variant="h5">
        {number}
      </AppText>
      <div className={classes.content}>
        <div className={classes.draft}>
          <DraftImages className={classes.images} uuid={uuid} />
          <StatisticsCard
            className={classes.pledge}
            value={(
              <NumberFormat
                prefix="$"
                decimalScale={2}
                fixedDecimalScale
                value={amount}
                displayType="text"
                thousandSeparator
              />
            )}
            description={t('pledged', { goal })}
          />
        </div>
        <Button className={classes.read} onPress={onPress}>
          <LeaderBoardItemTitle text={title} />
          <AppText className={classNames([classes.text, classes.synopsis])} variant="body1">
            { synopsis }
          </AppText>
          <AppText className={classNames([classes.text, classes.author])}>
            {t('written_by', { author })}
          </AppText>
          {genres.map((genre) => (
            <BadgeGenre key={genre} className={classes.genre} text={genre} />
          ))}
        </Button>
      </div>
    </div>
  );
};

LeaderBoardItem.defaultProps = {
  genres: [],
};

LeaderBoardItem.propTypes = {
  uuid: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
  amount: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  goal: PropTypes.number.isRequired,
  synopsis: PropTypes.string.isRequired,
  genres: PropTypes.array,
};

export default LeaderBoardItem;
