import { actions } from '../actions/Payment';

const initialState = {
  payment_loading: true,
  payment_methods: null,
  transaction: {
    message: null,
    processing: false,
    completed: false,
    errors: {},
  },
};

const paymentsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case actions.GET_PAYMENT_METHODS:
      return {
        ...state,
        payment_loading: true,
      };
    case actions.PUT_PAYMENT_METHODS:
      return {
        ...state,
        payment_loading: false,
        payment_methods: action.payload,
      };
    case actions.TRANSACTION.RESET:
      return {
        ...state,
        transaction: {
          message: null,
          processing: false,
          completed: false,
          errors: {},
        },
      };
    case actions.TRANSACTION.REQUEST:
      return {
        ...state,
        transaction: {
          ...state.transaction,
          message: null,
          processing: true,
        },
      };
    case actions.TRANSACTION.SUCCESS:
      return {
        ...state,
        transaction: {
          ...state.transaction,
          message: action.payload,
          processing: false,
          completed: true,
          errors: {},
        },
      };
    case actions.TRANSACTION.ERRORS:
      return {
        ...state,
        transaction: {
          ...state.transaction,
          message: action.payload.message,
          processing: false,
          completed: true,
          errors: action.payload.errors,
        },
      };
    default:
      return state;
  }
};

export default paymentsReducer;
