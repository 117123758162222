import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Card, CardContent, makeStyles } from '@material-ui/core';
import StorySynopsis from './StorySynopsis';

const styles = makeStyles((theme) => ({
  container: {
    boxShadow: '0px 3px 10px #00000029',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingTop: theme.spacing(3),
    marginBottom: theme.spacing(),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  synopsis: {
    flex: 1,
  },
  author: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(4),
    },
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(3),
      marginRight: theme.spacing(7),
      maxWidth: 240,
      borderRight: '1px solid #EAEAEA',
    },
  },
}));

const StorySummary = ({
  className, synopsis, genres,
}) => {
  const classes = styles();
  return (
    <Card className={classNames([classes.container, className])}>
      <CardContent className={classes.content}>
        <StorySynopsis
          className={classes.synopsis}
          genres={genres}
          synopsis={synopsis}
        />
      </CardContent>
    </Card>
  );
};

StorySummary.defaultProps = {
  className: null,
  genres: [],
};

StorySummary.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  synopsis: PropTypes.string.isRequired,
  genres: PropTypes.array,
};

export default StorySummary;
