import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
 Grid, Dialog, IconButton, CircularProgress 
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import Input from '../Input/Input';
import AppText from '../AppText';
import AdyenCheckout from '../AdyenCheckout/AdyenCheckout';
import { transactionCreateReset } from '../../providers/redux/actions/Payment';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  title: {
    fontWeight: 700,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(0.5),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  proceedButton: {
    width: 120,
    backgroundColor: '#397c15',
    fontSize: '0.7em',
    fontFamily: "'Montserrat', sans-serif",
    padding: theme.spacing(1),
    textTransform: 'uppercase',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#e14d81',
    },
    float: 'right',
  },
  input: {
    marginTop: 0,
    '& .MuiInput-root': {
      fontSize: 14,
      color: '#8E8E8E',
      fontFamily: "'Montserrat', sans-serif",
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#8E8E8E',
    },
    '& .MuiInputLabel-root': {
      fontSize: 14,
      color: '#8E8E8E',
      fontFamily: "'Montserrat', sans-serif",
    },
    '& input': {
      color: '#8E8E8E',
      padding: '0 0 7px',
    },
  },
  toggleButton: {
    borderRadius: 4,
    marginRight: 5,
    borderTopLeftRadius: '4px !important',
    borderBottomLeftRadius: '4px !important',
    borderTopRightRadius: '4px !important',
    borderBottomRightRadius: '4px !important',
    borderLeft: '1px solid rgba(0, 0, 0, 0.12) !important',
    height: 36,
    '& .MuiToggleButton-label': {
      width: 35,
    },
  },
  seperator: {
    marginTop: 14,
    textAlign: 'center',
  },
  label: {
    color: '#8e8e8e',
  },
  errorText: {
    color: '#E86868',
    marginTop: -20,
  },
  choiceAmountContainer: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(2),
    },
  },
  hidden: {
    display: 'none',
  },
  containerSize: {
    width: 588,
    height: 433,
  },
  containerCenter: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  gridCenter: {
    textAlign: 'center',
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  loading: {
    zIndex: 20,
    position: 'absolute',
    left: '46%',
    top: '50%',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const {
 children, classes, onClose, ...other 
} = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <AppText className={classes.title} variant="h6">
        {children}
      </AppText>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderTop: 'none',
    borderBottom: 'none',
  },
}))(MuiDialogContent);

const DonationDialog = withStyles(styles)((props) => {
  const {
    open,
    classes,
    handleClose,
    authorName,
    authorId,
    paymentMethodResponse,
  } = props;
  const { t } = useTranslation();

  const [amount, setAmount] = useState(0);
  const [mode, setMode] = useState('form');
  const [comment, setComment] = useState('');
  const handleAmount = (event, newValue) => {
    setAmount(parseInt(newValue));
  };

  const dispatch = useDispatch();

  const handleReset = () => {
    setAmount(0);
    setMode('form');
    setComment('');
    dispatch(transactionCreateReset());
  };

  const onClosing = () => {
    handleReset();
    handleClose();
  };

  const { processing, completed, transaction } = useSelector((state) => ({
    processing: state.paymentsReducer.transaction.processing,
    completed: state.paymentsReducer.transaction.completed,
    transaction: state.paymentsReducer.transaction,
  }));

  if (processing && !completed) {
    if (mode !== 'processing') {
      setMode('processing');
    }
  } else if (!processing && completed) {
    if (mode !== 'completed') {
      setMode('completed');
    }
  } else if (mode !== 'form') {
    setMode('form');
  }

  const filterTextAmount = (newValue) => {
    newValue = newValue.replace(/[^\d.]/g, '').trim();
    setAmount(newValue);
  };

  return (
    <Dialog
      onClose={onClosing}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle id="customized-dialog-title" onClose={onClosing}>
        {t('donation.support')} 
        {' '}
        {authorName}
      </DialogTitle>
      <DialogContent dividers>
        <CircularProgress
          className={mode === 'processing' ? classes.loading : classes.hidden}
        />
        <div
          className={
            mode === 'form' || mode === 'processing' ? '' : classes.hidden
          }
        >
          <Grid
            className={mode === 'form' ? '' : classes.disabled}
            container
            spacing={1}
          >
            <Grid item xs={12} sm={5} md={5}>
              <Input
                variant="outlined"
                label={t('donation.enter_amount')}
                className={classes.input}
                value={amount}
                onChangeText={filterTextAmount}
                fullWidth
                adornment="$"
              />
              {amount < 5 ? (
                <AppText className={classes.errorText} variant="subtitle1">
                  {t('donation.minimum_amount')}
                  {' '}
                  $ 5
                </AppText>
              ) : null}
            </Grid>
            <Grid item xs={12} sm={1} md={1}>
              <AppText className={classes.seperator} variant="body1">
                {t('donation.or')}
              </AppText>
            </Grid>
            <Grid
              className={classes.choiceAmountContainer}
              item
              xs={12}
              sm={6}
              md={6}
            >
              <AppText className={classes.label} variant="subtitle1">
                {t('donation.select_options')}
              </AppText>
              <ToggleButtonGroup
                value={amount.toString()}
                exclusive
                onChange={handleAmount}
              >
                <ToggleButton value="5" className={classes.toggleButton}>
                  <span>$ 5</span>
                </ToggleButton>
                <ToggleButton value="10" className={classes.toggleButton}>
                  <span>$ 10</span>
                </ToggleButton>
                <ToggleButton value="50" className={classes.toggleButton}>
                  <span>$ 50</span>
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Input
                variant="outlined"
                label={t('donation.comment')}
                className={classes.input}
                value={comment}
                onChangeText={setComment}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <AdyenCheckout
                paymentMethodResponse={paymentMethodResponse}
                amount={amount}
                comment={comment}
                authorId={authorId}
              />
            </Grid>
          </Grid>
        </div>
        <div className={mode === 'completed' ? '' : classes.hidden}>
          <Grid
            className={
              mode === 'completed'
              && transaction.message.resultCode === 'Authorised'
                ? ''
                : classes.hidden
            }
            container
            spacing={1}
          >
            <Grid className={classes.gridCenter} item xs={12} sm={12} md={12}>
              <AppText variant="h5">{t('donation.success')}</AppText>
            </Grid>
            <Grid className={classes.gridCenter} item xs={12} sm={12} md={12}>
              <AppText variant="body1">
                {t('donation.success_sub', {
                  value: `USD ${amount}`,
                  name: authorName,
                })}
              </AppText>
            </Grid>
          </Grid>

          <Grid
            className={
              mode === 'completed'
              && transaction.message.resultCode !== 'Authorised'
                ? ''
                : classes.hidden
            }
            container
            spacing={1}
          >
            <Grid className={classes.gridCenter} item xs={12} sm={12} md={12}>
              <AppText variant="h5">{t('donation.error')}</AppText>
            </Grid>
            <Grid className={classes.gridCenter} item xs={12} sm={12} md={12}>
              <AppText variant="body1">{t('donation.error_sub')}</AppText>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
    </Dialog>
  );
});

DonationDialog.defaultProps = {
  paymentMethodResponse: null,
};

DonationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  authorName: PropTypes.string.isRequired,
  authorId: PropTypes.string.isRequired,
  paymentMethodResponse: PropTypes.object,
};

export default DonationDialog;
