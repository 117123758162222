import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { IconButton, makeStyles } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import useRouter from '../../services/useRouter';

const styles = makeStyles((theme) => ({
  button: {
    padding: 0,
    borderRadius: '50%',
    backgroundColor: 'transparent',
  },
  icon: {
    fontSize: '2.25rem',
  },
  iconBlack: {
    color: theme.palette.common.black,
  },
  white: {
    color: theme.palette.common.white,
  },
}));

const ButtonBack = ({ className, onPress, white }) => {
  const classes = styles();
  const { history } = useRouter();
  const back = () => history.goBack();
  return (
    <IconButton
      href={null}
      className={
        classNames([
          classes.button,
          className,
        ])
      }
      onClick={onPress || back}
    >
      <ArrowBack
        className={
          classNames([
            classes.icon,
            white && classes.white,
          ])
        }
      />
    </IconButton>
  );
};

ButtonBack.defaultProps = {
  onPress: null,
  className: null,
  white: false,
};

ButtonBack.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onPress: PropTypes.func,
  white: PropTypes.bool,
};

export default ButtonBack;
