import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import {
  Drawer, Divider, IconButton, List, ListItem, ListItemText,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Button from '../Button';
import { logout } from '../../providers/redux/actions/User';
import useRouter from '../../services/useRouter';

import {
  siteLoginClose,
} from '../../providers/redux/actions/Site';
import Constants from '../../constants';

const drawerWidth = 240;
const styles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    overflow: 'auto',
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    width: '100%',
  },
  link: {
    fontFamily: '\'Montserrat\', sans-serif',
    color: theme.palette.common.black,
    fontSize: '0.8125rem'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  hide: {
    display: 'none',
  },
  button: {
    background: 'transparent',
  },
}));

const HeaderUserMobile = ({ user, loggedIn, logout: logoutAction }) => {
  const dispatch = useDispatch();
  const classes = styles();
  const { i18n, t } = useTranslation();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const isDesktop = useMediaQuery({ minWidth: Constants.minWidth });

  useEffect(() => {
    if (loggedIn) {
      dispatch(siteLoginClose());
    }
  }, [loggedIn, dispatch]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const onSwitch = () => {
    if (i18n.language === 'en') {
      i18n.changeLanguage('jp');
    } else {
      i18n.changeLanguage('en');
    }
  };

  if (!loggedIn || !user) {
    return (
      <div
        ref={anchorRef}
        className={classNames([
          classes.container,
          classes[`containerSpace${isDesktop ? 'M' : ''}`],
        ])}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="end"
          onClick={handleDrawerOpen}
          className={clsx(open && classes.hide)}
        >
          <MenuIcon />
        </IconButton>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
          <List>
            <ListItem button component={Link} className={classes.link} key={t('login.title')} to={Constants.routes.Login}>
              <ListItemText disableTypography className={classes.link} primary={t('login.title')} />
            </ListItem>
          </List>
          <Divider />
          <List>
            {/*<ListItem button component={Link} className={classes.link} key={t('about.title')} to={Constants.routes.About}>
              <ListItemText disableTypography className={classes.link} primary={t('about.title')} />
            </ListItem>*/}
            <ListItem button component={Link} className={classes.link} key={t('links.discussion')} to={Constants.routes.Discussion}>
              <ListItemText disableTypography className={classes.link} primary={t('links.discussion')} />
            </ListItem>
            <ListItem button component={Link} className={classes.link} key={t('links.akademy')} to={Constants.routes.Akademy}>
                <ListItemText disableTypography className={classes.link} primary={t('links.akademy')} />
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem button component={Button} onPress={() => onSwitch()} className={classes.link} key={t('switch')}>
              <ListItemText disableTypography className={classes.link} primary={t('switch')} />
            </ListItem>
          </List>
        </Drawer>
      </div>
    );
  }

  // const { displayName, email, photoUrl: image } = user;
  return (
    <div
      ref={anchorRef}
      className={classNames([
        classes.container,
        classes[`containerSpace${isDesktop ? 'M' : ''}`],
      ])}
    >
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="end"
        onClick={handleDrawerOpen}
        className={clsx(open && classes.hide)}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
            {/*<ListItem button component={Link} className={classes.link} key={t('about.title')} to={Constants.routes.About}>
            <ListItemText disableTypography className={classes.link} primary={t('about.title')} />
          </ListItem>*/}
          <ListItem button component={Link} className={classes.link} key={t('links.discussion')} to={Constants.routes.Discussion}>
            <ListItemText disableTypography className={classes.link} primary={t('links.discussion')} />
          </ListItem>
          <ListItem button component={Link} className={classes.link} key={t('links.akademy')} to={Constants.routes.Akademy}>
            <ListItemText disableTypography className={classes.link} primary={t('links.akademy')} />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button component={Link} className={classes.link} key={t('profile.title')} to={Constants.routes.Profile}>
            <ListItemText disableTypography className={classes.link} primary={t('profile.title')} />
          </ListItem>
          <ListItem button component={Button} onPress={() => onSwitch()} className={classes.link} key={t('switch')}>
            <ListItemText disableTypography className={classes.link} primary={t('switch')} />
          </ListItem>
          <ListItem button key={t('logout')} className={classes.link} onClick={() => logoutAction()}>
            <ListItemText disableTypography className={classes.link} primary={t('logout')} />
          </ListItem>
        </List>
      </Drawer>
    </div>
  );
};

HeaderUserMobile.defaultProps = {
  user: null,
};

HeaderUserMobile.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  user: PropTypes.object,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loggedIn: state.userReducer.loggedIn,
  user: state.userReducer.user,
});

export default connect(mapStateToProps, { logout })(HeaderUserMobile);
