import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import { KeyboardArrowLeftRounded } from '@material-ui/icons';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: 30,
    color: '#B9B9B9',
  },
  iconDisabled: {
    color: '#414141',
  },
}));

const ButtonArrowLeft = ({ className, disabled, onPress }) => {
  const classes = useStyles();
  return (
    <IconButton
      className={className}
      href={null}
      onClick={onPress}
    >
      <KeyboardArrowLeftRounded
        className={classNames([
          classes.icon,
          disabled && classes.iconDisabled,
        ])}
      />
    </IconButton>
  );
};

ButtonArrowLeft.defaultProps = {
  className: null,
  disabled: false,
};

ButtonArrowLeft.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onPress: PropTypes.func.isRequired,
};

export default ButtonArrowLeft;
