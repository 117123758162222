import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles, IconButton } from '@material-ui/core';
import {
  ThumbUp,
  ThumbDown,
} from '@material-ui/icons';
import AppText from '../AppText';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'none',
    flexDirection: 'row',
    alignItems: 'center',
  },
  count: {
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
  },
  white: {
    color: '#B9B9B9',
  },
  countWhite: {
    color: '#8E8E8E',
  },
}));

const Votes = ({
  className, count, voteUp, voteDown, white,
}) => {
  const classes = useStyles();
  return (
    <div className={classNames([classes.container, className])}>
      <IconButton onClick={voteUp}>
        <ThumbUp className={white ? classes.white : null} />
      </IconButton>
      <AppText
        className={
          classNames([
            classes.count,
            white && classes.countWhite,
          ])
        }
      >
        {count}
      </AppText>
      <IconButton onClick={voteDown}>
        <ThumbDown className={white ? classes.white : null} />
      </IconButton>
    </div>
  );
};

Votes.defaultProps = {
  className: null,
  white: false,
};

Votes.propTypes = {
  className: PropTypes.string,
  count: PropTypes.number.isRequired,
  voteUp: PropTypes.func.isRequired,
  voteDown: PropTypes.func.isRequired,
  white: PropTypes.bool,
};

export default Votes;
