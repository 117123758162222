import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  makeStyles, Hidden, Grid, FormControl, Select, InputLabel, FormLabel,
} from '@material-ui/core';
import Input from '../Input/Input';
import InputTextArea from '../Input/InputTextArea';
import { userUpdateRequest } from '../../providers/redux/actions/User';
import { siteUpdatePWOpen } from '../../providers/redux/actions/Site';
import Button from '../Button';
import Loading from '../Loading';
import Snackbar from '../Snackbar';
import AppText from '../AppText';
import UserUpdatePassword from '../User/UserUpdatePassword';
import useProtectRoute from '../../providers/hooks/useProtectRoute';
import countries from '../../constants/countries';
import UploadImages from '../UploadImages';

const styles = makeStyles((theme) => ({
  container: {
    width: '100%',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(1.5),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(),
      paddingRight: theme.spacing(),
      paddingTop: theme.spacing(),
      paddingBottom: theme.spacing(),
    },
  },
  header: {
    marginTop: theme.spacing(5.5),
    paddingBottom: theme.spacing(20.5),
  },
  content: {
    backgroundColor: '#EFEFEF',
  },
  contentContainer: {
    borderRadius: 16,
    position: 'relative',
    top: -theme.spacing(12.5),
    marginBottom: theme.spacing(4),
    minHeight: 973,
    height: '100%',
  },
  page: {
    padding: theme.spacing(4),
  },
  accountForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  input: {
    marginTop: 0,
  },
  tabs: {
    padding: theme.spacing(4),
    textAlign: 'center',
  },
  tab: {
    background: 'transparent',
    fontSize: 18,
  },
  countryLabel: {
    backgroundColor: '#fff',
    padding: '0 5px',
  },
  passwordContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 14,
    alignItems: 'center',
  },
  passwordLabel: {
    letterSpacing: '0.00938em',
    transformOrigin: 'top left',
    transform: 'scale(0.75)',
  },
  buttonContainer: {
    marginTop: theme.spacing(2),
    display: 'inline-block',
    float: 'right',
  },
  resetButton: {
    marginRight: theme.spacing(2),
  },
  button: {
    flex: 1,
    maxWidth: 240,
  },
  sectionTitle: {
    marginBottom: theme.spacing(2),
  },
  label: {
    fontFamily: '\'Montserrat\', sans-serif',
    fontSize: '0.85em',
  },
  inputUpload: {
    marginTop: -11,
  },
}));

const defaultDob = '2019-01-01';

const ProfileTab = ({
  user,
  profile,
  processing,
  success,
  message,
  // errors,
  userUpdateRequest: userUpdateRequestAction,
}) => {
  useProtectRoute();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = styles();

  const {
    displayName: userDisplayName,
    email,
  } = user || {};

  const {
    firstName: profileFirstName,
    middleName: profileMiddleName,
    lastName: profileLastName,
    dob: profileDOB,
    country: profileCountry,
    bio: profileBio,
  } = profile || {};

  const [displayName, setDisplayName] = useState(userDisplayName);
  const [firstName, setFirstName] = useState(profileFirstName);
  const [middleName, setMiddleName] = useState(profileMiddleName);
  const [lastName, setLastName] = useState(profileLastName);
  const [dob, setDob] = useState(profileDOB || defaultDob);
  const [country, setCountry] = useState(profileCountry);
  const [bio, setBio] = useState(profileBio);
  const [bannerImage, setBannerImage] = useState([]);
  const [profileImage, setProfileImage] = useState([]);

  useEffect(() => {
    setDisplayName(userDisplayName);
    setFirstName(profileFirstName);
    setMiddleName(profileMiddleName);
    setLastName(profileLastName);
    setDob(profileDOB || defaultDob);
    setCountry(profileCountry);
    setBio(bio);
  }, [user, profile]);

  const submit = () => userUpdateRequestAction({
    displayName, firstName, middleName, lastName, dob, country, bio, bannerImage, profileImage,
  });

  const updatePWOpen = () => dispatch(siteUpdatePWOpen());

  const handleChange = (event) => {
    setCountry(event.target.value);
  };

  const countryOptions = countries ? Object.entries(countries).map(
    ([key, { name }]) => (
      <option key={name} value={key}>
        {name}
      </option>
    ),
  ) : [];

  return (
    <div>
      <Snackbar
        variant={success ? 'success' : 'error'}
        message={message}
      />
      <Grid className={classes.container} container spacing={2}>
        <Grid item xs={12}>
          <AppText variant="h5" className={classes.sectionTitle} header>
            {t('profile.private')}
          </AppText>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Input
            label={t('forms.user.first_name')}
            className={classes.input}
            onChangeText={setFirstName}
            value={firstName}
            fullWidth
            margin="normal"
            variant="outlined"
            invert
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Input
            label={t('forms.user.middle_name')}
            className={classes.input}
            onChangeText={setMiddleName}
            value={middleName}
            fullWidth
            margin="normal"
            variant="outlined"
            invert
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Input
            label={t('forms.user.last_name')}
            className={classes.input}
            onChangeText={setLastName}
            value={lastName}
            fullWidth
            margin="normal"
            variant="outlined"
            invert
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Input
            disabled
            label={t('forms.user.email')}
            className={classes.input}
            onChangeText={null}
            value={email}
            fullWidth
            margin="normal"
            variant="outlined"
            invert
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Input
            label={t('forms.user.dob')}
            className={classes.input}
            onChangeText={setDob}
            value={dob}
            margin="normal"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            date
            invert
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <FormControl
            fullWidth
            variant="outlined"
          >
            <InputLabel htmlFor="country-select">
              <AppText className={classes.countryLabel} subtitle>{t('forms.user.country')}</AppText>
            </InputLabel>
            <Select
              native
              value={country}
              onChange={handleChange}
            >
              <option value="" />
              {countryOptions}
            </Select>
          </FormControl>
        </Grid>
        <Grid className={classes.passwordContainer} item md={4} sm={6} xs={12}>
          <FormControl>
            <FormLabel className={classes.passwordLabel}>
              <AppText subtitle>{t('forms.user.password')}</AppText>
            </FormLabel>
            <UserUpdatePassword />
            {processing
              ? null
              : (
                <Button
                  variant="contained"
                  color="primary"
                  onPress={updatePWOpen}
                  className={classes.resetButton}
                >
                  {t('profile.updatePw')}
                </Button>
              )}
          </FormControl>
        </Grid>
      </Grid>
      <Grid className={classes.container} container spacing={2}>
        <Grid item xs={12}>
          <AppText header variant="h5" className={classes.sectionTitle}>{t('profile.public')}</AppText>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <FormControl className={classes.inputUpload}>
            <FormLabel className={classes.label}>{t('forms.user.banner_image')}</FormLabel>
            <UploadImages
              setFiles={setBannerImage}
              files={bannerImage}
            />
          </FormControl>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <FormControl className={classes.inputUpload}>
            <FormLabel className={classes.label}>{t('forms.user.profile_image')}</FormLabel>
            <UploadImages
              setFiles={setProfileImage}
              files={profileImage}
            />
          </FormControl>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Input
            label={t('forms.user.display_name')}
            className={classes.input}
            onChangeText={setDisplayName}
            value={displayName}
            fullWidth
            margin="normal"
            variant="outlined"
            invert
          />
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <InputTextArea
            className={classes.input}
            variant="outlined"
            label={t('forms.user.bio')}
            onChangeText={setBio}
            value={bio}
            fullWidth
            invert
          />
        </Grid>
      </Grid>
      <Grid className={classes.container} container spacing={2}>
        {/* Call-To-Action on Tablet View */}
        <Hidden mdUp>
          <Grid item sm={6} style={{ width: '100%' }}>
            {processing
              ? <Loading />
              : (
                <Button
                  variant="contained"
                  className={classes.button}
                  onPress={submit}
                  style={{ maxWidth: 'none', width: '100%' }}
                >
                  {t('profile.update')}
                </Button>
              )}
          </Grid>
        </Hidden>
        {/* Call-To-Action on Desktop View */}
        <Hidden smDown>
          <Grid item xs={12}>
            <div className={classes.buttonContainer}>
              {processing
                ? <Loading />
                : (
                  <Button
                    variant="contained"
                    className={classes.button}
                    onPress={submit}
                    disabled={displayName === '' || firstName === '' || lastName === '' || dob === '' || country === ''}
                  >
                    {t('profile.update')}
                  </Button>
                )}
            </div>
          </Grid>
        </Hidden>
      </Grid>
    </div>
  );
};

ProfileTab.defaultProps = {
  message: null,
};

ProfileTab.propTypes = {
  user: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  // errors: PropTypes.object.isRequired,
  processing: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  message: PropTypes.string,
  userUpdateRequest: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.userReducer.user,
  profile: state.userReducer.profile,
  errors: state.userReducer.update.errors,
  processing: state.userReducer.update.processing,
  success: state.userReducer.update.success,
  message: state.userReducer.update.message,
});

export default connect(mapStateToProps, { userUpdateRequest })(ProfileTab);
