import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import AppText from './AppText';

const styles = makeStyles((theme) => ({
  text: {
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
  },
}));

const StoryCardTitle = ({ className, text }) => {
  const classes = styles();
  return (
    <AppText
      className={classNames([classes.text, className])}
      variant="h4"
      header
    >
      { text }
    </AppText>
  );
};

StoryCardTitle.defaultProps = {
  className: null,
};

StoryCardTitle.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  text: PropTypes.string.isRequired,
};

export default StoryCardTitle;
