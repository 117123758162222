const colors = {
  theme: '#131313',
  white: '#FFFFFF',
  greyLight: '#f5f5f5',
  black: '#000000',
  inactive: '#838383',
  inactiveText: '#515151',
  error: '#ff493b',
  comments: [
    {
      backgroundColor: '#E3F2FD',
      color: '#3E84B7',
    },
    {
      backgroundColor: '#F3E3FD',
      color: '#843EB7',
    },
    {
      backgroundColor: '#FDE3E7',
      color: '#B73E7F',
    },
    {
      backgroundColor: '#FDEEE3',
      color: '#B7713E',
    },
  ],
};

export default colors;
