import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import Button from '../Button';
import AppText from '../AppText';

/* eslint-disable react/jsx-props-no-spreading */
const acceptedTypes = [
  'image/*',
];

const styles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontFamily: '\'Montserrat\', sans-serif',
  },
  dropping: {
    fontFamily: '\'Montserrat\', sans-serif',
    background: '#CCC',
  },
  default: {
    fontFamily: '\'Montserrat\', sans-serif',
    boxShadow: 'none',
    backgroundColor: '#EDEDED',
    '& .MuiButton-label > p': {
      color: '#151515',
    }
  },
  text: {
    marginLeft: theme.spacing(3),
  },
  warning: {
    color: '#C83232'
  }
}));

const UploadImages = ({ setFiles, files, ...other }) => {
  const classes = styles();
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const onDrop = useCallback((acceptedFiles) => {
    setFiles(Object.values(acceptedFiles));
  }, [setFiles]);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div>
      <div
        className={classes.container}
        {...getRootProps()}
      >
        <input
          type="file"
          ref={inputRef}
          {...getInputProps()}
          accept={acceptedTypes.join(', ')}
          {...other}
        />
        <Button
          className={isDragActive ? classes.dropping : classes.default}
          variant="contained"
          onPress={() => inputRef.current && inputRef.current.click()}
        >
          {t('publish.fields.choose_file')}
        </Button>
        <AppText className={classes.text} variant="subtitle1">
          {files.length === 0 && t('publish.fields.cover_image_no_file')}
          {files.map(({ name }) => name).join(', ')}
        </AppText>
      </div>
      <AppText className={classes.warning} variant="subtitle1">
        {t('publish.fields.cover_image_limit')}
      </AppText>
    </div>
    
  );
};

UploadImages.propTypes = {
  setFiles: PropTypes.func.isRequired,
  files: PropTypes.array.isRequired
};

export default UploadImages;
