import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { siteLoginOpen } from '../../providers/redux/actions/Site';
import Button from './Button';

const styles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: '#518435',
    fontFamily: '\'Montserrat\', sans-serif',
    padding: theme.spacing(),
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#6bb047',
    },
  },
  text: {
      fontSize: '1rem',
      lineHeight: '1.38rem',
      color: theme.palette.common.white,
  },
}));

const ButtonRead = ({
  className, onPress, authentication, continueReading,
}) => {
  const dispatch = useDispatch();
  const classes = styles();
  const { t } = useTranslation();
  const { loggedIn } = useSelector((state) => ({
    loggedIn: state.userReducer.loggedIn,
  }));

  const navigate = () => {
    if (!authentication) {
      onPress();
      return;
    }

    if (loggedIn) {
      onPress();
      return;
    }

    dispatch(siteLoginOpen());
  };

  return (
    <Button
      className={className}
      textClassName={classes.text}
      href={null}
      onPress={navigate}
      classes={{
        root: classes.root,
      }}
    >
      {continueReading ? t('Continue Reading') : t('Start Reading')}
    </Button>
  );
};

ButtonRead.defaultProps = {
  className: null,
  authentication: false,
  continueReading: false,
};

ButtonRead.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onPress: PropTypes.func.isRequired,
  authentication: PropTypes.bool,
  continueReading: PropTypes.bool,
};

export default ButtonRead;
