import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useRouter from '../../../services/useRouter';
import Button from '../../Button';
import Constants from '../../../constants';

const styles = makeStyles((theme) => ({
  button: {
    display: 'flex',
    backgroundColor: 'transparent',
    fontFamily: '\'Source Sans Pro\', sans-serif',
    fontSize: 15,
    color: '#8E8E8E',
    textDecoration: 'none',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(2),
  },
  text: {
    color: theme.palette.secondary.main,
    fontWeight: 'normal',
    fontSize: '1.0rem'
  },
  accentText: {
      color: theme.palette.primary.main,
  },
  active: {
    color: theme.palette.common.white,
  },
}));

const HeaderNavigationItem = ({
  text, link, authentication,
}) => {
  const { location: { pathname }, history } = useRouter();
  const { t } = useTranslation();
  const classes = styles();
  const { loggedIn } = useSelector((state) => ({
    loggedIn: state.userReducer.loggedIn,
  }));

  const navigate = () => {
    if (!authentication) {
      history.push(link);
    } else if (!loggedIn) {
      history.push(Constants.routes.Login);
    } else {
      history.push(link);
    }
  };

  const colorK = (str) => {
      if (str == 'AKademy') {
          return (
              <div>
                A<span className={classes.accentText}>K</span>ademy
              </div>
          );
      } else {
          return str;
      }
  };

  return (
    <Button
      className={classes.button}
      textClassName={[classes.text, pathname === link && classes.active]}
      onPress={navigate}
      headerText
    >
      {
          colorK(t(text))
      }
    </Button>
  );
};

HeaderNavigationItem.defaultProps = {
  authentication: false,
};

HeaderNavigationItem.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  authentication: PropTypes.bool,
};

export default HeaderNavigationItem;
