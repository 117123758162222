import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import List from './List';
import { transactionsListRequest } from '../providers/redux/actions/Transactions';
import Loading from './Loading';
import AppText from './AppText';

const useStyles = makeStyles(() => ({
  header: {
    background: '#F4F7F8',
  },
  headerCell: {
    fontWeight: 'bold',
    color: '#656565',
  },
}));

const TransactionHistory = ({ className }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { loading, transactions } = useSelector((state) => ({
    loading: state.transactionsReducer.loading,
    transactions: state.transactionsReducer.data,
  }));

  useEffect(() => {
    dispatch(
      transactionsListRequest(),
    );
  }, []);


  return (
    <List
      className={className}
      title={t('Transaction History')}
      header={(
        <TableHead className={classes.header}>
          <TableCell>
            <AppText className={classes.headerCell}>
              {t('Date')}
            </AppText>
          </TableCell>
          <TableCell>
            <AppText className={classes.headerCell}>
              {t('Sender')}
            </AppText>
          </TableCell>
          <TableCell>
            <AppText className={classes.headerCell}>
              {t('Amount')}
            </AppText>
          </TableCell>
          <TableCell>
            <AppText className={classes.headerCell}>
              {t('Comment')}
            </AppText>
          </TableCell>
        </TableHead>
      )}
    >
      {loading && (<Loading />)}
      {(!loading && transactions.length > 0) && transactions.map(({
        timestamp, name, amount, comment,
      }) => (
        <TableRow>
          <TableCell>
            {timestamp.format('D/M/YYYY')}
          </TableCell>
          <TableCell>
            {name}
          </TableCell>
          <TableCell>
            <NumberFormat
              prefix="$"
              decimalScale={2}
              fixedDecimalScale
              value={amount}
              displayType="text"
              thousandSeparator
            />
          </TableCell>
          <TableCell>
            { comment }
          </TableCell>
        </TableRow>
      ))}
    </List>
  );
};

TransactionHistory.defaultProps = {
  className: null,
};

TransactionHistory.propTypes = {
  className: PropTypes.string,
};

export default TransactionHistory;
