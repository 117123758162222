import { actions } from '../actions/Discussions';

const initialState = {
  item: {
    loading: false,
    data: null,
  },
  create: {
    id: null,
    success: false,
    processing: false,
    message: null,
    errors: {},
  },
  loading: true,
  data: null,
};

const discussionsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case actions.GET:
      return {
        ...state,
        loading: true,
      };
    case actions.PUT:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case actions.ITEM.GET:
      return {
        ...state,
        item: {
          loading: true,
          data: null,
        },
      };
    case actions.ITEM.PUT:
      return {
        ...state,
        item: {
          loading: false,
          data: action.payload,
        },
      };
    case actions.ITEM.RESET:
      return {
        ...state,
        item: {
          loading: false,
          data: null,
        },
      };
    case actions.CREATE.REQUEST:
      return {
        ...state,
        create: {
          ...state.create,
          processing: true,
          success: false,
          errors: {},
          message: null,
        },
      };
    case actions.CREATE.SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          processing: false,
          success: true,
          id: action.payload.id,
          message: action.payload.message,
        },
      };
    case actions.CREATE.ERRORS:
      return {
        ...state,
        create: {
          ...state.create,
          processing: false,
          success: false,
          message: action.payload.message,
          errors: action.payload.errors,
        },
      };
    case actions.CREATE.RESET:
      return {
        ...state,
        create: {
          ...initialState.create,
        },
      };
    case actions.RESET:
      return {
        ...state,
        loading: false,
        data: null,
      };
    default:
      return state;
  }
};

export default discussionsReducer;
