import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import AppText from '../../AppText';
import Button from '../../Button';
import useRouter from '../../../services/useRouter';
import Constants from '../../../constants';

const styles = makeStyles((theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'transparent',
    width: '100%',
    borderRadius: 0,
    color: '#151515',
    padding: theme.spacing(),
  },
  chapter: {
    flex: 1,
    textOverflow: 'ellipsis',
  },
  date: {
    fontStyle: 'italic',
  },
  alternate: {
    backgroundColor: 'transparent',
  },
  label: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  alignM: {
    width: '100%',
  },
}));

const StoryChaptersRow = ({
  storyId, id, title, number,
}) => {
  const { t } = useTranslation();
  const { history } = useRouter();
  const classes = styles();

  const read = () => {
    history.push(`${Constants.routes.Reader}/${storyId}/${id}`);
  };

  return (
    <Button
      onPress={read}
      className={classes.container}
      textClassName={classes.text}
      classes={{
        label: classes.label,
      }}
      authentication
    >
      <AppText className={classes.chapter} variant="body1">
        {`${t('story.chapter', { number })}: ${title}`}
      </AppText>
    </Button>
  );
};

StoryChaptersRow.propTypes = {
  storyId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
};

export default StoryChaptersRow;
