import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { storage } from 'firebase';
import { makeStyles } from '@material-ui/core';
import imageSet from '../../../providers/helpers/ImageSet';

const firebaseStorage = storage();

const useStyles = makeStyles(() => ({
  image: {
    width: '100%',
  },
}));

const DiscussionBoardPostCover = ({ uuid }) => {
  const classes = useStyles();
  const [cover, setCover] = useState(null);

  useEffect(() => {
    const getImages = async () => {
      const folder = await firebaseStorage.ref(
        `discussions/${uuid}`,
      )
        .listAll();

      const images = imageSet(folder.items);
      const firstItem = images.shift();
      console.log(firstItem);
      if (firstItem) {
        setCover(await firstItem.getDownloadURL());
        return;
      }

      setCover('https://via.placeholder.com/180x250.png?text=Cover+Image');
    };

    getImages();
  }, [uuid]);

  return (
    <img className={classes.image} src={cover} alt="" />
  );
};

DiscussionBoardPostCover.propTypes = {
  uuid: PropTypes.string.isRequired,
};

export default DiscussionBoardPostCover;
