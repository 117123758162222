import { actions } from '../actions/User';

const initialState = {
  initialSync: true,
  loading: false,
  loggedIn: false,
  error: null,
  createError: null,
  forgotError: null,
  user: null,
  profile: null,
  create: {
    message: null,
    processing: false,
    success: false,
    errors: {},
  },
  update: {
    message: null,
    processing: false,
    success: false,
    errors: {},
  },
  updatePw: {
    processing: false,
    success: false,
    error: null,
  },
  resetPw: {
    processing: false,
    success: false,
    error: null,
  },
  stories: null,
  posts: null,
};

const userReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case actions.LOGIN.REQUEST:
    case actions.LOGOUT.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.LOGIN.SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        loggedIn: true,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    case actions.LOGIN.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actions.LOGOUT.SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        loggedIn: false,
        user: null,
        initialSync: false,
      };
    case actions.LOGOUT.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case actions.PROFILE_SYNCED:
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.payload,
        },
        initialSync: false,
      };
    case actions.CREATE.REQUEST:
      return {
        ...state,
        create: {
          ...state.create,
          message: null,
          processing: true,
        },
      };
    case actions.CREATE.SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          message: action.payload,
          processing: false,
          success: true,
        },
        createError: null,
      };
    case actions.CREATE.ERROR:
      return {
        ...state,
        create: {
          ...state.create,
          message: action.payload.message,
          processing: false,
        },
        createError: action.createError,
      };
    case actions.UPDATE.REQUEST:
      return {
        ...state,
        update: {
          ...state.update,
          message: null,
          processing: true,
        },
      };
    case actions.UPDATE.SUCCESS:
      return {
        ...state,
        update: {
          ...state.update,
          message: action.payload,
          processing: false,
          success: true,
          errors: {},
        },
      };
    case actions.UPDATE.ERROR:
      return {
        ...state,
        update: {
          ...state.update,
          message: action.payload.message,
          processing: false,
          errors: action.payload.errors,
        },
      };
    case actions.UPDATE_PW.REQUEST:
      return {
        ...state,
        updatePw: {
          ...state.updatePw,
          processing: true,
        },
      };
    case actions.UPDATE_PW.SUCCESS:
      return {
        ...state,
        updatePw: {
          ...state.updatePw,
          processing: false,
          success: true,
          error: null,
        },
      };
    case actions.UPDATE_PW.ERROR:
      return {
        ...state,
        updatePw: {
          ...state.updatePw,
          processing: false,
          error: action.payload.error,
        },
      };
    case actions.RESET.REQUEST:
      return {
        ...state,
        resetPw: {
          ...state.resetPw,
          processing: true,
        },
      };
    case actions.RESET.SUCCESS:
      return {
        ...state,
        resetPw: {
          ...state.resetPw,
          processing: false,
          success: true,
          error: null,
        },
      };
    case actions.RESET.ERROR:
      return {
        ...state,
        resetPw: {
          ...state.resetPw,
          processing: false,
          error: action.payload.error,
        },
      };
    case actions.STORIES_SYNCED:
      return {
        ...state,
        stories: {
          ...state.stories,
          ...action.payload,
        },
      };
    case actions.POSTS.LOADED:
      return {
        ...state,
        posts: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
