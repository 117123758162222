import React from 'react';
import PropTypes from 'prop-types';
import { Button, makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DonationDialog from '../Donation';
import useRouter from '../../services/useRouter';
import Constants from '../../constants';

const styles = makeStyles((theme) => ({
  root: {
    width: 200,
    backgroundColor: '#5D9140',
    fontSize: '1em',
    fontFamily: '\'Montserrat\', sans-serif',
    padding: '7px',
    textTransform: 'uppercase',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#6bb047',
    },
    [theme.breakpoints.down('xs')]: {
      width: 160,
      fontSize: '0.85em',
    },
  },
}));

const ButtonDonate = ({
  className, loggedIn, authorName, paymentMethodResponse, authorId,
}) => {
  const classes = styles();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const { history } = useRouter();

  const handleClickOpen = () => {
    if (!loggedIn) {
      history.push(Constants.routes.Login);
    } else {
      setOpen(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        className={className}
        variant="contained"
        href={null}
        onClick={handleClickOpen}
        classes={{
          root: classes.root,
        }}
      >
        {t('author.support')}
      </Button>
      <DonationDialog
        open={open}
        paymentMethodResponse={paymentMethodResponse}
        handleClose={handleClose}
        authorName={authorName}
        authorId={authorId}
      />
    </>
  );
};

ButtonDonate.defaultProps = {
  className: null,
  paymentMethodResponse: null,
};

ButtonDonate.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  authorName: PropTypes.string.isRequired,
  authorId: PropTypes.string.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  paymentMethodResponse: PropTypes.object,
};

const mapStateToProps = (state) => ({
  loggedIn: state.userReducer.loggedIn,
});

export default connect(mapStateToProps, {})(ButtonDonate);
