import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, CardContent } from '@material-ui/core';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import StandardLayout from '../components/Layout/StandardLayout';
import Loading from '../components/Loading';
import Input from '../components/Input/Input';
import AppText from '../components/AppText';
import { userCreateRequest } from '../providers/redux/actions/User';
import Button from '../components/Button';
import Snackbar from '../components/Snackbar';
import ErrorBox from '../components/ErrorBox';
import LayoutLight from '../components/Layout/LayoutLight';

const styles = makeStyles((theme) => ({
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  header: {
    flex: 1,
    fontSize: 28,
    fontWeight: 500,
    color: theme.palette.common.white,
    marginTop: theme.spacing(),
  },
  subtitle: {
    marginTop: theme.spacing(2),
    fontSize: 18,
    color: '#8E8E8E',
  },
  loginForm: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 640
  },
  title: {
    fontSize: 28,
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 640
  },
  button: {
    flex: 1,
  },
}));

const RegisterScreen = ({
  error,
  processing,
  success,
  message,
  userCreateRequest: userCreateRequestAction,
}) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  // const [displayName, setDisplayName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const classes = styles();
  const submit = () => userCreateRequestAction({ email, password, confirmPassword });

  const onKeyPress = (e) => {
    if (!processing && !(email === '' || password === '' || confirmPassword === '')) {
      if (e.key === 'Enter') {
        userCreateRequestAction({ email, password, confirmPassword });
      }
    }
  };

  return (
    <StandardLayout>
      <Snackbar
        variant={success ? 'success' : 'error'}
        message={message}
      />
      <LayoutLight
        header={(
          <div className={classes.headerContainer}>
            <AppText className={classes.header} header>
              {t('register.title')}
            </AppText>
            <AppText className={classes.subtitle} subtitle>
              {t('register.subtitle')}
            </AppText>
          </div>
        )}
      >
        <CardContent>
          <div className={classes.loginForm}>
            <Input
              label={t('forms.user.email')}
              onKeyPress={onKeyPress}
              onChangeText={setEmail}
              value={email}
              invert
            />
            <Input
              label={t('forms.user.password')}
              onKeyPress={onKeyPress}
              onChangeText={setPassword}
              value={password}
              secure
              invert
            />
            <Input
              label={t('forms.user.confirm_password')}
              onKeyPress={onKeyPress}
              onChangeText={setConfirmPassword}
              value={confirmPassword}
              secure
              invert
            />
            <ErrorBox error={error} center />
          </div>
          <div className={classes.buttonContainer}>
            {processing
              ? <Loading />
              : (
                <Button
                  variant="contained"
                  className={classes.button}
                  onPress={submit}
                  disabled={email === '' || password === '' || confirmPassword === ''}
                >
                  {t('register.submit')}
                </Button>
              )}
          </div>
        </CardContent>
      </LayoutLight>
    </StandardLayout>
  );
};

RegisterScreen.defaultProps = {
  message: null,
  error: null,
};

RegisterScreen.propTypes = {
  processing: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  message: PropTypes.string,
  error: PropTypes.string,
  userCreateRequest: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  processing: state.userReducer.create.processing,
  success: state.userReducer.create.success,
  message: state.userReducer.create.message,
  error: state.userReducer.error,
});

export default connect(mapStateToProps, { userCreateRequest })(RegisterScreen);
