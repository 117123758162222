import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import BadgeComments from '../../Badge/BadgeComments';
import Votes from '../../Votes/Votes';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'inline-flex',
    flexDirection: 'row',
    borderRadius: 8,
    background: theme.palette.dark.main,
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    [theme.breakpoints.down('sm')]: {
        paddingRight: theme.spacing(2),
    },
  },
  downVote: {
    marginLeft: theme.spacing(2),
  },
  comments: {
    display: 'flex',
    flexDirection: 'row',
    flex: 3,
    alignItems: 'center',
    color: theme.palette.common.white,
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(1),
    },
  },
  icon: {
    marginRight: theme.spacing(),
    color: '#8E8E8E',
  },
}));

const DiscussionStats = ({
  className, upVotes, downVotes, uuid,
}) => {
  const classes = useStyles();
  return (
    <div className={classNames([classes.container, className])}>
      <Votes
        voteUp={() => {}}
        voteDown={() => {}}
        count={upVotes - downVotes}
        white
      />
      <BadgeComments className={classes.comments} uuid={uuid} />
    </div>
  );
};

DiscussionStats.defaultProps = {
  className: null,
};

DiscussionStats.propTypes = {
  className: PropTypes.string,
  upVotes: PropTypes.number.isRequired,
  downVotes: PropTypes.number.isRequired,
  uuid: PropTypes.number.isRequired,
};

export default DiscussionStats;
