import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ArrowDropDown, Search } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import HeaderLogo from './HeaderLogo';
import HeaderNavigation from './HeaderNavigation';
import HeaderUser from './HeaderUser';
import HeaderUserMobile from './HeaderUserMobile';
import LanguageSelector from '../LanguageSelector';
import Button from '../Button';
import Constants from '../../constants';

const styles = makeStyles((theme) => ({
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    height: Constants.headerHeight,
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: theme.palette.header.main,
    color: theme.palette.header.contrastText,
    borderBottom: '2px solid #3B3B3B',
    zIndex: 1000,
  },
  button: {
    backgroundColor: 'transparent',
    fontFamily: '\'Source Sans Pro\', sans-serif',
    fontSize: '1.0rem',
    color: theme.palette.secondary.main,
    textDecoration: 'none',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  mobileHide: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  badge: {
      position: "absolute",
      left: "-8px",
      top: "22px",
      backgroundColor: "#F70000",
      WebkitTransform: "rotate(-45deg) scale(1) skew(0deg) translate(10px)",
      MozTransform: "rotate(-45deg) scale(1) skew(0deg) translate(10px)",
      OTransform: "rotate(-45deg) scale(1) skew(0deg) translate(10px)",
      msTransform: "rotate(-45deg) scale(1) skew(0deg) translate(10px)",
      transform: "rotate(-45deg) scale(1) skew(0deg) translate(10px)",
      textAlign: "center",
      color: "white",
      fontSize: "14px",
      padding: "0 10px",
      borderTop: "0px",
      lineHeight: "20px",
      margin: "0"
  },
  sufbadge: {
      content: "' '",
      position: "absolute",
      left: "32px",
      top: "-3px",
      WebkitTransform: "rotate(-45deg) scale(1) skew(0deg) translate(10px)",
      MozTransform: "rotate(-45deg) scale(1) skew(0deg) translate(10px)",
      OTransform: "rotate(-45deg) scale(1) skew(0deg) translate(10px)",
      msTransform: "rotate(-45deg) scale(1) skew(0deg) translate(10px)",
      transform: "rotate(-45deg) scale(1) skew(0deg) translate(10px)",
      borderRight: "20px solid transparent",
      borderBottom: "20px solid #F70000"
  },
  prebadge: {
      content: "' '",
      position: "absolute",
      left: "-17px",
      top: "46px",
      WebkitTransform: "rotate(-45deg) scale(1) skew(0deg) translate(10px)",
      MozTransform: "rotate(-45deg) scale(1) skew(0deg) translate(10px)",
      OTransform: "rotate(-45deg) scale(1) skew(0deg) translate(10px)",
      msTransform: "rotate(-45deg) scale(1) skew(0deg) translate(10px)",
      transform: "rotate(-45deg) scale(1) skew(0deg) translate(10px)",
      borderLeft: "20px solid transparent",
      borderBottom: "20px solid #F70000"
  }
}));

const Header = () => {
  const classes = styles();
  const { i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const language = (i18n.language === 'en') ? 'English' : '日本語';

  const { header: HeaderComponent } = useSelector((state) => ({
    header: state.siteReducer.header,
  }));

  if (HeaderComponent !== null) {
    return <HeaderComponent />;
  }

  return (
    <div className={classes.container}>
      <div className={classes.prebadge}></div>
      <div className={classes.badge}>BETA</div>
      <div className={classes.sufbadge}></div>
      <HeaderLogo />
      <HeaderNavigation />
      <div className={classes.mobileHide}>
        <Button
          onPress={() => {}}
          className={classes.button}
        >
          <Search />
        </Button>
        <Button
          className={classes.button}
          onPress={(e) => {
            setOpen(true);
            setAnchorEl(e.currentTarget);
          }}
        >
          {language}
          {' '}
          <ArrowDropDown />
        </Button>
        <LanguageSelector
          anchorEl={anchorEl}
          setClose={() => setOpen(false)}
          open={open}
        />
      </div>
      <HeaderUser />
      <HeaderUserMobile />
    </div>
  );
};

export default Header;
