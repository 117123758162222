import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ForumRounded } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';
import AppText from '../AppText';
import Disqus from 'disqus-react';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'inline-flex',
    flexDirection: 'row',
  },
  downVote: {
    marginLeft: theme.spacing(4),
  },
  comments: {
    marginLeft: theme.spacing(4),
    display: 'flex',
    flexDirection: 'row',
    flex: 3,
    alignItems: 'center',
    color: theme.palette.common.white,
  },
  icon: {
    marginRight: theme.spacing(),
    color: theme.palette.common.white,
  },
}));

const BadgeComments = ({ className, iconClassName, uuid }) => {
  const classes = useStyles();
  const disqusShortname = 'komakaku';
  const disqusConfig = {
      url: 'https://komakaku.com/discussion/'+uuid,
      identifier: uuid,
  };

  return (
    <div className={classNames([classes.container, className])}>
      <ForumRounded className={classNames([classes.icon, iconClassName])} />
        {uuid && (
            <Disqus.CommentCount shortname={disqusShortname} config={disqusConfig}>
                comments
            </Disqus.CommentCount>
        )}
    </div>
  );
};

BadgeComments.defaultProps = {
  className: null,
  iconClassName: null,
};

BadgeComments.propTypes = {
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  uuid: PropTypes.number.isRequired,
};

export default BadgeComments;
