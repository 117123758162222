import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import Constants from '../../constants';
import Button from '../Button';
import DiscussionBoardPostCover
  from './DiscussionBoard/DiscussionBoardPostCover';
import LeaderBoardItemTitle
  from '../Leaderboard/LeaderBoardItem/LeaderBoardItemTitle';
import DiscussionAuthor from './DiscussionAuthor';
import AppText from '../AppText';
import DiscussionStats from './DiscussionStats/DiscussionStats';
import useRouter from '../../services/useRouter';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: 0,
    paddingRight: 0,
    alignItems: 'flex-start',
  },
  text: {
    marginTop: theme.spacing(3),
    color: theme.palette.text.secondary,
    textAlign: 'justify',
  },
  author: {
    marginTop: theme.spacing(),
    display: 'flex',
    flexDirection: 'row',
  },
  authorName: {
    fontWeight: 'bold',
    color: theme.palette.common.white,
    marginLeft: theme.spacing(),
  },
  image: {
    marginRight: theme.spacing(),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  imageMobile: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  read: {
    flex: 1,
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(),
      marginRight: theme.spacing(),
    },
  },
  stats: {
    marginTop: theme.spacing(4),
  },
}));

const DiscussionPost = ({
  className, uuid, user, title, description, createdAt, imageWidth, imageMobileWidth,
}) => {
  const { history } = useRouter();
  const classes = useStyles();
  const onPress = () => history.push(`${Constants.routes.Discussion}/${uuid}`);

  return (
    <Button
      className={classNames([classes.container, className])}
      onPress={onPress}
    >
      <div className={classes.image} style={{ width: imageWidth }}>
        <DiscussionBoardPostCover uuid={uuid} />
      </div>
      <div className={classes.read}>
        <LeaderBoardItemTitle text={title} />
        <div className={classes.author}>
          <DiscussionAuthor
            className={classes.rowItem}
            createdAt={createdAt}
            name={user.name}
          />
        </div>
        <div
          className={classes.imageMobile}
          style={{ width: imageMobileWidth }}
        >
          <DiscussionBoardPostCover uuid={uuid} />
        </div>
        <AppText
          className={classNames([classes.text])}
          variant="body1"
        >
          { description }
        </AppText>
        <DiscussionStats
          className={classes.stats}
          upVotes={287}
          downVotes={13}
          uuid={uuid}
        />
      </div>
    </Button>
  );
};

DiscussionPost.defaultProps = {
  className: null,
  imageWidth: 180,
  imageMobileWidth: '100%',
};

DiscussionPost.propTypes = {
  className: PropTypes.string,
  imageWidth: PropTypes.number,
  uuid: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  imageMobileWidth: PropTypes.string,
};

export default DiscussionPost;
