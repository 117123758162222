import {
  fork, all,
} from 'redux-saga/effects';
import { firestore } from 'firebase';
import firebase from '../../firebase';
import {
  putChapters,
} from '../actions/Chapters';
import Constants from '../../../constants';

/**
 * This is only the logic for processing the published document. No errors or
 * exceptions are expected within this block.
 *
 * If there are any, they will be caught by the exception handler in
 * createStorySaga.
 *
 * @param chapterNumber
 * @param chapterTitle
 * @param createdAt
 * @param source
 * @param story
 * @param updatedAt
 * @param color
 */

const transformer = (chapters) => {
  const data = [];
  chapters.forEach((chapter) => {
    const {
      chapter_number,
      chapter_title,
      created_at,
      source,
      story,
      updated_at,
    } = chapter.data();

    data.push({
      chapter_number,
      chapter_title,
      created_at,
      source,
      story,
      updated_at,
    });
  });

  return data;
};

const collection = firestore().collection(Constants.firebase.collections.chapters);

export default function* Chapters() {
  yield all([
    fork(
      firebase.firestore.syncCollection,
      collection.where('reviewing', '==', false).orderBy('updated_at', 'desc').limit(36),
      {
        successActionCreator: putChapters,
        transform: transformer,
      },
    )
  ]);
}