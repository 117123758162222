import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import AppText from './AppText';
import Constants from '../constants';

const styles = makeStyles(() => ({
  error: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 48,
  },
  errorText: {
    color: Constants.colors.error,
    fontSize: 13,
  },
  errorCenter: {
    justifyContent: 'center',
  },
  errorTextCenter: {
    textAlign: 'center',
  },
}));

const ErrorBox = ({ error, center }) => {
  const classes = styles();
  return (
    <div className={classNames([classes.error, center && classes.errorCenter])}>
      { error
        ? (
          <AppText
            className={classNames([
              classes.errorText,
              center && classes.errorTextCenter,
            ])}
          >
            { error }
          </AppText>
        )
        : null }
    </div>
  );
};

ErrorBox.defaultProps = {
  error: null,
  center: false,
};

ErrorBox.propTypes = {
  error: PropTypes.string,
  center: PropTypes.bool,
};

export default ErrorBox;
