import React from 'react';
import PropTypes from 'prop-types';
import useStoryCovers from '../../hooks/useStoryCovers';
import Cover from '../Cover';

const StoryCover = ({ className, uuid }) => {
  const cover = useStoryCovers(uuid, 2);
  return (
    <Cover className={className} image={cover} />
  );
};

StoryCover.defaultProps = {
  className: null,
};

StoryCover.propTypes = {
  uuid: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default StoryCover;
