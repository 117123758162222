import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import BookCarouselSlider from './BookCarouselSlider';
import BookCarouselItem from './BookCarouselItem';
import {
  POSITION_END,
  POSITION_MIDDLE,
  POSITION_START,
} from './PositionConstants';
import ButtonArrowLeft from '../../Button/ButtonArrowLeft';
import ButtonArrowRight from '../../Button/ButtonArrowRight';

const styles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  sliderContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    marginRight: '-54px',
    overflow: 'hidden',
  },
  iconContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '999'
  },
  iconContainerLeft: {
      [theme.breakpoints.down('sm')]: {
          left: 0,
      },
      left: '54px',
      bottom: 0,
      top: 0,
  },
  iconContainerRight: {
      right: 0,
      bottom: 0,
      top: 0,
  },
  icon: {
    fontSize: 30,
    color: '#B9B9B9',
  },
  iconDisabled: {
    color: '#414141',
  },
  image: {
    height: '100%',
  },
  iconButton: {
    height: '100%',
    [theme.breakpoints.down('sm')]: {
        padding: 0,
    },
    borderRadius: 0,
    '&:hover': {
        backgroundColor: 'rgba(0,0,0,.61)',
    },
  }
}));

const BookCarousel = ({
  className, index, next, previous, width, height, chapters,
}) => {
  let startingPosition;
  if (index === 0) {
    startingPosition = POSITION_START;
  } else if (index === chapters.length) {
    startingPosition = POSITION_END;
  } else {
    startingPosition = POSITION_MIDDLE;
  }

  const [location, setLocation] = useState(startingPosition);

  const classes = styles();
  const sliderContainer = useRef(null);

  if (!chapters || chapters.length === 0) {
    return null;
  }

  return (
    <div
      key={chapters.length}
      className={classNames([classes.container, className])}
    >
      <div className={
          classNames([
              classes.iconContainer,
              classes.iconContainerLeft,
          ])
      }>
        <ButtonArrowLeft
          className={classes.iconButton}
          onPress={() => location !== POSITION_START && previous()}
          disabled={!previous || location === POSITION_START}
        />
      </div>
      <div ref={sliderContainer} className={classes.sliderContainer}>
        <BookCarouselSlider
          container={sliderContainer}
          index={index}
          width={width}
          height={height}
          setLocation={setLocation}
        >
          {chapters.map((chapter, key) => (
            <BookCarouselItem
              key={key.toString()}
              width={width}
              chapter={chapter}
            />
          ))}
        </BookCarouselSlider>
      </div>
      <div className={
          classNames([
              classes.iconContainer,
              classes.iconContainerRight,
          ])
      }>
        <ButtonArrowRight
          className={classes.iconButton}
          onPress={() => location !== POSITION_END && next()}
          disabled={!next || location === POSITION_END}
        />
      </div>
    </div>
  );
};

BookCarousel.defaultProps = {
  className: null,
};

BookCarousel.propTypes = {
  className: PropTypes.string,
  index: PropTypes.number.isRequired,
  next: PropTypes.func.isRequired,
  previous: PropTypes.func.isRequired,
  chapters: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default BookCarousel;
