export const actions = {
  LOGIN: {
    OPEN: 'SITE_LOGIN_OPEN',
    CLOSE: 'SITE_LOGIN_CLOSE',
    TOGGLE: 'SITE_LOGIN_TOGGLE',
  },
  UPDATE_PW: {
    OPEN: 'SITE_UPDATE_PW_OPEN',
    CLOSE: 'SITE_UPDATE_PW_CLOSE',
    TOGGLE: 'SITE_UPDATE_PW_TOGGLE',
  },
  HEADER: {
    SET: 'SITE_HEADER_SET',
    RESET: 'SITE_HEADER_RESET',
  },
};

export const siteLoginOpen = () => ({
  type: actions.LOGIN.OPEN,
});

export const siteLoginToggle = () => ({
  type: actions.LOGIN.TOGGLE,
});

export const siteLoginClose = () => ({
  type: actions.LOGIN.CLOSE,
});

export const siteUpdatePWOpen = () => ({
  type: actions.UPDATE_PW.OPEN,
});

export const siteUpdatePWToggle = () => ({
  type: actions.UPDATE_PW.TOGGLE,
});

export const siteUpdatePWClose = () => ({
  type: actions.UPDATE_PW.CLOSE,
});

export const siteHeaderSet = (component) => ({
  type: actions.HEADER.SET,
  payload: component,
});

export const siteHeaderReset = () => ({
  type: actions.HEADER.RESET,
});
