import React, { useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { Card } from '@material-ui/core';
import Disqus from 'disqus-react';

const useStyles = makeStyles((theme) => ({
  card: {
    position: 'relative',
    top: -theme.spacing(10),
    [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(5),
    },
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
    },
  },
}));

const CommentsLightLayout = ({ article, uuid }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const disqusShortname = 'komakaku';
  const disqusConfig = {
      url: 'https://komakaku.com/discussion/'+uuid,
      identifier: uuid,
      title: article.title,
  };

  return (
    <Card className={classes.card}>
        <Disqus.DiscussionEmbed shortname={disqusShortname} config={disqusConfig} />
    </Card>
  );
};

CommentsLightLayout.propTypes = {
  article: PropTypes.func.isRequired,
  uuid: PropTypes.func.isRequired,
};

export default CommentsLightLayout;
