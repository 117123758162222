import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import AkademyClassCard from './AkademyClassCard';

const AkademyClasses = ({ onPress, list }) => (
  <Grid spacing={2} container>
    {list.map(({
      image, title, views, rating,
    }) => (
      <Grid
        key={title}
        item
        md={4}
        sm={6}
        xs={12}
      >
        <AkademyClassCard
          onPress={onPress}
          image={image}
          title={title}
          views={views}
          rating={rating}
        />
      </Grid>
    ))}
  </Grid>
);

AkademyClasses.propTypes = {
  onPress: PropTypes.func.isRequired,
  list: PropTypes.array.isRequired,
};

export default AkademyClasses;
