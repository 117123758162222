import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import IconSupport from '../assets/images/icons/icon-support-story.svg';
import IconReach from '../assets/images/icons/icon-reaching-out.svg';
import IconReward from '../assets/images/icons/icon-rewarding.svg';
import IconJoy from '../assets/images/icons/icon-spreading-joy.svg';
import IconGiveBack from '../assets/images/icons/icon-giving-back.svg';
import IconContribute from '../assets/images/icons/icon-contribute.svg';
import AboutUsBackground from '../components/Background/BackgroundAbout';
import StandardLayout from '../components/Layout/StandardLayout';
import AppText from '../components/AppText';
import AboutCard from '../components/AboutCard';
import ResponsiveWrapper from '../components/ResponsiveWrapper';
import Constants from '../constants';

const styles = makeStyles((theme) => ({
  wrapper: {
    marginTop: 380,
    textAlign: 'center',
  },
  wrapperM: {
    marginTop: 140,
    textAlign: 'center',
  },
  header: {
    color: theme.palette.secondary.main,
    marginBottom: theme.spacing(),
  },
  title: {
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),
  },
  subtitle: {
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  contentParagraph: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(6),
    textAlign: 'justify',
  },
  cards: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  cardHeader: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(),
    color: theme.palette.common.white,
    display: 'block',
    textAlign: 'center',
  },
}));

const AboutScreen = () => {
  const { t } = useTranslation();
  const classes = styles();
  const isDesktop = useMediaQuery({ minWidth: Constants.minWidth });
  return (
    <StandardLayout background={AboutUsBackground}>
      <ResponsiveWrapper className={
          classNames([
            classes[`wrapper${isDesktop ? '' : 'M'}`],
          ])
      }
      >
        <AppText className={classes.title} header variant="h4">
          {t('about.slogan')}
        </AppText>
        <AppText className={classes.contentParagraph} subtitle variant="body1">
          {t('about.para')}
        </AppText>
        <div className={classes.cardHeader}>
          <AppText header variant="h4">
            {t('about.cards.creator')}
          </AppText>
        </div>
        <div className={classes.cards}>
          <AboutCard icon={IconSupport} content="about.cards.support" />
          <AboutCard icon={IconReach} content="about.cards.translate" />
          <AboutCard icon={IconReward} content="about.cards.opportunities" />
        </div>
        <div className={classes.cardHeader}>
          <AppText header variant="h4">
            {t('about.cards.fans')}
          </AppText>
        </div>
        <div className={classes.cards}>
          <AboutCard icon={IconJoy} content="about.cards.favourite" />
          <AboutCard icon={IconGiveBack} content="about.cards.give" />
          <AboutCard icon={IconContribute} content="about.cards.contribute" />
        </div>
      </ResponsiveWrapper>
    </StandardLayout>
  );
};

export default AboutScreen;
