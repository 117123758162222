import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, makeStyles, Slide,
} from '@material-ui/core';
import ButtonArrowLeft from '../../Button/ButtonArrowLeft';
import ButtonArrowRight from '../../Button/ButtonArrowRight';
import AppText from '../../AppText';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    outline: 'none',
  },
  fill: {
    display: 'block',
    flex: 1,
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  imageViewer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  footer: {
    padding: theme.spacing(3),
  },
  footerText: {
    color: theme.palette.common.white,
  },
}));

const CarouselViewer = ({
  images, open, setClose, position,
}) => {
  const classes = useStyles();
  const [index, setIndex] = useState(position);

  useEffect(() => {
    setIndex(position);
  }, [position]);

  const image = images[index];

  const canPrevious = index > 0;
  const canNext = index < images.length - 1;

  const previous = () => canPrevious && setIndex(index - 1);
  const next = () => canNext && setIndex(index + 1);

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={setClose}
      scroll="body"
    >
      <Slide
        className={classes.container}
        direction="up"
        in={open}
        mountOnEnter
        unmountOnExit
      >
        <div>
          <div className={classes.imageViewer}>
            <div className={classes.iconContainer}>
              <ButtonArrowLeft
                onPress={previous}
                disabled={!canPrevious}
              />
            </div>
            <div className={classes.fill} />
            <div className={classes.content}>
              <div className={classes.sliderContainer}>
                <img src={image} alt="" />
              </div>
            </div>
            <div className={classes.fill} />
            <div className={classes.iconContainer}>
              <ButtonArrowRight
                onPress={next}
                disabled={!canNext}
              />
            </div>
          </div>
          <div className={classes.footer}>
            <AppText className={classes.footerText} subtitle variant="h6">
              {`Image ${index + 1}/${images.length}`}
            </AppText>
          </div>
        </div>
      </Slide>
    </Modal>
  );
};

CarouselViewer.defaultProps = {
  position: null,
};

CarouselViewer.propTypes = {
  images: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  setClose: PropTypes.func.isRequired,
  position: PropTypes.number,
};

export default CarouselViewer;
