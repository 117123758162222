import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import AkademyMasterCard from './AkademyMasterCard';

const AkademyMasters = ({ list }) => (
  <Grid container spacing={2}>
    {list.map(({
      uuid, image, name, caption,
    }) => (
      <Grid
        key={name}
        item
        md={6}
        sm={12}
        xs={12}
      >
        <AkademyMasterCard
          uuid={uuid}
          image={image}
          caption={caption}
          title={name}
        />
      </Grid>
    ))}
  </Grid>
);

AkademyMasters.propTypes = {
  list: PropTypes.array.isRequired,
};

export default AkademyMasters;
