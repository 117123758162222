import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';

class ErrorBoundary extends React.Component {
  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    const { children } = this.props;
    // if (hasError) {
    //   return (
    //     <div style={styles.container}>
    //       <AppText style={styles.title}>Well, this is embarrassing</AppText>
    //       <AppText style={styles.content}>
    //         We&#39;re experiencing an unknown problem.
    //         While we fix this, why don&#39;t you try something else?
    //       </AppText>
    //       <Button
    //         style={styles.button}
    //         textStyle={styles.buttonText}
    //         onPress={() => {
    //           this.setState({ hasError: false });
    //         }}
    //       >
    //         Back to home
    //       </Button>
    //     </div>
    //   );
    // }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
