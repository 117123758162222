import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  makeStyles,
} from '@material-ui/core';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Input from '../Input/Input';
import Button from '../Button';
import { updatePW } from '../../providers/redux/actions/User';
import Loading from '../Loading';
import ErrorBox from '../ErrorBox';
import { siteUpdatePWClose } from '../../providers/redux/actions/Site';
import AppText from '../AppText';

const styles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    flex: 1,
    fontSize: 28,
    fontWeight: 500,
    color: theme.palette.common.black,
    marginTop: theme.spacing(),
    textAlign: 'center',
  },
  registerLink: {
    flex: 1,
    fontFamily: '\'Montserrat\', sans-serif',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
  button: {
    fontFamily: '\'Montserrat\', sans-serif',
    textTransform: 'uppercase',
    flex: 1,
  },
  input: {
    marginTop: theme.spacing(2),
    labelRoot: {
      fontFamily: '\'Montserrat\', sans-serif',
    },
  },
}));

const UserUpdatePassword = ({
  error,
  success,
  processing,
  updatePW: updatePWAction,
}) => {
  const { t } = useTranslation();
  const { open } = useSelector((state) => ({
    open: state.siteReducer.updatePW,
  }));

  const dispatch = useDispatch();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const classes = styles();

  const setClose = () => dispatch(siteUpdatePWClose());

  useEffect(() => {
    if (success) {
      // reset fields
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');

      dispatch(siteUpdatePWClose());
    }
  }, [success, dispatch]);
  const updatePwSubmit = () => updatePWAction({ currentPassword, newPassword, confirmPassword });

  const onKeyPress = (e) => {
    if (!processing && !(currentPassword === '' || newPassword === '' || confirmPassword === '')) {
      if (e.key === 'Enter') {
        updatePWAction({ currentPassword, newPassword, confirmPassword });
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={!processing ? setClose : null}
      onBackdropClick={!processing ? setClose : null}
      maxWidth="sm"
      fullWidth
    >
      <AppText className={classes.title} header>{t('profile.updatePw')}</AppText>
      <DialogContent className={classes.content}>
        <Input
          label={t('forms.user.current_password')}
          className={classes.input}
          onKeyPress={onKeyPress}
          onChangeText={setCurrentPassword}
          value={currentPassword}
          secure
          invert
        />
        <Input
          label={t('forms.user.new_password')}
          className={classes.input}
          onKeyPress={onKeyPress}
          onChangeText={setNewPassword}
          value={newPassword}
          secure
          invert
        />
        <Input
          label={t('forms.user.confirm_password')}
          className={classes.input}
          onKeyPress={onKeyPress}
          onChangeText={setConfirmPassword}
          value={confirmPassword}
          secure
          invert
        />
        <div className={classes.buttonContainer}>
          { processing
            ? <Loading size={48} />
            : (
              <Button
                className={classes.button}
                variant="contained"
                onPress={updatePwSubmit}
                disabled={currentPassword === '' || newPassword === '' || confirmPassword === ''}
                color="primary"
              >
                {t('profile.update')}
              </Button>
            )}
        </div>
        <ErrorBox error={error} center />
      </DialogContent>
    </Dialog>
  );
};

UserUpdatePassword.defaultProps = {
  error: null,
};

UserUpdatePassword.propTypes = {
  processing: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  error: PropTypes.string,
  updatePW: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  processing: state.userReducer.updatePw.processing,
  success: state.userReducer.updatePw.success,
  error: state.userReducer.updatePw.error,
});

export default connect(mapStateToProps, { updatePW })(UserUpdatePassword);
