import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AppText from '../AppText';
import UploadChaptersRowTemplate from './UploadChaptersRowTemplate';

const styles = makeStyles(theme => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(3),
    fontSize: 18,
    color: '#B9B9B9',
    fontWeight: 600,
    borderBottom: '1px solid #DEDEDE'
  }
}));

const UploadChaptersHeader = () => {
  const { t } = useTranslation();
  const classes = styles();
  return (
    <UploadChaptersRowTemplate
      className={classes.row}
      fileName={<AppText>{t('publish.fields.file_name')}</AppText>}
      pages={<AppText>{t('publish.fields.pages')}</AppText>}
      chapterNo={<AppText>{t('publish.fields.chapter_no')}</AppText>}
      language={<AppText>{t('publish.fields.language')}</AppText>}
      title={<AppText>{t('publish.fields.title')}</AppText>}
      preview={<AppText>{t('publish.fields.preview')}</AppText>}
    />
  );
};

export default UploadChaptersHeader;
