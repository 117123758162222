import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import { Close } from '@material-ui/icons';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.common.white,
  },
}));

const ButtonClose = ({ className, onPress, fontSize }) => {
  const classes = useStyles();
  return (
    <IconButton
      className={classNames([classes.icon, className])}
      onClick={onPress}
    >
      <Close fontSize={fontSize} />
    </IconButton>
  );
};

ButtonClose.defaultProps = {
  className: null,
  fontSize: 'default',
};

ButtonClose.propTypes = {
  className: PropTypes.string,
  fontSize: PropTypes.oneOf([
    'inherit',
    'small',
    'default',
    'large',
  ]),
  onPress: PropTypes.func.isRequired,
};

export default ButtonClose;
