import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import AppText from '../../AppText';

const styles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    padding: theme.spacing(2),
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 18,
  },
}));

const StoryChaptersEmpty = () => {
  const { t } = useTranslation();
  const classes = styles();
  return (
    <div className={classes.container}>
      <AppText subtitle>{t('story.no_chapters')}</AppText>
    </div>
  );
};

export default StoryChaptersEmpty;
