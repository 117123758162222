import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { usePdf } from '@mikecousins/react-pdf';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Loading from '../Loading';
import AppText from '../AppText';
import Input from '../Input/Input';
import UploadChaptersRowTemplate from './UploadChaptersRowTemplate';
import ButtonRemove from '../Button/ButtonRemove';
import ButtonPreview from '../Button/ButtonPreview';
import SelectLanguage from '../Select/SelectLanguage';

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(2),
    background: '#FDFEFF',
    fontSize: 18
  },
  alternate: {
    background: '#F4F8FA'
  },
  item: {
    flex: 1
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  input: {
    '& .MuiOutlinedInput-root': {
      padding: '14px 14px'
    },
    '& .MuiInputBase-input': {
      padding: '3px 0 7px'
    },
    '& .MuiInputBase-root': {
      width: '100%'
    }
  },
  select: {
    paddingTop: 16,
    paddingBottom: 21
  }
}));

const UploadChaptersRow = ({
  file,
  alternate,
  onChange,
  remove,
  errors,
  setPreview,
  uploadChaptersCallbackFn
}) => {
  const { name, url, number, title, language } = file;
  const { t } = useTranslation();
  const classes = useStyles();
  const [pages, setPages] = useState(null);
  const canvasEl = useRef(null);
  const [loading, numPages] = usePdf({
    file: url,
    page: 1,
    canvasEl
  });

  useEffect(() => {
    uploadChaptersCallbackFn(numPages);
    setPages(numPages);
  }, [numPages]);

  const onChangeNumber = newNumber =>
    onChange({
      ...file,
      number: newNumber
    });

  const onChangeTitle = newTitle =>
    onChange({
      ...file,
      title: newTitle
    });

  const onChangeLanguage = newLanguage =>
    onChange({
      ...file,
      language: newLanguage
    });

  const preview = () => {
    setPreview(url);
  };

  return loading ? (
    <Loading />
  ) : (
    <UploadChaptersRowTemplate
      className={classNames([classes.row, alternate && classes.alternate])}
      fileName={<AppText>{name}</AppText>}
      pages={pages ? <AppText>{pages}</AppText> : <Loading />}
      chapterNo={
        <div className={classes.inputContainer}>
          <Input
            className={classes.input}
            variant="outlined"
            onChangeText={onChangeNumber}
            value={number}
            error={errors.number}
            fullWidth
            invert
          />
        </div>
      }
      language={
        <div className={classNames([classes.inputContainer, classes.select])}>
          <SelectLanguage
            className={classes.input}
            onChange={onChangeLanguage}
            value={language}
          />
        </div>
      }
      title={
        <div className={classes.inputContainer}>
          <Input
            className={classes.input}
            variant="outlined"
            onChangeText={onChangeTitle}
            placeholder={t('publish.fields.chapter_title_placeholder')}
            value={title}
            error={errors.title}
            fullWidth
            invert
          />
        </div>
      }
      remove={<ButtonRemove onPress={remove} />}
      preview={<ButtonPreview onPress={preview} file={file} />}
    />
  );
};

UploadChaptersRow.defaultProps = {
  alternate: false,
  errors: {}
};

UploadChaptersRow.propTypes = {
  file: PropTypes.object.isRequired,
  alternate: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  setPreview: PropTypes.func.isRequired,
  errors: PropTypes.object,
  uploadChaptersCallbackFn: PropTypes.func.isRequired
};

export default UploadChaptersRow;
