import {
  fork, all, takeLatest, call, select, put,
} from 'redux-saga/effects';
import { firestore, storage } from 'firebase';

import firebase from '../../firebase';
import {
  actions,
  putSubmissions,
  submissionsCreateErrors, submissionsCreateSuccess,
  submissionsItemPut,
} from '../actions/Submissions';
import Constants from '../../../constants';
import { userProfileRequest } from '../actions/User';

const getUser = (state) => ({
  user: state.userReducer.user,
});

const setCall = async (collection, object) => collection.set(object);

const saveCall = async (ref, file) => ref.put(file);

const titleToSlug = (title) => title.toLowerCase()
  .replace(/ /gi, '-');

const itemTransformer = (submission) => {
  const {
    submission_title: title,
    synopsis,
    author: {
      display_name: authorName,
    },
    genre: genres,
    pledged,
    goal,
    rank,
    updated_on: lastUpdated,
  } = submission.data();

  return {
    uuid: submission.id,
    title,
    synopsis,
    authorName,
    genres,
    pledged,
    goal,
    rank,
    lastUpdated,
  };
};

const transformer = (submissions) => {
  const data = [];
  submissions.forEach(async (submission) => {
    const {
      submission_title: title,
      synopsis,
      author: {
        display_name: authorName,
      },
      genre: genres,
      pledged,
      goal,
      rank,
      updated_on: lastUpdated,
    } = submission.data();

    data.push({
      uuid: submission.id,
      title,
      synopsis,
      authorName,
      genres,
      pledged,
      goal,
      rank,
      lastUpdated,
    });
  });

  return [...data];
};

const collection = firestore().collection(
  Constants.firebase.collections.submission,
);

function* saveCoverImageSaga(draftCoversFolder, file) {
  const { name } = file;
  const coverImagePath = `${draftCoversFolder}/${name}`;
  const ref = storage().ref(coverImagePath);

  yield call(saveCall, ref, file);
}

function* saveMaterialSaga(draftFolder, material) {
  const { name, file } = material;
  const source = `${draftFolder}/${name}`;
  const ref = storage().ref(source);
  yield call(saveCall, ref, file);
}

/**
 * This is only the logic for processing the published document. No errors or
 * exceptions are expected within this block.
 *
 * If there are any, they will be caught by the exception handler in
 * createSubmissionSaga.
 *
 * @param title
 * @param synopsis
 * @param goal
 * @param coverImages
 * @param genres
 * @param materials
 */
function* addDocumentSaga({
  title,
  synopsis,
  goal,
  coverImages,
  genres,
  materials,
}) {
  yield put(userProfileRequest());
  const { user } = yield select(getUser);
  const { uid, displayName } = user;

  const slug = titleToSlug(title);

  const draftDocument = firestore()
    .collection(Constants.firebase.collections.submission)
    .doc(slug);

  yield call(
    setCall,
    draftDocument,
    {
      submission_title: title,
      author: {
        display_name: displayName,
        document: `${Constants.firebase.collections.profile}/${uid}`,
      },
      genre: genres,
      synopsis: synopsis || '',
      pledged: 0,
      goal,
      reviewing: true,
      updated_at: new Date(),
    },
  );

  const storyCoversFolder = `/submission_cover/${slug}`;

  yield all(
    coverImages.map((cover) => call(
      saveCoverImageSaga,
      storyCoversFolder,
      cover,
    )),
  );
  // await storyDocument.update({ covers: storyCoverUrls });

  const draftFolder = `/submission_draft/${slug}`;
  yield all(
    materials.map((material) => call(
      saveMaterialSaga,
      draftFolder,
      material,
    )),
  );
}

/**
 * @param uuid
 */
function* getSubmissionSaga({ payload: uuid }) {
  yield fork(
    firebase.firestore.syncDocument,
    `${Constants.firebase.collections.submission}/${uuid}`,
    {
      successActionCreator: submissionsItemPut,
      transform: itemTransformer,
    },
  );
}

function* createSubmissionSaga({
  payload: {
    title,
    synopsis,
    goal,
    coverImages,
    materials,
  },
}) {
  try {
    const { data: { message } } = yield call(
      addDocumentSaga,
      {
        title,
        synopsis,
        goal,
        coverImages,
        materials,
      },
    );
    yield put(submissionsCreateSuccess({ message }));
  } catch (e) {
    let errors = {};
    let message = null;
    if (e.response.data) {
      message = e.response.data.message;
      errors = e.response.data.errors;
    }

    yield put(
      submissionsCreateErrors({
        message: message || 'An unknown error has occurred.',
        errors,
      }),
    );
  }
}

export default function* Submissions() {
  yield all([
    fork(
      firebase.firestore.syncCollection,
      collection.orderBy('pledged', 'desc'),
      {
        successActionCreator: putSubmissions,
        transform: transformer,
      },
    ),
    takeLatest(actions.ITEM.GET, getSubmissionSaga),
    takeLatest(actions.CREATE.REQUEST, createSubmissionSaga),
  ]);
}
