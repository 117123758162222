import { compose, createStore } from 'redux';

import middlewares from './enhancers';
import sagaMiddleware from './enhancers/middlewares';
import reducers from './redux/reducers';
import rootSaga from './redux/sagas';

const enhancers = compose(
  middlewares,
  //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const store = createStore(reducers, enhancers);

sagaMiddleware.run(rootSaga);

export default store;
