import React, { useEffect, useState } from 'react';
import { storage } from 'firebase';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const firebaseStorage = storage();

const styles = makeStyles(() => ({
  avatar: {
    fontFamily: '\'Source Sans Pro\', sans-serif',
    width: 48,
    height: 48,
    fontSize: 17,
    fontWeight: 'bold',
  },
}));

const UserAvatar = ({
  className, name, image, backgroundColor, color,
}) => {

  const classes = styles();
  const [imageUrl, setImageUrl] = useState(null);
  const initials = name.split(' ')
    .map((word) => word.substring(0, 1))
    .concat();

  useEffect(() => {
    if (image && image.indexOf('http') < 0) {
      // Get image because partial url
      const getImage = async () => {
        const url = await firebaseStorage.ref(
          image,
        ).getDownloadURL();

        if (url) {
          setImageUrl(url);
        }
      };

      getImage();
    } else {
      // Assume image is full url
      setImageUrl(image);
    }
  }, [image]);
  
  return (
    <Avatar
      component="div"
      className={classNames([classes.avatar, className])}
      style={{ backgroundColor, color }}
      src={imageUrl}
    >
      { initials }
    </Avatar>
  );
};

UserAvatar.defaultProps = {
  className: null,
  image: null,
  backgroundColor: null,
  color: null,
};

UserAvatar.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  image: PropTypes.string,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
};

export default UserAvatar;
