import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import StandardLayout from '../components/Layout/StandardLayout';
import AppText from '../components/AppText';
import LeaderBoardItem from '../components/Leaderboard/LeaderBoardItem';
import Loading from '../components/Loading';
import Constants from '../constants';
import Button from '../components/Button';
import useRouter from '../services/useRouter';
import { siteLoginOpen } from '../providers/redux/actions/Site';

const styles = makeStyles((theme) => ({
  content: {
    maxWidth: Constants.maxWidth,
    margin: 'auto',
    paddingLeft: theme.spacing(9.5),
    paddingRight: theme.spacing(9.5),
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: '1px solid #FFFFFF',
  },
  header: {
    flex: 1,
    color: theme.palette.common.white,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  contentText: {
    color: '#B2B2B2',
    marginBottom: theme.spacing(3),
  },
  cards: {
    display: 'flex',
    flexDirection: 'row',
  },
  center: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const LeaderboardScreen = ({ data }) => {
  const { t } = useTranslation();
  const classes = styles();
  const { history } = useRouter();
  const dispatch = useDispatch();
  const { loggedIn } = useSelector((state) => ({
    loggedIn: state.userReducer.loggedIn,
  }));

  const submitDraft = () => {
    if (loggedIn) {
      history.push(Constants.routes.DraftSubmit);
    } else {
      dispatch(siteLoginOpen());
    }
  };

  if (!data) {
    return (
      <StandardLayout>
        <div className={classes.center}>
          <Loading size={48} />
        </div>
      </StandardLayout>
    );
  }

  return (
    <StandardLayout>
      <div className={classes.content}>
        <div className={classes.headerContainer}>
          <AppText className={classes.header} header variant="h4">
            {t('leaderboard.title')}
          </AppText>
          <div>
            <Button
              onPress={submitDraft}
              color="primary"
              variant="contained"
            >
              {t('Submit Manga Draft')}
            </Button>
          </div>
        </div>
        { data && data.map(({
          uuid,
          title,
          synopsis,
          authorName,
          genres,
          pledged,
          goal,
          rank,
        }) => (
          <LeaderBoardItem
            key={uuid}
            uuid={uuid}
            number={rank}
            title={title}
            synopsis={synopsis}
            author={authorName}
            genres={genres}
            amount={pledged}
            goal={goal}
          />
        ))}
      </div>
    </StandardLayout>
  );
};

LeaderboardScreen.defaultProps = {
  data: null,
};

LeaderboardScreen.propTypes = {
  data: PropTypes.array,
};

const mapStateToProps = (state) => ({
  data: state.submissionsReducer.data,
});

export default connect(mapStateToProps)(LeaderboardScreen);
