export const actions = {
  LOGIN: {
    REQUEST: 'LOGIN.REQUEST',
    SUCCESS: 'LOGIN.SUCCESS',
    FAILURE: 'LOGIN.FAILURE',
  },
  LOGOUT: {
    REQUEST: 'LOGOUT.REQUEST',
    SUCCESS: 'LOGOUT.SUCCESS',
    FAILURE: 'LOGOUT.FAILURE',
  },
  UPDATE_PW: {
    REQUEST: 'UPDATE_PW.REQUEST',
    SUCCESS: 'UPDATE_PW.SUCCESS',
    ERROR: 'UPDATE_PW.ERROR',
  },
  CREATE: {
    REQUEST: 'USER_CREATE_REQUEST',
    SUCCESS: 'USER_CREATE_SUCCESS',
    ERROR: 'USER_CREATE_ERROR',
  },
  UPDATE: {
    REQUEST: 'USER_UPDATE_REQUEST',
    SUCCESS: 'USER_UPDATE_SUCCESS',
    ERROR: 'USER_UPDATE_ERROR',
  },
  PROFILE: {
    REQUEST: 'USER_PROFILE_REQUEST',
  },
  RESET: {
    REQUEST: 'USER_RESET_REQUEST',
    SUCCESS: 'USER_RESET_SUCCESS',
    ERROR: 'USER_RESET_ERROR',
  },
  POSTS: {
    LOADED: 'USER_POSTS_LOADED',
  },
  SYNC_USER: 'SYNC_USER',
  SYNCED: 'USER_SYNCED',
  SYNC_PROFILE: 'SYNC_PROFILE',
  PROFILE_SYNCED: 'PROFILE_SYNCED',
  SYNC_STORIES: 'SYNC_STORIES',
  STORIES_SYNCED: 'STORIES_SYNCED',
};

export const login = ({ username, password }) => ({
  type: actions.LOGIN.REQUEST,
  username,
  password,
});

export const loginSuccess = ({ user }) => ({
  type: actions.LOGIN.SUCCESS,
  payload: user,
});

export const loginFailure = (error) => ({
  type: actions.LOGIN.FAILURE,
  error,
});

export const logout = () => ({
  type: actions.LOGOUT.REQUEST,
});

export const logoutSuccess = () => ({
  type: actions.LOGOUT.SUCCESS,
});

export const logoutFailure = (error) => ({
  type: actions.LOGOUT.FAILURE,
  error,
});

export const syncUser = () => ({
  type: actions.SYNC_USER,
});

export const syncProfile = () => ({
  type: actions.SYNC_PROFILE,
});

export const userSynced = () => ({
  type: actions.SYNCED,
});

export const profileSynced = (profile) => ({
  type: actions.PROFILE_SYNCED,
  payload: profile,
});

export const userCreateRequest = ({ email, newPassword, confirmPassword }) => ({
  type: actions.CREATE.REQUEST,
  email,
  newPassword,
  confirmPassword,
});

export const userCreateSuccess = ({ message }) => ({
  type: actions.CREATE.SUCCESS,
  payload: message,
});

export const userCreateError = ({ message, createError }) => ({
  type: actions.CREATE.ERROR,
  payload: {
    message,
  },
  createError,
});

export const userUpdateRequest = ({
  displayName, photo, firstName, middleName, lastName, dob, country, bio, bannerImage, profileImage,
}) => ({
  type: actions.UPDATE.REQUEST,
  payload: {
    displayName,
    photo,
    firstName,
    middleName,
    lastName,
    dob,
    country,
    bio,
    bannerImage, 
    profileImage,
  },
});

export const userUpdateSuccess = ({ message }) => ({
  type: actions.UPDATE.SUCCESS,
  payload: message,
});

export const userUpdateError = ({ message, errors }) => ({
  type: actions.UPDATE.ERROR,
  payload: {
    message,
    errors,
  },
});

export const userProfileRequest = () => ({
  type: actions.PROFILE.REQUEST,
});

export const updatePW = ({ currentPassword, newPassword, confirmPassword }) => ({
  type: actions.UPDATE_PW.REQUEST,
  currentPassword,
  newPassword,
  confirmPassword,
});

export const updatePWSuccess = () => ({
  type: actions.UPDATE_PW.SUCCESS,
});

export const updatePWError = ({ error }) => ({
  type: actions.UPDATE_PW.ERROR,
  payload: {
    error,
  },
});

export const userResetPWRequest = ({ forgotEmail, domain }) => ({
  type: actions.RESET.REQUEST,
  forgotEmail,
  domain,
});

export const resetPWSuccess = () => ({
  type: actions.RESET.SUCCESS,
});

export const resetPWError = ({ forgotError }) => ({
  type: actions.RESET.ERROR,
  forgotError,
});

export const syncStories = () => ({
  type: actions.SYNC_STORIES,
});

export const storiesSynced = (stories) => ({
  type: actions.STORIES_SYNCED,
  payload: stories,
});

export const userPostsLoaded = (posts) => ({
  type: actions.POSTS.LOADED,
  payload: posts,
});
