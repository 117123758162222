import React from 'react';
import PropTypes from 'prop-types';
import { Reply } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';
import Button from './index';
import AppText from '../AppText';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(),
    color: theme.palette.common.white,
    transform: 'scaleX(-1)',
  },
  text: {
    fontSize: '1rem',
    lineHeight: '1.38rem',
    color: theme.palette.common.white,
  },
}));

const ButtonShareWithIcon = ({ className, onPress }) => {
  const classes = useStyles();
  return (
    <Button className={className} onPress={onPress}>
      <Reply className={classes.icon} />
      <AppText className={classes.text}>Share</AppText>
    </Button>
  );
};

ButtonShareWithIcon.defaultProps = {
  className: null,
};

ButtonShareWithIcon.propTypes = {
  className: PropTypes.string,
  onPress: PropTypes.func.isRequired,
};

export default ButtonShareWithIcon;
