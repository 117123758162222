import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { IconButton, makeStyles } from '@material-ui/core';
import { RemoveRedEye } from '@material-ui/icons';

const styles = makeStyles(() => ({
  button: {
    borderRadius: '50%',
    backgroundColor: 'transparent'
  },
  icon: {
    width: 28,
    height: 28
  }
}));

const ButtonPreview = ({ className, onPress, file }) => {
  const classes = styles();
  return (
    <IconButton
      className={classNames([classes.button, className])}
      onClick={() => window.open(file.url, '_blank')}
    >
      <RemoveRedEye className={classes.icon} />
    </IconButton>
  );
};

ButtonPreview.defaultProps = {
  className: null,
  file: {
    url: ''
  }
};

ButtonPreview.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onPress: PropTypes.func.isRequired
};

export default ButtonPreview;
