import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AppText from '../AppText';
import UploadFilesRowTemplate from './UploadFilesRowTemplate';

const styles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(3),
    fontSize: 18,
    color: '#B9B9B9',
    fontWeight: 600,
    borderBottom: '1px solid #DEDEDE',
  },
}));

const UploadFilesHeader = () => {
  const { t } = useTranslation();
  const classes = styles();
  return (
    <UploadFilesRowTemplate
      className={classes.row}
      fileName={<AppText>{t('publish.fields.file_name')}</AppText>}
      captions={<AppText>{t('captions')}</AppText>}
    />
  );
};

export default UploadFilesHeader;
