import React, {
  useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core';
import {
  POSITION_END,
  POSITION_MIDDLE,
  POSITION_START,
} from './PositionConstants';

const styles = makeStyles(() => ({
  slider: {
    flex: 1,
    position: 'absolute',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    transition: '200ms ease-in-out',
  },
}));

const CarouselSlider = ({
  children, index, width, container, setLocation,
}) => {
  const classes = styles();
  const theme = useTheme();
  const [maxWidth, setMaxWidth] = useState(0);
  const [position, setPosition] = useState(0);
  const sliderRef = useRef(null);

  useLayoutEffect(() => {
    const updateSize = () => {
      setMaxWidth(container.current ? container.current.offsetWidth : 0);
    };

    window.addEventListener('resize', updateSize);

    return () => window.removeEventListener('resize', updateSize);
  }, [container]);

  useEffect(() => {
    setMaxWidth(container.current ? container.current.offsetWidth : 0);
  }, [container]);

  useEffect(() => {
    const newPosition = index * (width + theme.spacing(2));
    if (sliderRef.current) {
      const sliderWidth = sliderRef.current.offsetWidth;
      const endReached = (sliderWidth - theme.spacing(2)) - newPosition
        <= maxWidth;
      if (endReached) {
        setLocation(POSITION_END);
      } else if (newPosition === 0) {
        setLocation(POSITION_START);
      } else {
        setLocation(POSITION_MIDDLE);
      }

      setPosition(endReached ? sliderWidth - maxWidth : newPosition);
    }
  }, [index, width, maxWidth, setLocation, theme]);

  return (
    <div
      ref={sliderRef}
      className={classes.slider}
      style={{
        msTransform: `translateX(-${position}px)`,
        transform: `translateX(-${position}px)`,
      }}
    >
      { children }
    </div>
  );
};

CarouselSlider.defaultProps = {
  index: 0,
};

CarouselSlider.propTypes = {
  container: PropTypes.object.isRequired,
  index: PropTypes.number,
  width: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  setLocation: PropTypes.func.isRequired,
};

export default CarouselSlider;
