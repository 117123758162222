import React from 'react';
import PropTypes from 'prop-types';
import { Button, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const styles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: '#518435',
    fontSize: 18,
    fontFamily: '\'Montserrat\', sans-serif',
    padding: theme.spacing(2),
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#6bb047',
    },
  },
}));

const ButtonPledge = ({ className, onPress }) => {
  const classes = styles();
  const { t } = useTranslation();
  return (
    <Button
      className={className}
      variant="contained"
      href={null}
      onClick={onPress}
      classes={{
        root: classes.root,
      }}
    >
      {t('Pledge')}
    </Button>
  );
};

ButtonPledge.defaultProps = {
  className: null,
};

ButtonPledge.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onPress: PropTypes.func.isRequired,
};

export default ButtonPledge;
