import {
  all, takeLatest, put, select, call, fork, take,
} from 'redux-saga/effects';
import { firestore } from 'firebase';
import {
  actions,
  readerChapterLoad,
  readerChapterLoadFromReference,
  readerStoryLoad, readerStoryLoaded,
} from '../actions/Reader';
import firebase from '../../firebase';
import Constants from '../../../constants';

const itemTransformer = (story) => {
  const {
    story_title: title,
    synopsis,
    author: {
      display_name: authorName,
      document: authorDocument,
    },
    genre: genres,
    updated_on: lastUpdated,
    cover,
  } = story.data();

  return {
    uuid: story.id,
    id: story.id,
    title,
    synopsis,
    authorName,
    authorDocument,
    chapters: story.ref.collection(Constants.firebase.collections.chapters),
    genres,
    lastUpdated,
    cover,
  };
};

const getFromReference = async (reference) => reference.get();

const getCurrentUser = (state) => ({
  user: state.userReducer.user,
});

const getCurrentStory = (state) => ({
  story: state.readerReducer.story,
});

const getNextChapterSnapshot = async ({
  chapters,
  storyChapter,
}) => chapters
  .orderBy('number', 'asc')
  .startAfter(storyChapter)
  .get();

const getPreviousChapterSnapshot = async ({
  chapters,
  storyChapter,
}) => chapters
  .orderBy('number', 'desc')
  .startAfter(storyChapter)
  .get();

/**
 * @param uuid
 * @returns {IterableIterator<*>}
 */
function* loadStoryFromIdSaga({ payload: uuid }) {
  yield fork(
    firebase.firestore.syncDocument,
    `${Constants.firebase.collections.stories}/${uuid}`,
    {
      successActionCreator: readerStoryLoad,
      transform: itemTransformer,
    },
  );
}

/**
 * @returns {IterableIterator<*>}
 */
function* storyLoadedSaga() {
  yield put(readerStoryLoaded());
}

/**
 * @returns {IterableIterator<*>}
 */
function* loadFromReferenceSaga({ payload: storyChapter }) {
  const { document } = storyChapter.data();
  const chapter = yield call(getFromReference, document);

  const { story } = yield select(getCurrentStory);
  const nextChapterSnapshot = yield call(
    getNextChapterSnapshot,
    { chapters: story.chapters, storyChapter },
  );

  const previousChapterSnapshot = yield call(
    getPreviousChapterSnapshot,
    { chapters: story.chapters, storyChapter },
  );

  const nextChapter = nextChapterSnapshot.docs.shift();
  const previousChapter = previousChapterSnapshot.docs.shift();

  yield put(
    readerChapterLoad({
      chapterReference: storyChapter,
      previousChapter,
      chapter,
      nextChapter,
    }),
  );
}

/**
 * gotoChapterSaga
 * @returns {IterableIterator<*>}
 */
function* gotoChapterSaga({ payload: { storyId, uuid } }) {
  if (storyId) {
    yield fork(loadStoryFromIdSaga, { payload: storyId });
    yield take(actions.STORY.LOADED);
  }

  const { story } = yield select(getCurrentStory);
  const chapterRef = story.chapters.doc(uuid);
  const storyChapter = yield call(getFromReference, chapterRef);

  yield put(
    readerChapterLoadFromReference({
      storyChapter,
    }),
  );

  const { user } = yield select(getCurrentUser);
  const ref = firestore().doc(
    `${Constants.firebase.collections.bookmarks}/${user.uid}/${Constants.firebase.collections.stories}/${storyId}`,
  );

  ref.set({
    chapter: chapterRef,
  });
}

export default function* Reader() {
  yield all([
    takeLatest(actions.STORY.LOAD, storyLoadedSaga),
    takeLatest(actions.CHAPTER.GOTO, gotoChapterSaga),
    takeLatest(actions.CHAPTER.REFERENCE, loadFromReferenceSaga),
  ]);
}
