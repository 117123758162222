export const actions = {
  GET: 'SUBGENRES_GET',
  PUT: 'SUBGENRES_PUT',
  RESET: 'SUBGENRES_RESET',
};

export const getSubGenres = () => ({
  type: actions.GET,
});

export const resetSubGenres = () => ({
  type: actions.RESET,
});

export const putSubGenres = (subGenres) => ({
  type: actions.PUT,
  payload: subGenres,
});
