import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { transactionCreateRequest } from '../../providers/redux/actions/Payment';

const AdyenCheckout = ({ paymentMethodResponse, amount, comment, authorId }) => {
  const { user } = useSelector((state) => ({
    user: state.userReducer.user,
  }));

  const dispatch = useDispatch();

  const submit = (paymentData, note) => {
    dispatch(
      transactionCreateRequest({
        payerId: user.uid,
        payeeId: authorId,
        amount,
        paymentMethodData: paymentData,
        comment: note,
      }),
    );
  };

  const initAdyenCheckout = () => {
    const checkout = new window.AdyenCheckout({
      locale: process.env.REACT_APP_ADYEN_LOCALE, // might change to dynamic based on lang selector
      environment: process.env.REACT_APP_ADYEN_ENV,
      originKey: process.env.REACT_APP_ADYEN_ORIGIN_KEY, // Mandatory. originKey from Costumer Area
      paymentMethodsResponse: paymentMethodResponse,
      amount: {
        value: amount * 100,
        currency: process.env.REACT_APP_ADYEN_CURRENCY,
      },
    });

    // 2. Create and mount the Component
    checkout
      .create('dropin', {
        onSubmit: (state, dropin) => {
          if (state.isValid) {
            submit(state.data.paymentMethod, comment);
          }
        },
      })
      .mount('#pay-component');
  };

  useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = process.env.REACT_APP_ADYEN_SDK_CSS;
    document.head.appendChild(link);

    const tempDiv = document.createElement('div');
    tempDiv.id = 'pay-component';
    tempDiv.async = true;
    document.getElementById('adyen-checkout').appendChild(tempDiv);

    const script = document.createElement('script');
    script.src = process.env.REACT_APP_ADYEN_SDK_JS;
    script.async = true;
    script.onload = initAdyenCheckout; // Wait until the script is loaded before init AdyenCheckout
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
      document.head.removeChild(link);
      document.getElementById('pay-component').innerHTML = '';
    };
  }, []);

  useEffect(() => {
    if (amount > 0) {
      if (
        document.getElementById('pay-component')
        && document.getElementById('pay-component').hasChildNodes
      ) {
        document.getElementById('pay-component').innerHTML = '';
      }
      initAdyenCheckout();
    }
  }, [amount, comment]);

  return <div id="adyen-checkout" />;
};

AdyenCheckout.defaultProps = {
  amount: 0,
};

AdyenCheckout.propTypes = {
  paymentMethodResponse: PropTypes.object.isRequired,
  amount: PropTypes.number,
  comment: PropTypes.string.isRequired,
  authorId: PropTypes.string.isRequired,
};

export default AdyenCheckout;
