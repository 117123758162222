import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import StandardLayout from '../components/Layout/StandardLayout';
import useRouter from '../services/useRouter';
import ButtonBack from '../components/Button/ButtonBack';
import LayoutDark from '../components/Layout/LayoutDark';
import AuthorProfile from '../components/Author/AuthorProfile';
import AuthorPublishedStories from '../components/Author/AuthorPublishedStories';
import AuthorVideos from '../components/Author/AuthorVideos';
import AppText from '../components/AppText';
import { authorsItemGet } from '../providers/redux/actions/Authors';
import { getPaymentMethods } from '../providers/redux/actions/Payment';
import Loading from '../components/Loading';
import DiscussionPost from '../components/Discussion/DiscussionPost';
import ResponsiveWrapper from '../components/ResponsiveWrapper';

const useStyles = makeStyles((theme) => ({
  back: {
    textAlign: 'left',
    color: theme.palette.common.white,
    marginBottom: theme.spacing(3),
  },
  container: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  buttonContainer: {
    paddingTop: theme.spacing(5),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    width: '100%',
    margin: 'auto',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(),
      paddingRight: theme.spacing(),
    },
  },
  header: {
    color: '#B9B9B9',
    fontWeight: 600,
    marginBottom: theme.spacing(2),
  },
  layout: {
    marginTop: -100,
  },
  post: {
    backgroundColor: '#212121',
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(),
      paddingRight: theme.spacing(),
    },
  },
}));

const AuthorScreen = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { match } = useRouter();
  const { uuid } = match.params;

  const {
    author, loading, paymentLoading, paymentMethods,
  } = useSelector(
    (state) => ({
      loading: state.authorsReducer.item.loading,
      author: state.authorsReducer.item.data,
      paymentLoading: state.paymentsReducer.payment_loading,
      paymentMethods: state.paymentsReducer.payment_methods,
    }),
  );

  useEffect(() => {
    dispatch(authorsItemGet({ uuid }));
    dispatch(getPaymentMethods());
  }, [uuid]);

  if (
    loading
    || author === null
    || paymentLoading
    || paymentMethods === null
  ) {
    return (
      <StandardLayout>
        <Loading />
      </StandardLayout>
    );
  }

  const {
    name, bio, stories, profileImage, bannerImage, posts,
  } = author;
  return (
    <StandardLayout banner={bannerImage}>
      <ResponsiveWrapper className={classes.buttonContainer}>
        <ButtonBack className={classes.back} white />
      </ResponsiveWrapper>
      <LayoutDark className={classes.layout}>
        <div className={classes.container}>
          <AuthorProfile
            id={uuid}
            name={name}
            bio={bio}
            image={profileImage}
            paymentMethodResponse={paymentMethods}
          />
          <AppText className={classes.header} variant="h5">
            {t('author.videos')}
          </AppText>
          <AuthorVideos videos={[]} />
          <AppText className={classes.header} variant="h5">
            {t('author.works')}
          </AppText>
          <AuthorPublishedStories stories={stories || []} />
          {(posts.length > 0) && (
          <AppText className={classes.header} variant="h5">
            {t('author.posts')}
          </AppText>
          )}
          <div>
            {posts.map(
              ({
                createdAt,
                description,
                title,
                uuid: postUuid,
                author: postAuthor,
              }) => (
                <DiscussionPost
                  className={classes.post}
                  user={postAuthor}
                  createdAt={createdAt}
                  description={description}
                  title={title}
                  uuid={postUuid}
                  imageWidth={128}
                />
              ),
            )}
          </div>
        </div>
      </LayoutDark>
    </StandardLayout>
  );
};

export default AuthorScreen;
