import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Card, makeStyles } from '@material-ui/core';
import Constants from '../../constants';
import ResponsiveWrapper from '../ResponsiveWrapper';

const styles = makeStyles((theme) => ({
  container: {
    width: '100%',
    margin: 'auto',
    boxSizing: 'border-box',
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  header: {
    marginTop: theme.spacing(5.5),
    paddingBottom: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3.5),
      paddingBottom: theme.spacing(8.5),
    },
  },
  content: {
    backgroundColor: '#EFEFEF',
  },
  contentContainer: {
    borderRadius: 16,
    position: 'relative',
    top: -theme.spacing(6),
    marginBottom: theme.spacing(4),
  },
  page: {
    padding: theme.spacing(4),
  },
  accountForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    width: 92,
    height: 92,
    fontSize: 20,
    marginRight: theme.spacing(5),
  },
  info: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  welcome: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    color: Constants.colors.white,
    alignItems: 'center',
  },
  welcomeText: {
    fontSize: 48,
  },
  tabs: {
    padding: theme.spacing(4),
    textAlign: 'center',
  },
  tab: {
    background: 'transparent',
    fontSize: 18,
  },
  buttonContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  button: {
    flex: 1,
  },
}));

const LayoutLight = forwardRef(({ header, children, disableCard }, ref) => {
  const classes = styles();
  return (
    <div ref={ref}>
      <div className={classes.header}>
        <ResponsiveWrapper className={classes.container}>
          {header}
        </ResponsiveWrapper>
      </div>
      <div className={classes.content}>
        <ResponsiveWrapper className={classes.container}>
          {!disableCard
            ? (
              <Card className={classes.contentContainer}>
                {children}
              </Card>
            )
            : children}
        </ResponsiveWrapper>
      </div>
    </div>
  );
});

LayoutLight.defaultProps = {
  disableCard: false,
};

LayoutLight.propTypes = {
  header: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  disableCard: PropTypes.bool,
};

export default LayoutLight;
