const routes = {
  Home: '/',
  About: '/about',
  Login: '/login',
  Story: '/story',
  Register: '/register',
  Leaderboard: '/leaderboard',
  DraftSubmit: '/draft/publish',
  StoryPublish: '/story/publish',
  Akademy: '/akademy',
  AkademyCreator: '/akademy/creator',
  Profile: '/profile',
  Draft: '/draft',
  Reader: '/read',
  Discussion: '/discussion',
  Author: '/author',
  PostCreate: '/discussion/create',
  FileStore: 'https://firebasestorage.googleapis.com/v0/b/ekakaku-dev.appspot.com/o',
};

export default routes;
