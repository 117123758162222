import React from 'react';
import PropTypes from 'prop-types';
import { LinearProgress, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import ButtonPledge from '../Button/ButtonPledge';
import ButtonShare from '../Button/ButtonShare';
import StatisticsCard from '../StatisticsCard';

const styles = makeStyles((theme) => ({
  statistics: {
    marginBottom: theme.spacing(4),
  },
  progress: {
    marginBottom: theme.spacing(4),
  },
  root: {
    height: 10,
    borderRadius: 4,
  },
  bar: {
    backgroundColor: '#518435',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
  },
  contentGoal: {
    flex: 3,
  },
  contentItem: {
    flex: 1,
  },
  pledgeButton: {
    marginBottom: theme.spacing(3),
  },
  disclaimer: {
    fontSize: 14,
    color: '#8E8E8E',
  },
}));

const StoryStretchGoal = ({
  uuid, className, amount, goal, days,
}) => {
  const { t } = useTranslation();
  const classes = styles();
  const value = (amount / goal) * 100;
  return (
    <div className={className}>
      <LinearProgress
        className={classes.progress}
        variant="determinate"
        value={value > 100 ? 100 : value}
        classes={{
          root: classes.root,
          bar: classes.bar,
        }}
      />
      <div className={classes.content}>
        <div className={classes.contentGoal}>
          <StatisticsCard
            className={classes.statistics}
            value={(
              <NumberFormat
                prefix="$"
                decimalScale={2}
                fixedDecimalScale
                value={amount}
                displayType="text"
                thousandSeparator
              />
            )}
            description={t('pledged', { goal })}
          />
          <StatisticsCard
            className={classes.statistics}
            value={days}
            description={t('story.days_remaining')}
          />
        </div>
        <div className={classes.contentItem}>
          <ButtonPledge
            className={classes.pledgeButton}
            onPress={() => {}}
          />
          <ButtonShare onPress={() => {}} />
        </div>
      </div>
      <div className={classes.disclaimer}>
        {t('story.disclaimer', { date: 'Fri, October 4 2019 7:00 AM AWST' })}
      </div>
    </div>
  );
};

StoryStretchGoal.defaultProps = {
  className: null,
};

StoryStretchGoal.propTypes = {
  className: PropTypes.string,
  uuid: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  goal: PropTypes.number.isRequired,
  days: PropTypes.number.isRequired,
};

export default StoryStretchGoal;
