import {
  fork, all, takeLatest, select, call, put, take,
} from 'redux-saga/effects';
import { firestore, storage } from 'firebase';
import firebase from '../../firebase';
import {
  actions,
  putStories,
  storiesCreateErrors,
  storiesCreateSuccess,
  storiesItemPut, storiesLastRead,
} from '../actions/Stories';
import Constants from '../../../constants';
import { userProfileRequest } from '../actions/User';

const getUser = (state) => ({
  user: state.userReducer.user,
  authenticated: state.userReducer.authenticated,
});

const getCall = async (ref) => ref.get();

const setCall = async (collection, object) => collection.set(object);

const saveCall = async (ref, file) => ref.put(file);

const addCall = async (ref, object) => ref.add(object);

const updateCall = async (ref, object) => ref.update(object);

const titleToSlug = (title) => title.toLowerCase()
  .replace(/ /gi, '-');

function* saveCoverImageSaga(storyCoversFolder, file) {
  const { name } = file;
  const coverImagePath = `${storyCoversFolder}/${name}`;
  const ref = storage().ref(coverImagePath);

  yield call(saveCall, ref, file);
}

function* saveChapterSaga(
  chaptersFolder,
  slug,
  storyDocument,
  file,
) {
  const {
    number, title, language, file: chapterFile,
  } = file;
  const chapterNumber = parseInt(number, 10);
  const chapterRef = yield call(
    addCall,
    firestore()
      .collection(Constants.firebase.collections.chapters),
    {
      chapter_number: chapterNumber,
      chapter_title: title,
      language,
      reviewing: true,
      story: firestore().doc(`${Constants.firebase.collections.stories}/${slug}`),
      created_at: new Date(),
      updated_at: new Date(),
    },
  );

  const { id: chapterId } = chapterRef;

  const source = `${chaptersFolder}/${chapterId}.pdf`;
  const ref = storage().ref(source);
  yield call(saveCall, ref, chapterFile);
  yield call(updateCall, chapterRef, { source });
  yield call(
    setCall,
    storyDocument.collection(Constants.firebase.collections.chapters)
      .doc(`chapter-${chapterNumber.toString()}`),
    {
      title,
      number: chapterNumber,
      document: firestore().doc(`${Constants.firebase.collections.chapters}/${chapterId}`),
      created_at: new Date(),
      updated_at: new Date(),
    },
  );
}

/**
 * This is only the logic for processing the published document. No errors or
 * exceptions are expected within this block.
 *
 * If there are any, they will be caught by the exception handler in
 * createStorySaga.
 *
 * @param title
 * @param synopsis
 * @param genres
 * @param coverImages
 * @param chapters
 * @param estimatedLength
 * @param color
 */
function* addDocumentSaga({
  title,
  synopsis,
  genres,
  coverImages,
  // contentRatings,
  // language,
  // readingStyle,
  chapters,
  // chapterFiles,
  estimatedLength,
  color,
}) {
  yield put(userProfileRequest());
  const { user } = yield select(getUser);
  const { uid, displayName } = user;

  const slug = titleToSlug(title);

  const storyDocument = firestore()
    .collection(Constants.firebase.collections.stories)
    .doc(slug);

  yield call(
    setCall,
    storyDocument,
    {
      story_title: title,
      author: {
        display_name: displayName,
        document: firestore().doc(`${Constants.firebase.collections.profile}/${uid}`),
      },
      genre: genres,
      synopsis: synopsis || '',
      estimated_chapters: estimatedLength,
      color,
      reviewing: true,
      updated_at: new Date(),
    },
  );

  const storyCoversFolder = `/story_cover/${slug}`;

  yield all(
    coverImages.map((cover) => call(
      saveCoverImageSaga,
      storyCoversFolder,
      cover,
    )),
  );
  // await storyDocument.update({ covers: storyCoverUrls });

  const chaptersFolder = `/chapter/${slug}`;
  yield all(
    chapters.map((chapter) => call(
      saveChapterSaga,
      chaptersFolder,
      slug,
      storyDocument,
      chapter,
    )),
  );
}

const mapAuthor = ({ display_name: authorName, document: authorDocument }) => ({
  authorName,
  authorDocument,
});

const itemTransformer = (story) => {
  const {
    story_title: title,
    synopsis,
    author,
    authors,
    genre: genres,
    updated_on: lastUpdated,
    cover,
    reviewing,
  } = story.data();

  if (reviewing) {
    return null;
  }

  return {
    uuid: story.id,
    title,
    synopsis,
    authors: authors ? authors.map(mapAuthor) : [mapAuthor(author)],
    chapters: story.ref.collection(Constants.firebase.collections.chapters),
    genres,
    lastUpdated,
    cover,
  };
};

const transformer = (stories) => {
  const data = [];
  stories.forEach((story) => {
    const {
      story_title: title,
      synopsis,
      author,
      authors,
      genre: genres,
      updated_on: lastUpdated,
      cover,
    } = story.data();

    data.push({
      uuid: story.id,
      title,
      synopsis,
      authors: authors ? authors.map(mapAuthor) : [mapAuthor(author)],
      chapters: story.ref.collection(Constants.firebase.collections.chapters),
      genres,
      lastUpdated,
      cover,
    });
  });

  return data;
};

const collection = firestore().collection(Constants.firebase.collections.stories);

function* getStorySaga({ payload: uuid }) {
  yield fork(
    firebase.firestore.syncDocument,
    `${Constants.firebase.collections.stories}/${uuid}`,
    {
      successActionCreator: storiesItemPut,
      transform: itemTransformer,
    },
  );
}

function* createStorySaga({
  payload: {
    title,
    synopsis,
    genres,
    coverImages,
    chapters,
    estimatedLength,
    color,
    contentRatings,
    language,
    readingStyle,
  },
}) {
  try {
    const errors = {};
    if (title === '') {
      errors.title = 'Title is required';
    }

    const slug = titleToSlug(title);
    const snapshot = yield call(
      getCall,
      firestore().doc(`${Constants.firebase.collections.stories}/${slug}`),
    );

    if (snapshot.exists) {
      errors.title = 'Title already exists';
    }

    if (synopsis === '') {
      errors.synopsis = 'Synopsis is required';
    }

    if (genres === '') {
      errors.genres = 'Genres are required';
    }

    if (estimatedLength === '') {
      errors.estimatedLength = 'Estimated number of chapters are required';
    }

    if (color === '') {
      errors.color = 'Color is required';
    }

    const chaptersErrors = {};
    chapters.forEach(({ number, title: chapterTitle }, key) => {
      const chapterErrors = {};
      if (chapterTitle === '') {
        chapterErrors.title = 'Chapter title is required';
      }

      if (number === '') {
        chapterErrors.number = 'Chapter number is required';
      }

      if (Object.values(chapterErrors).length > 0) {
        chaptersErrors[key] = chapterErrors;
      }
    });

    if (Object.values(chaptersErrors).length > 0) {
      errors.chapters = chaptersErrors;
    }

    if (Object.values(errors).length > 0) {
      yield put(
        storiesCreateErrors({
          message: 'Please fix the errors in your form.',
          errors,
        }),
      );

      return;
    }

    yield call(
      addDocumentSaga,
      {
        title,
        synopsis,
        genres,
        coverImages,
        estimatedLength,
        color,
        contentRatings,
        language,
        readingStyle,
        chapters,
      },
    );
    yield put(storiesCreateSuccess());
  } catch (e) {
    let errors = {};
    let message = null;
    if (e.response && e.response.data) {
      message = e.response.data.message;
      errors = e.response.data.errors;
    }

    yield put(
      storiesCreateErrors({
        message: message || 'An unknown error has occurred.',
        errors,
      }),
    );
  }
}

function* putStorySaga() {
  while (true) {
    const { payload: story } = yield take(actions.ITEM.PUT);

    if (story) {
      const { user, authenticated } = yield select(getUser);

      if (authenticated) {
        const document = firestore().doc(
          `${Constants.firebase.collections.bookmarks}/${user.uid}/${Constants.firebase.collections.stories}/${story.uuid}`,
        );

        const lastRead = yield call(getCall, document);
        if (lastRead.exists) {
          const { chapter } = lastRead.data();
          yield put(storiesLastRead(chapter));
        } else {
          yield put(storiesLastRead(null));
        }
      }
    }
  }
}

export default function* Stories() {
  yield all([
    fork(
      firebase.firestore.syncCollection,
      collection.where('reviewing', '==', false)
        .orderBy('updated_at', 'desc'),
      {
        successActionCreator: putStories,
        transform: transformer,
      },
    ),
    fork(putStorySaga),
    takeLatest(actions.ITEM.GET, getStorySaga),
    takeLatest(actions.CREATE.REQUEST, createStorySaga),
  ]);
}
