import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AuthorPublishedStories from '../Author/AuthorPublishedStories';
import Button from '../Button';
import AppText from '../AppText';
import constants from '../../constants';
import useRouter from '../../services/useRouter';

const TNCDoc = constants.routes.FileStore + '/web_assets%2F20200203_komakaku_grp_tnc.pdf?alt=media&token=9d7273eb-5d58-41ae-95e6-e436c23b1999';

const styles = makeStyles((theme) => ({
  content: {
    textAlign: 'center',
  },
  text: {
    marginBottom: theme.spacing(3),
  },
  tnc: {
      marginTop: theme.spacing(1),
      fontFamily: '\'Montserrat\', sans-serif',
      fontSize: 10,
      color: '#b9b9b9',
      textAlign: 'center',
  },
}));

const WorksTab = ({
  stories,
}) => {
  const { t } = useTranslation();
  const { history } = useRouter();
  const classes = styles();

  if (stories && stories.length > 0) {
    return (
      <AuthorPublishedStories
        stories={Object.keys(stories).map((key) => stories[key])}
        theme="light"
        editable
      />
    );
  }
  return (
    <Grid container>
      <Grid className={classes.content} item xs={12}>
        <AppText className={classes.text} variant="h6">
          {t('profile.works_empty')}
        </AppText>
      </Grid>
      <Grid className={classes.content} item xs={12}>
        <Button
          variant="contained"
          color="primary"
          onPress={() => history.push(
            `${constants.routes.StoryPublish}`,
          )}
        >
          {t('profile.works_start')}
        </Button>
        <AppText className={classes.tnc}>
            {t('profile.tncPre')}
            <a className={classes.tnc} href={TNCDoc} target="_blank">{t('profile.tncLinkText')}</a>
        </AppText>
      </Grid>
    </Grid>
  );
};

WorksTab.propTypes = {
  stories: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  stories: state.userReducer.stories,
});

export default connect(mapStateToProps, {})(WorksTab);
