import { actions } from '../actions/Withdrawal';

const initialState = {
  data: {
    amount: '',
    bank_name: '',
    bank_number: '',
    status: '',
    timestamp: '',
    user_id: '',
    error: ''
  },
  isLoading: false,
  history_data: [],
  isHistoryLoading: false
};

const withdrawalReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case actions.WITHDRAWAL.REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case actions.WITHDRAWAL.SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload
      };
    case actions.WITHDRAWAL.ERROR:
      return {
        ...state,
        isLoading: false,
        data: {
          error: 'Error in withdraw request'
        }
      };
    case actions.WITHDRAWAL_HISTORY.REQUEST:
      return {
        ...state,
        isHistoryLoading: true
      };
    case actions.WITHDRAWAL_HISTORY.SUCCESS: {
      return {
        ...state,
        isHistoryLoading: false,
        history_data: action.payload
      };
    }
    case actions.WITHDRAWAL_HISTORY.ERROR: {
      return {
        ...state,
        isHistoryLoading: false,
        history_data: {
          error: 'Error getting withdrawal history'
        }
      };
    }
    default:
      return {
        ...initialState
      };
  }
};

export default withdrawalReducer;
