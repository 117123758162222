import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { KeyboardArrowRightRounded } from '@material-ui/icons';
import Button from '../Button';
import useRouter from '../../services/useRouter';
import Constants from '../../constants';
import AppText from '../AppText';

const styles = makeStyles((theme) => ({
  button: {
    flex: 1,
    margin: theme.spacing(),
    borderRadius: theme.spacing(),
    justifyContent: 'flex-end',
  },
  icon: {
    color: theme.palette.common.white,
    marginLeft: theme.spacing(),
  },
}));

const ReaderNextChapterButton = () => {
  const classes = styles();
  const { history } = useRouter();
  const { story, nextChapter } = useSelector((state) => ({
    story: state.readerReducer.story,
    nextChapter: state.readerReducer.nextChapter,
  }));

  const navigate = () => history.push(
    `${Constants.routes.Reader}/${story.uuid}/${nextChapter.id}`,
  );

  const { t } = useTranslation();
  return (
    <Button
      className={classes.button}
      onPress={navigate}
    >
      <AppText>
        {t('Next Chapter')}
      </AppText>
      <KeyboardArrowRightRounded className={classes.icon} />
    </Button>
  );
};

export default ReaderNextChapterButton;
