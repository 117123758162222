import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import { storage } from 'firebase';
import Carousel from '../Carousel';
import imageSet from '../../providers/helpers/ImageSet';

const firebaseStorage = storage();

const styles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(4),
    userSelect: 'none',
    webkitUserSelect: 'none',
    msUserSelect: 'none',
  },
  carousel: {
    flex: 1,
  },
}));

const DiscussionCarousel = ({
  className, uuid, width, height,
}) => {
  const [images, setImages] = useState([]);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const getImages = async () => {
      const folder = await firebaseStorage.ref(
        `discussions/${uuid}`,
      )
        .listAll();

      const imageUrls = await Promise.all(
        imageSet(folder.items).map(
          async (reference) => reference.getDownloadURL(),
        ),
      );

      setImages(imageUrls);
    };

    getImages();
  }, [uuid]);

  const classes = styles();

  const previous = () => index > 0 && setIndex(index - 1);
  const next = () => index < images.length && setIndex(index + 1);

  return (
    <div
      className={classNames([classes.container, className])}
      style={{ height }}
    >
      <Carousel
        className={classes.carousel}
        width={width}
        next={next}
        previous={previous}
        index={index}
        images={images}
      />
    </div>
  );
};

DiscussionCarousel.defaultProps = {
  className: null,
  width: 400,
  height: 320,
};

DiscussionCarousel.propTypes = {
  className: PropTypes.string,
  uuid: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default DiscussionCarousel;
