import React from 'react';

import { BrowserRouter, Switch, Route } from 'react-router-dom';
import ErrorBoundary from '../components/ErrorBoundary';
import HomeScreen from './HomeScreen';
import LoginScreen from './LoginScreen';
import AboutScreen from './AboutScreen';
import Constants from '../constants';
import PostCreateScreen from './PostCreateScreen';
import StoryScreen from './StoryScreen';
import LeaderboardScreen from './LeaderboardScreen';
import SubmissionScreen from './SubmissionScreen';
import ProfileScreen from './ProfileScreen';
import DraftScreen from './DraftScreen';
import PublishScreen from './PublishScreen';
import RegisterScreen from './RegisterScreen';
import AuthorScreen from './AuthorScreen';
import ReaderScreen from './ReaderScreen';
import DiscussionBoardScreen from './DiscussionBoardScreen';
import DiscussionScreen from './DiscussionScreen';
import AkademyScreen from './AkademyScreen';
import AkademyCreatorScreen from './AkademyCreatorScreen';

const Navigator = () => (
  <ErrorBoundary>
    <BrowserRouter>
      <Switch>
        <Route
          path={`${Constants.routes.AkademyCreator}/:uuid`}
          component={AkademyCreatorScreen}
        />
        <Route
          path={Constants.routes.Akademy}
          component={AkademyScreen}
          exact
        />
        <Route
          path={Constants.routes.About}
          component={AboutScreen}
        />
        <Route
          path={Constants.routes.Login}
          component={LoginScreen}
        />
        <Route
          path={Constants.routes.DraftSubmit}
          component={SubmissionScreen}
        />
        <Route
          path={Constants.routes.StoryPublish}
          component={PublishScreen}
        />
        <Route
          path={`${Constants.routes.Story}/:uuid`}
          component={StoryScreen}
        />
        <Route
          path={Constants.routes.PostCreate}
          component={PostCreateScreen}
        />
        <Route
          path={[
            Constants.routes.Leaderboard,
            Constants.routes.Story,
          ]}
          component={LeaderboardScreen}
        />
        <Route
          path={`${Constants.routes.Draft}/:uuid`}
          component={DraftScreen}
        />
        <Route
          path={Constants.routes.Profile}
          component={ProfileScreen}
        />
        <Route
          path={Constants.routes.Register}
          component={RegisterScreen}
        />
        <Route
          path={`${Constants.routes.Author}/:uuid`}
          component={AuthorScreen}
        />
        <Route
          path={`${Constants.routes.Reader}/:storyId/:chapterId`}
          component={ReaderScreen}
        />
        <Route
          path={`${Constants.routes.Discussion}/:uuid`}
          component={DiscussionScreen}
        />
        <Route
          path={Constants.routes.Discussion}
          component={DiscussionBoardScreen}
          exact
        />
        <Route
          path={Constants.routes.Home}
          component={HomeScreen}
        />
      </Switch>
    </BrowserRouter>
  </ErrorBoundary>
);

export default Navigator;
