import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import classNames from 'classnames';
import Constants from '../../../constants';
import Button from '../../Button';
import AppText from '../../AppText';
import DiscussionBoardPost from './DiscussionBoardPost';
import useRouter from '../../../services/useRouter';

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: theme.spacing(6),
    maxWidth: Constants.maxWidth,
    width: '100%',
    boxSizing: 'border-box',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(9.5),
      paddingRight: theme.spacing(9.5),
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(),
    },
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: '1px solid #FFFFFF',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(),
      paddingRight: theme.spacing(),
    },
  },
  header: {
    flex: 1,
    color: theme.palette.common.white,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  },
  contentText: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(3),
  },
  cards: {
    display: 'flex',
    flexDirection: 'row',
  },
  center: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  posts: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  button: {
    marginLeft: theme.spacing(),
  },
  mobileButton: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 10,
    paddingBottom: 10,
    textAlign: 'center',
    color: theme.palette.common.white,
  },
  hideMobile: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  showMobile: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
}));

const DiscussionBoard = ({ posts }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { history } = useRouter();

  const createPost = () => history.push(Constants.routes.PostCreate);

  return (
    <div className={classes.content}>
      <div className={classes.headerContainer}>
        <AppText className={classes.header} header variant="h4">
          {t('discussions.title')}
        </AppText>
        <div className={classes.hideMobile}>
          <ButtonGroup
            className={classes.button}
            variant="contained"
            color="primary"
            size="small"
            aria-label="small contained button group"
          >
            <Button onPress={() => {}}>
              {t('discussions.sort_top')}
            </Button>
            <Button onPress={() => {}}>
              {t('discussions.sort_latest')}
            </Button>
          </ButtonGroup>
          <Button
            className={classes.button}
            onPress={createPost}
            color="primary"
            variant="contained"
          >
            {t('discussions.share_koma')}
          </Button>
        </div>
        <Button
          className={classNames([
            classes.mobileButton,
            classes.showMobile,
          ])}
          onPress={createPost}
          color="primary"
          variant="contained"
        >
          <Add />
        </Button>
      </div>
      <div className={classes.posts}>
        { posts.map(({
          uuid, author, title, description, createdAt,
        }) => (
          <DiscussionBoardPost
            uuid={uuid}
            user={author}
            description={description}
            title={title}
            createdAt={createdAt}
          />
        ))}
      </div>
    </div>
  );
};

DiscussionBoard.propTypes = {
  posts: PropTypes.arrayOf(
    PropTypes.shape({
      user: PropTypes.object.isRequired,
      title: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default DiscussionBoard;
