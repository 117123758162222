import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import moment from 'moment';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import StandardLayout from '../components/Layout/StandardLayout';
import Constants from '../constants';
import UserAvatar from '../components/User/UserAvatar';
import AppText from '../components/AppText';
import useProtectRoute from '../providers/hooks/useProtectRoute';
import LayoutLight from '../components/Layout/LayoutLight';
import ProfileTab from '../components/Profile/ProfileTab';
import WorksTab from '../components/Profile/WorksTab';
import PostTab from '../components/Profile/PostTab';
import Wallet from '../components/Wallet';
import List from '../components/List';
import Loading from '../components/Loading';

import Button from '../components/Button';
import TransactionHistory from '../components/TransactionHistory';

import {
  createWithdrawalRequest,
  createWithdrawalHistoryRequest
} from '../providers/redux/actions/Withdrawal';

/* eslint-disable react/jsx-props-no-spreading */
const useStyles = makeStyles(theme => ({
  avatar: {
    width: 92,
    height: 92,
    fontSize: 20,
    marginRight: theme.spacing(5)
  },
  info: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  welcome: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  welcomeText: {
    color: Constants.colors.white
  },
  withdrawalButtonDisabled: {
    background: '#EDEDED'
  },
  withdrawalButtonEnabled: {
    background: '#D4A367'
  },
  alertButton: {
    background: '#FF0000'
  },
  withdrawalButtonTextDisabled: {
    color: '#8E8E8E'
  },
  withdrawalButtonTextEnabled: {
    scolor: '#fff'
  },
  transactions: {
    marginBottom: theme.spacing(2)
  },
  transactionActivity: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.defaultProps = {
  children: null
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const ProfileScreen = () => {
  useProtectRoute();
  const { t } = useTranslation();
  const {
    user,
    posts,
    profile,
    transaction,
    withdrawalHistoryData,
    isWithdrawalHistoryLoading
  } = useSelector(state => ({
    user: state.userReducer.user,
    posts: state.userReducer.posts,
    profile: state.userReducer.profile,
    transaction: state.transactionsReducer.data,
    withdrawalHistoryData: state.withdrawalReducer.history_data,
    isWithdrawalHistoryLoading: state.withdrawalReducer.isHistoryLoading
  }));

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [openDialog, setDialogOpen] = React.useState(false);
  const [bankNameInput, setBankNameInput] = React.useState('');
  const [bankAccNoInput, setBankAccNoInput] = React.useState('');

  const { displayName, email, uid } = user || {};

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = index => {
    setValue(index);
  };

  const handleClickOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const transformDateFormat = date_in_seconds => {
    const formattedSeconds = new Date(date_in_seconds * 1000);
    const formattedDateInMoment = moment(formattedSeconds).format('YYYY/MM/DD');
    return formattedDateInMoment;
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(createWithdrawalHistoryRequest(uid));
  }, [value]);

  let withdrawalAmount = 0;
  transaction.map(transactionItem => {
    withdrawalAmount += transactionItem.amount;
  });

  const name = displayName || email || '';

  const submitForm = () => {
    handleCloseDialog();
    return dispatch(
      createWithdrawalRequest({
        bank_name: bankNameInput,
        bank_number: bankAccNoInput,
        status: 'PENDING',
        user_id: uid,
        timestamp: moment.utc()._d,
        amount: withdrawalAmount
      })
    );
  };

  return (
    <StandardLayout>
      <LayoutLight
        header={
          <div className={classes.info}>
            <div className={classes.welcome}>
              <UserAvatar
                name={name}
                className={classes.avatar}
                image={profile.profileImage}
              />
              <AppText className={classes.welcomeText} variant="h2" header>
                {`${t('profile.hi')}${name}`}
              </AppText>
            </div>
          </div>
        }
      >
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Request Withdrawal</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please enter your bank name and bank account number.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label="Bank name"
              type="text"
              onChange={e => setBankNameInput(e.target.value)}
              fullWidth
            />
            <TextField
              margin="dense"
              label="Bank Account Number"
              type="text"
              onChange={e => setBankAccNoInput(e.target.value)}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onPress={handleCloseDialog} className={classes.alertButton}>
              Cancel
            </Button>
            <Button
              onPress={submitForm}
              className={classes.withdrawalButtonEnabled}
            >
              Submit Request
            </Button>
          </DialogActions>
        </Dialog>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label={t('profile.title')} {...a11yProps(0)} />
          <Tab label={t('profile.works')} {...a11yProps(1)} />
          <Tab label={t('profile.posts')} {...a11yProps(2)} />
          <Tab label={t('Transaction Activity')} {...a11yProps(3)} />
        </Tabs>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <ProfileTab />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <WorksTab />
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <PostTab posts={posts} />
          </TabPanel>
          <TabPanel value={value} index={3} dir={theme.direction}>
            <div className={classes.transactionActivity}>
              <Wallet amount={withdrawalAmount} />
              <TransactionHistory className={classes.transactions} />
              <List
                title={t('Withdrawal History')}
                description={t('Minimum withdrawal amount is $ 100')}
                header={
                  <TableHead>
                    <TableCell>{t('Date')}</TableCell>
                    <TableCell>{t('Withdrawal Amount')}</TableCell>
                    <TableCell>{t('Description')}</TableCell>
                    <TableCell>{t('Status')}</TableCell>
                  </TableHead>
                }
                actions={
                  <Button
                    className={
                      withdrawalAmount >= 100
                        ? classes.withdrawalButtonEnabled
                        : classes.withdrawalButtonDisabled
                    }
                    onPress={handleClickOpenDialog}
                    textClassName={
                      withdrawalAmount >= 100
                        ? classes.withdrawalButtonTextEnabled
                        : classes.withdrawalButtonTextDisabled
                    }
                    disabled={!(withdrawalAmount >= 100)}
                  >
                    {t('Withdrawal Request')}
                  </Button>
                }
              >
                {isWithdrawalHistoryLoading && <Loading />}
                {!isWithdrawalHistoryLoading &&
                  withdrawalHistoryData.length > 0 &&
                  withdrawalHistoryData.map(
                    ({ amount, timestamp, bank_name, bank_number, status }) => (
                      <TableRow>
                        <TableCell>
                          {transformDateFormat(timestamp.seconds)}
                        </TableCell>
                        <TableCell>
                          <NumberFormat
                            prefix="$"
                            decimalScale={2}
                            fixedDecimalScale
                            value={amount}
                            displayType="text"
                            thousandSeparator
                          />
                        </TableCell>
                        <TableCell>{`BANK TRANSFER ${bank_name} ${bank_number}`}</TableCell>
                        <TableCell>{status}</TableCell>
                      </TableRow>
                    )
                  )}
              </List>
            </div>
          </TabPanel>
        </SwipeableViews>
      </LayoutLight>
    </StandardLayout>
  );
};

ProfileScreen.propTypes = {
  user: PropTypes.object.isRequired
};

export default ProfileScreen;
