import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment';
import Button from '../Button';
import DiscussionBoardPostCover
  from '../Discussion/DiscussionBoard/DiscussionBoardPostCover';
import LeaderBoardItemTitle
  from '../Leaderboard/LeaderBoardItem/LeaderBoardItemTitle';
import AppText from '../AppText';
import BadgeComments from '../Badge/BadgeComments';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(2),
    alignItems: 'flex-start',
    border: '1px solid #D5D5D5',
    borderRadius: 10,
  },
  synopsis: {
    marginBottom: theme.spacing(5),
  },
  text: {
    marginTop: theme.spacing(1),
    color: '#151515',
  },
  author: {
    display: 'flex',
    flexDirection: 'row',
  },
  authorName: {
    fontWeight: 'bold',
    color: theme.palette.common.white,
    marginLeft: theme.spacing(),
  },
  image: {
    [theme.breakpoints.up('sm')]: {
      width: 180,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  read: {
    flex: 1,
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  row: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  actions: {
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  stats: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  edit: {
    background: '#EDEDED',
  },
  editText: {
    color: '#151515',
  },
  remove: {
    marginLeft: theme.spacing(2),
    border: '1px solid #E86868',
  },
  removeText: {
    color: '#E86868',
  },
  comments: {
    color: '#151515',
  },
  statsDetails: {
    marginLeft: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
}));

const UserPost = ({
  uuid, title, description, createdAt,
}) => {
  const classes = useStyles();
  const created = moment(createdAt.seconds * 1000);

  const edit = () => {

  };

  const remove = () => {

  };

  return (
    <div className={classes.container}>
      <div className={classes.image}>
        <DiscussionBoardPostCover uuid={uuid} />
      </div>
      <div className={classes.read}>
        <LeaderBoardItemTitle text={title} />
        <AppText
          className={classNames([classes.text, classes.synopsis])}
          variant="body1"
        >
          { description }
        </AppText>
        <div className={classes.row}>
          <div className={classes.actions}>
            <Button
              textClassName={classes.editText}
              className={classes.edit}
              onPress={edit}
            >
              Edit Post
            </Button>
            <Button
              textClassName={classes.removeText}
              className={classes.remove}
              onPress={remove}
            >
              Remove Post
            </Button>
          </div>
          <div className={classes.stats}>
            <BadgeComments
              className={classes.comments}
              iconClassName={classes.comments}
              count={13}
            />
            <div className={classes.statsDetails}>
              <AppText>
                {`Posted ${created.fromNow()}`}
              </AppText>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

UserPost.propTypes = {
  uuid: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
};

export default UserPost;
