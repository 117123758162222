import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import AppText from '../AppText';

const styles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    textAlign: 'center',
  },
  header: {
    color: '#151515',
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  amount: {
    color: '#518435',
    fontWeight: 'normal',
  },
}));

const Wallet = ({ amount }) => {
  const classes = styles();
  const { t } = useTranslation();
  return (
    <div className={classes.container}>
      <AppText className={classes.header} variant="h5" header>
        {t('Balance')}
      </AppText>
      <AppText className={classes.amount} variant="h2">
        <NumberFormat
          prefix="$"
          decimalScale={2}
          value={amount}
          displayType="text"
          thousandSeparator
          fixedDecimalScale
        />
      </AppText>
    </div>
  );
};

Wallet.propTypes = {
  amount: PropTypes.number.isRequired,
};

export default Wallet;
