export const actions = {
  GET_PAYMENT_METHODS: 'PAYMENT_METHODS_GET',
  PUT_PAYMENT_METHODS: 'PAYMENT_METHODS_PUT',
  TRANSACTION: {
    RESET: 'TRANSACTION_CREATE_RESET',
    REQUEST: 'TRANSACTION_CREATE_REQUEST',
    SUCCESS: 'TRANSACTION_CREATE_SUCCESS',
    ERROR: 'TRANSACTION_CREATE_ERROR',
  },
};

export const getPaymentMethods = () => ({
  type: actions.GET_PAYMENT_METHODS,
});

export const putPaymentMethods = (payments) => ({
  type: actions.PUT_PAYMENT_METHODS,
  payload: payments,
});

export const transactionCreateReset = () => ({
  type: actions.TRANSACTION.RESET,
});

export const transactionCreateRequest = ({
  payerId, amount, paymentMethodData, comment, payeeId,
}) => ({
  type: actions.TRANSACTION.REQUEST,
  payload: {
    payerId,
    payeeId,
    amount,
    paymentMethodData,
    comment,
  }
});

export const transactionCreateSuccess = (result) => ({
  type: actions.TRANSACTION.SUCCESS,
  payload: result,
});

export const transactionCreateError = ({ message, errors }) => ({
  type: actions.TRANSACTION.ERROR,
  payload: {
    message,
    errors,
  },
});
