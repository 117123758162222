import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  item: {
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
  },
  fileName: {
    flex: 1,
  },
  captions: {
    flex: 2,
  },
  action: {
    width: 20,
  },
}));

const UploadFilesRowTemplate = ({
  className, fileName, captions, remove,
}) => {
  const classes = styles();
  return (
    <div className={className}>
      <div className={classNames([classes.fileName, classes.item])}>
        {fileName}
      </div>
      <div className={classNames([classes.captions, classes.item])}>
        {captions}
      </div>
      <div className={classNames([classes.action, classes.item])}>
        {remove}
      </div>
    </div>
  );
};

UploadFilesRowTemplate.defaultProps = {
  className: null,
  remove: null,
};

UploadFilesRowTemplate.propTypes = {
  className: PropTypes.string,
  fileName: PropTypes.node.isRequired,
  captions: PropTypes.node.isRequired,
  remove: PropTypes.node,
};

export default UploadFilesRowTemplate;
