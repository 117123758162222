import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const styles = makeStyles(theme => ({
  item: {
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing()
  },
  fileName: {
    flex: 5
  },
  pages: {
    flex: 1
  },
  language: {
    flex: 3
  },
  chapterNo: {
    flex: 2
  },
  title: {
    flex: 5
  },
  action: {
    flex: 1
  },
  pullRight: {
    flex: 6
  }
}));

const UploadChaptersRowTemplate = ({
  className,
  fileName,
  pages,
  chapterNo,
  title,
  remove,
  preview,
  language
}) => {
  const classes = styles();
  return (
    <div className={className}>
      <div className={classNames([classes.fileName, classes.item])}>
        {fileName}
      </div>
      <div className={classNames([classes.pages, classes.item])}>{pages}</div>
      <div className={classNames([classes.chapterNo, classes.item])}>
        {chapterNo}
      </div>
      <div className={classNames([classes.language, classes.item])}>
        {language}
      </div>
      <div className={classNames([classes.title, classes.item])}>{title}</div>
      <div className={classNames([classes.action, classes.item])}>{remove}</div>
      <div
        className={classNames([
          classes.action,
          classes.item,
          classes.pullRight
        ])}
      >
        {preview}
      </div>
    </div>
  );
};

UploadChaptersRowTemplate.defaultProps = {
  className: null,
  remove: null,
  preview: null
};

UploadChaptersRowTemplate.propTypes = {
  className: PropTypes.string,
  fileName: PropTypes.node.isRequired,
  pages: PropTypes.node.isRequired,
  chapterNo: PropTypes.node.isRequired,
  language: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  remove: PropTypes.node,
  preview: PropTypes.node
};

export default UploadChaptersRowTemplate;
