import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';
import Rating from '../Rating/Rating';
import AppText from '../AppText';
import Button from '../Button';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    background: 'center / cover no-repeat',
    height: 300,
    borderRadius: 0,
    width: '100%',
  },
  gradient: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'linear-gradient(180deg, rgba(255,255,255,0) 31%, #151515  100%)',
  },
  details: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    justifyContent: 'flex-end',
  },
  stats: {
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    textTransform: 'uppercase',
    color: theme.palette.common.white,
    fontWeight: 'bold',
  },
  views: {
    marginLeft: theme.spacing(3),
    color: theme.palette.common.white,
    fontWeight: 'bold',
  },
}));

const AkademyClassCard = ({
  onPress, image, title, views, rating,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Button
      className={classes.card}
      style={{ backgroundImage: `url('${image}')` }}
      onPress={onPress}
    >
      <div className={classes.gradient} />
      <div className={classes.details}>
        <AppText className={classes.title}>
          { title }
        </AppText>
        <div className={classes.stats}>
          <Rating ratings={rating} />
          <AppText className={classes.views} variant="caption">
            <NumberFormat
              decimalScale={0}
              suffix={` ${t('views')}`}
              value={views}
              displayType="text"
              thousandSeparator
            />
          </AppText>
        </div>
      </div>
    </Button>
  );
};

AkademyClassCard.propTypes = {
  onPress: PropTypes.func.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  views: PropTypes.number.isRequired,
  rating: PropTypes.number.isRequired,
};

export default AkademyClassCard;
