import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  makeStyles,
  Collapse,
  Divider,
} from '@material-ui/core';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Input from '../Input/Input';
import Button from '../Button';
import { login, userCreateRequest } from '../../providers/redux/actions/User';
import Loading from '../Loading';
import ErrorBox from '../ErrorBox';
import { siteLoginClose } from '../../providers/redux/actions/Site';

import AppText from '../AppText';

const styles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    flex: 1,
    fontSize: 28,
    fontWeight: 500,
    color: theme.palette.common.black,
    marginTop: theme.spacing(),
    textAlign: 'center',
  },
  registerLink: {
    flex: 1,
    fontFamily: '\'Montserrat\', sans-serif',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  mutedSubtitle: {
    flex: 1,
    fontSize: 16,
    color: '#999',
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: '-15px',
    marginRight: '-15px',
    padding: 0,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  button: {
    fontFamily: '\'Montserrat\', sans-serif',
    textTransform: 'uppercase',
    flex: 1,
  },
  input: {
    marginTop: theme.spacing(2),
    labelRoot: {
      fontFamily: '\'Montserrat\', sans-serif',
    },
  },
}));

const HeaderLoginForm = ({
  error,
  createError,
  loading,
  processing,
  loggedIn,
  login: loginAction,
  userCreateRequest: userCreateRequestAction,
}) => {
  const { t } = useTranslation();
  const { open } = useSelector((state) => ({
    open: state.siteReducer.login,
  }));
  const [openRegister, setOpenRegister] = useState(false);

  const dispatch = useDispatch();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const classes = styles();

  const setClose = () => dispatch(siteLoginClose());

  useEffect(() => {
    if (loggedIn) {
      dispatch(siteLoginClose());
    }
  }, [loggedIn, dispatch]);

  const submit = () => loginAction({ username, password });
  const submitRegister = () => userCreateRequestAction({ email, newPassword, confirmPassword });

  const handleRegisterOpen = () => {
    setOpenRegister((prev) => !prev);
  };

  const onKeyPress = (e) => {
    if (!loading && !(username === '' || password === '')) {
      if (e.key === 'Enter') {
        loginAction({ username, password });
      }
    }
  };

  const onRegisterKeyPress = (e) => {
    if (!processing && !(email === '' || newPassword === '' || confirmPassword === '')) {
      if (e.key === 'Enter') {
        userCreateRequestAction({ email, newPassword, confirmPassword });
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={!loading ? setClose : null}
      onBackdropClick={!loading ? setClose : null}
      maxWidth="sm"
      fullWidth
    >
      <Collapse in={!openRegister}>
        <DialogContent className={classes.content}>
          <AppText className={classes.title} header>{t('login.title')}</AppText>
          <Input
            label={t('forms.user.email')}
            className={classes.input}
            onKeyPress={onKeyPress}
            onChangeText={setUsername}
            value={username}
            fullWidth
            invert
          />
          <Input
            label={t('forms.user.password')}
            className={classes.input}
            onKeyPress={onKeyPress}
            onChangeText={setPassword}
            value={password}
            fullWidth
            secure
            invert
          />
          <ErrorBox error={error} center />
          <div className={classes.buttonContainer}>
            { loading
              ? <Loading size={48} />
              : (
                <Button
                  className={classes.button}
                  variant="contained"
                  onPress={submit}
                  disabled={username === '' || password === ''}
                  color="primary"
                >
                  {t('login.title')}
                </Button>
              )}
          </div>
          <Divider className={classes.divider} />
          <AppText className={classes.mutedSubtitle}>{t('register.no_account')}</AppText>
          <Button
            variant="contained"
            fullWidth
            className={classes.button}
            onPress={handleRegisterOpen}
          >
            {t('register.title')}
          </Button>
        </DialogContent>
      </Collapse>
      <Collapse in={openRegister}>
        <DialogContent className={classes.content}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            className={classes.registerLink}
            onClick={handleRegisterOpen}
            to={null}
          >
            {t('register.back')}
          </Link>
          <AppText className={classes.title} header>{t('register.title')}</AppText>
          <Input
            label={t('forms.user.email')}
            className={classes.input}
            onKeyPress={onRegisterKeyPress}
            onChangeText={setEmail}
            value={email}
            fullWidth
            invert
          />
          <Input
            label={t('forms.user.password')}
            className={classes.input}
            onKeyPress={onRegisterKeyPress}
            onChangeText={setNewPassword}
            value={newPassword}
            secure
            fullWidth
            invert
          />
          <Input
            label={t('forms.user.confirm_password')}
            className={classes.input}
            onKeyPress={onRegisterKeyPress}
            onChangeText={setConfirmPassword}
            value={confirmPassword}
            secure
            fullWidth
            invert
          />
          <ErrorBox error={createError} center />
          <div className={classes.buttonContainer}>
            {processing
              ? <Loading />
              : (
                <Button
                  variant="contained"
                  className={classes.button}
                  onPress={submitRegister}
                  disabled={email === '' || newPassword === '' || confirmPassword === ''}
                >
                  {t('register.submit')}
                </Button>
              )}
          </div>
        </DialogContent>
      </Collapse>
    </Dialog>
  );
};

HeaderLoginForm.defaultProps = {
  error: null,
  createError: null,
};

HeaderLoginForm.propTypes = {
  processing: PropTypes.bool.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  createError: PropTypes.string,
  login: PropTypes.func.isRequired,
  userCreateRequest: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  processing: state.userReducer.create.processing,
  success: state.userReducer.create.success,
  message: state.userReducer.create.message,
  loading: state.userReducer.loading,
  loggedIn: state.userReducer.loggedIn,
  error: state.userReducer.error,
  createError: state.userReducer.createError,
});

export default connect(mapStateToProps, { login, userCreateRequest })(HeaderLoginForm);
