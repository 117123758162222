import React, { useEffect, useState } from 'react';
import { storage } from 'firebase';
import PropTypes from 'prop-types';
import {
  CardMedia, makeStyles, Box,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import StoryCardTitle from '../../StoryCardTitle';
import AppText from '../../AppText';
import Rating from '../../Rating/Rating';
// import BadgeGenre from '../../Badge/BadgeGenre';
import useRouter from '../../../services/useRouter';
import Button from '../../Button';
import Constants from '../../../constants';

const firebaseStorage = storage();

const styles = makeStyles((theme) => ({
  button: {
    padding: 0,
    letterSpacing: 0,
    width: '100%',
    marginBottom: theme.spacing(3),
  },
  actionButton: {
    width: 120,
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
    display: 'inline-block',
    backgroundColor: '#EDEDED',
    boxShadow: 'none',
    '& .MuiButton-label > p': {
      textTransform: 'uppercase',
      color: '#151515',
    },
    '& .MuiTypography-root': {
      fontSize: '0.85em',
      fontFamily: '\'Montserrat\', sans-serif',
    },
  },
  card: {
    width: '100%',
    backgroundColor: '#212121',
    padding: theme.spacing(3),
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(),
      paddingRight: theme.spacing(),
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  cardLight: {
    width: '100%',
    backgroundColor: '#ffffff',
    padding: theme.spacing(2),
    border: '1px solid #D5D5D5',
    borderRadius: 10,
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  cover: {
    width: 120,
    height: 180,
    background: '#E7F1F7',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#86A4B5',
    padding: theme.spacing(3),
    fontSize: '1.2rem',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  },
  coverContainer: {
    [theme.breakpoints.down('xs')]: {
      textAlign: '-webkit-center',
    },
    flex: 1,
    [theme.breakpoints.up('sm')]: {
      maxWidth: 200,
    },
  },
  workContainer: {
    flex: 1,
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(2),
  },
  synopsis: {
    marginTop: theme.spacing(2),
    color: '#ffffff',
    textAlign: 'justify',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.05rem',
    },
  },
  synopsisLight: {
    marginTop: theme.spacing(2),
    color: '#151515',
    textAlign: 'justify',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.05rem',
    },
  },
  genres: {
    marginTop: theme.spacing(2),
  },
  editables: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      textAlign: '-webkit-center',
    },
  },
  titleBox: {
    display: 'block',
    [theme.breakpoints.down('xs')]: {
      textAlign: '-webkit-center',
    },
  },
  title: {
    display: 'inline-block',
  },
  stats: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      textAlign: '-webkit-center',
    },
  },
  views: {
    color: '#8E8E8E',
    marginTop: '-2px',
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  rating: {
    '& .MuiSvgIcon-root': {
      fontSize: '0.6em',
    },
    width: 'fit-content',
  },
  reviewingBox: {
    borderRadius: 4,
    border: '1px solid #d1ad77',
    padding: '4px 7px',
    color: '#d1ad77',
    fontSize: '0.85em',
    position: 'relative',
    top: '-5px',
  },
  publishedBox: {
    borderRadius: 4,
    border: '1px solid #d1ad77',
    padding: '4px 7px',
    color: '#fff',
    backgroundColor: '#D4A367',
    fontSize: '0.85em',
    position: 'relative',
    top: '-5px',
  },
  icon: {
    fontSize: '1.15em',
    position: 'relative',
    top: '2.2px',
  },
}));

const AuthorPublishedStoriesCard = ({
  id, name, synopsis, reviewing, themeId, editable,
}) => {
  const { t } = useTranslation();
  const { history } = useRouter();
  const [image, setImage] = useState(null);
  const classes = styles();

  const placeholderStyle = image ? { display: 'none' } : {};

  useEffect(() => {
    if (id) {
      const getImage = async () => {
        const folder = await firebaseStorage.ref(
          `story_cover/${id}`,
        ).listAll();

        const coverImage = folder.items[0];
        if (coverImage) {
          const url = await coverImage.getDownloadURL();
          if (url) {
            setImage(url);
          }
        }
      };

      getImage();
    }
  }, [id]);

  return (
    <Button
      className={classes.button}
      onPress={() => history.push(
        `${Constants.routes.Story}/${id}`,
      )}
    >
      <div className={classes[`card${themeId === 'light' ? 'Light' : ''}`]} elevation={(themeId === 'light' ? 0 : 1)}>
        <div className={classes.coverContainer}>
          <CardMedia
            className={classes.cover}
            image={image}
          >
            <AppText style={placeholderStyle}>{t('No cover image')}</AppText>
          </CardMedia>
        </div>
        <div className={classes.workContainer}>
          <div className={classes.titleBox}>
            <StoryCardTitle className={classes.title} text={name} />
            {editable && reviewing ? (
              <Box className={classes.reviewingBox} component="span" m={1}>
                Reviewing
              </Box>
            ) : null}
            {editable && !reviewing ? (
              <Box className={classes.publishedBox} component="span" m={1}>
                <CheckCircleOutlineRoundedIcon className={classes.icon} />
                {' '}
Published
              </Box>
            ) : null}
          </div>
          <div className={classes.stats}>
            <Rating className={classes.rating} size={14} ratings={5} />
            <AppText className={classes.views}>1,400,294 views</AppText>
          </div>
          <AppText className={classes[`synopsis${themeId === 'light' ? 'Light' : ''}`]} variant="body1">{synopsis}</AppText>
          {/* <div key={name} className={classes.genres}>
            { genres.map((genre) => <BadgeGenre key={genre} text={genre} />)}
          </div> */}
          { editable ? (
            <div className={classes.editables}>
              <Button
                variant="contained"
                className={classes.actionButton}
                onPress={null}
              >
                {t('profile.add_chapters')}
              </Button>
              <Button
                variant="contained"
                className={classes.actionButton}
                onPress={null}
              >
                {t('profile.edit_details')}
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    </Button>
  );
};

AuthorPublishedStoriesCard.defaultProps = {
  themeId: 'dark',
};

AuthorPublishedStoriesCard.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  synopsis: PropTypes.string.isRequired,
  themeId: PropTypes.string,
  editable: PropTypes.bool.isRequired,
  reviewing: PropTypes.bool.isRequired,
};

export default AuthorPublishedStoriesCard;
