export const actions = {
  GET: 'SUBMISSIONS_GET',
  PUT: 'SUBMISSIONS_PUT',
  RESET: 'SUBMISSIONS_RESET',
  ITEM: {
    PUT: 'SUBMISSIONS_ITEM_PUT',
    GET: 'SUBMISSIONS_ITEM_GET',
    RESET: 'SUBMISSIONS_ITEM_RESET',
  },
  CREATE: {
    REQUEST: 'SUBMISSIONS_CREATE_REQUEST',
    SUCCESS: 'SUBMISSIONS_CREATE_SUCCESS',
    ERRORS: 'SUBMISSIONS_CREATE_ERRORS',
  },
};

export const getSubmissions = () => ({
  type: actions.GET,
});

export const putSubmissions = (submissions) => ({
  type: actions.PUT,
  payload: submissions,
});


export const resetSubmissions = () => ({
  type: actions.RESET,
});


export const submissionsItemGet = ({ uuid }) => ({
  type: actions.ITEM.GET,
  payload: uuid,
});

export const submissionsItemPut = (submission) => ({
  type: actions.ITEM.PUT,
  payload: submission,
});

export const submissionsItemReset = () => ({
  type: actions.ITEM.RESET,
});

export const submissionsCreateRequest = ({
  title,
  synopsis,
  goal,
  coverImages,
  materials,
}) => ({
  type: actions.CREATE.REQUEST,
  payload: {
    title,
    synopsis,
    goal,
    coverImages,
    materials,
  },
});

export const submissionsCreateSuccess = ({ message }) => ({
  type: actions.CREATE.SUCCESS,
  payload: message,
});

export const submissionsCreateErrors = ({ message, errors }) => ({
  type: actions.CREATE.ERRORS,
  payload: {
    message,
    errors,
  },
});
