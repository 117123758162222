import React from 'react';
import { Star, StarHalf, StarBorder } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  star: {
    color: '#D9CF52',
  },
}));

const RatingStar = ({ filled, half, size }) => {
  const classes = useStyles();
  if (half) {
    return (
      <StarHalf
        className={classes.star}
        style={{ fontSize: `${size}` }}
      />
    );
  }

  if (filled) {
    return (
      <Star
        className={classes.star}
        style={{ fontSize: `${size}` }}
      />
    );
  }

  return (
    <StarBorder
      className={classes.star}
      style={{ fontSize: `${size}` }}
    />
  );
};

RatingStar.defaultProps = {
  half: false,
  filled: false,
  size: 20,
};

RatingStar.propTypes = {
  half: PropTypes.bool,
  filled: PropTypes.bool,
  size: PropTypes.number,
};

export default RatingStar;
