import React from 'react';
import {
  FormControl,
  Checkbox as MaterialCheckbox,
  FormControlLabel,
  makeStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';

const styles = makeStyles(() => ({
  label: {
    fontFamily: '\'Montserrat\', sans-serif',
  }
}));

const Checkbox = ({
  className, label, checked, onChange, value,
}) => {
  const classes = styles();

  return (
    <FormControl className={className}>
      <FormControlLabel
        control={(
          <MaterialCheckbox
            color="primary"
            onChange={() => onChange(value)}
            checked={checked}
            value={value}
          />
          )}
        label={label}
        classes={classes}
      />
    </FormControl>
  );
};

Checkbox.defaultProps = {
  className: null,
};

Checkbox.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
};

export default Checkbox;
