import React from 'react';
import PropTypes from 'prop-types';
import {
  Menu,
  MenuList,
  MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { logout } from '../../providers/redux/actions/User';
import Constants from '../../constants';
import useRouter from '../../services/useRouter';

const styles = makeStyles(() => ({
  menu: {
    '& .MuiMenu-paper': {
      backgroundColor: '#151515',
      color: '#fff',
      border: '1px solid #707070'
    },
    '& .MuiMenuItem-root': {
      fontFamily: '\'Source Sans Pro\', sans-serif',
      fontSize: 15,
    },
    '& .MuiList-root': {
      padding: 8,
      paddingBottom: 8,
      outline: 'none',
    },
  },
}));

const HeaderUserDropdown = ({
  anchorRef,
  open,
  setClose,
  logout: logoutAction,
}) => {
  const { t } = useTranslation();
  const { history } = useRouter();
  const classes = styles();
  return (
    <Menu
      elevation={2}
      anchorEl={anchorRef.current}
      open={open}
      onClose={setClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      getContentAnchorEl={null}
      keepMounted
      className={classes.menu}
    >
      <MenuList>
        <MenuItem onClick={() => history.push(Constants.routes.Profile)}>
          {t('profile.title')}
        </MenuItem>
        <MenuItem onClick={() => logoutAction()}>
          {t('logout')}
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

HeaderUserDropdown.defaultProps = {
  anchorRef: null,
};

HeaderUserDropdown.propTypes = {
  anchorRef: PropTypes.object,
  open: PropTypes.bool.isRequired,
  setClose: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

export default connect(null, { logout })(HeaderUserDropdown);
