import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import HeaderNavigationItem from './HeaderNavigationItem';
import Constants from '../../../constants';

const styles = makeStyles((theme) => ({
  header: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

const HeaderNavigation = () => {
  const classes = styles();
  return (
    <div className={classes.header}>
      {/*<HeaderNavigationItem
        link={Constants.routes.About}
        text="about.title"
      />*/}
      <HeaderNavigationItem
        link={Constants.routes.Discussion}
        text="links.discussion"
      />
      <HeaderNavigationItem
        link={Constants.routes.StoryPublish}
        text="links.submit"
        authentication
      />
      <HeaderNavigationItem
        link={Constants.routes.Akademy}
        text="links.akademy"
      />
    </div>
  );
};

export default HeaderNavigation;
