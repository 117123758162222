import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '../Button';
import AppText from '../AppText';
import UserPost from '../User/UserPost';
import Constants from '../../constants';
import useRouter from '../../services/useRouter';

const styles = makeStyles((theme) => ({
  content: {
    textAlign: 'center',
  },
  text: {
    marginBottom: theme.spacing(3),
  },
}));

const PostTab = ({
  posts,
}) => {
  const { t } = useTranslation();
  const { history } = useRouter();
  const classes = styles();

  if (posts && posts.length > 0) {
    return (
      <div>
        {posts.map(({
          createdAt, description, title, uuid,
        }) => (
          <UserPost
            createdAt={createdAt}
            description={description}
            title={title}
            uuid={uuid}
          />
        ))}
      </div>
    );
  }

  return (
    <Grid container>
      <Grid className={classes.content} item xs={12}>
        <AppText className={classes.text} variant="h6">
          {t('profile.posts_empty')}
        </AppText>
      </Grid>
      <Grid className={classes.content} item xs={12}>
        <Button
          variant="contained"
          color="primary"
          onPress={() => history.push(
            `${Constants.routes.Discussion}`,
          )}
        >
          {t('profile.posts_start')}
        </Button>
      </Grid>
    </Grid>
  );
};

PostTab.propTypes = {
  posts: PropTypes.array.isRequired,
};

export default PostTab;
