import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  TextField,
} from '@material-ui/core';

const styles = makeStyles((theme) => ({
  labelRoot: {
    fontFamily: '\'Montserrat\', sans-serif',
  },
  root: {
    '& label': {
      color: theme.palette.common.white,
    },
    '& textarea': {
      color: theme.palette.common.white,
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: theme.palette.common.white,
    },
  },
}));

const InputTextArea = ({
  className,
  label,
  value,
  onChangeText,
  invert,
  onKeyPress,
  error,
  fullWidth,
  required,
  placeholder,
  ...other
}) => {
  const classes = styles();
  return (
    <TextField
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
      className={className}
      onKeyPress={onKeyPress}
      label={label}
      onChange={(e) => onChangeText(e.target.value)}
      value={value}
      classes={{
        root: !invert && classes.root,
      }}
      InputLabelProps={{
        classes: {
          root: classes.labelRoot,
        },
      }}
      error={error}
      rows={5}
      fullWidth={fullWidth}
      required={required}
      placeholder={placeholder}
      multiline
    />
  );
};

InputTextArea.defaultProps = {
  className: null,
  label: null,
  invert: false,
  onKeyPress: null,
  error: null,
  fullWidth: false,
  required: false,
  placeholder: null,
};

InputTextArea.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  onChangeText: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  error: PropTypes.string,
  invert: PropTypes.bool,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default InputTextArea;
