import {
  fork, all, takeLatest, take, select,
} from 'redux-saga/effects';
import { firestore } from 'firebase';

import firebase from '../../firebase';
import {
  actions, authorsItemPosts,
  authorsItemPut, authorsItemPutStories,
} from '../actions/Authors';
import Constants from '../../../constants';
import postTransformer from '../../transformers/Post';

const getCurrentAuthor = (state) => ({
  author: state.authorsReducer.item.data,
});

const collection = firestore()
  .collection(Constants.firebase.collections.stories);

const itemTransformer = (author) => {
  const {
    firstName,
    middleName,
    lastName,
    bio,
    profileImage,
    bannerImage,
  } = author.data();

  return {
    uuid: author.id,
    name: `${firstName} ${middleName} ${lastName}`,
    firstName,
    middleName,
    lastName,
    bio,
    profileImage,
    bannerImage,
    stories: null,
    posts: [],
  };
};

const storiesTransformer = (stories) => {
  const data = [];
  stories.forEach((story) => {
    const {
      story_title: title,
      synopsis,
      genre: genres,
      cover,
    } = story.data();

    data.push({
      uuid: story.id,
      title,
      synopsis,
      genres,
      cover,
    });
  });

  return data;
};

const postsTransformer = (posts) => {
  const data = [];
  posts.forEach((discussion) => data.push(postTransformer(discussion)));
  return data;
};

function* getAuthorSaga({ payload: uuid }) {
  yield fork(
    firebase.firestore.syncDocument,
    `${Constants.firebase.collections.authors}/${uuid}`,
    {
      successActionCreator: authorsItemPut,
      transform: itemTransformer,
    },
  );
}

/*
 * Getting published stories of author.
 */

function* getAuthorContentSaga() {
  while (true) {
    yield take(actions.ITEM.PUT);
    const { author } = yield select(getCurrentAuthor);
    if (author) {
      const { uuid } = author;
      yield fork(
        firebase.firestore.syncCollection,
        collection.where(
          'author.document',
          '==',
          firestore().doc(`profile/${uuid}`),
        ).where('reviewing', '==', false),
        {
          successActionCreator: authorsItemPutStories,
          transform: storiesTransformer,
        },
      );

      yield fork(
        firebase.firestore.syncCollection,
        firestore()
          .collection(Constants.firebase.collections.discussion)
          .where(
            'user.id',
            '==',
            firestore().doc(`${Constants.firebase.collections.profile}/${uuid}`),
          ),
        {
          successActionCreator: authorsItemPosts,
          transform: postsTransformer,
        },
      );
    }
  }
}

export default function* Authors() {
  yield all([
    takeLatest(actions.ITEM.GET, getAuthorSaga),
    fork(getAuthorContentSaga),
  ]);
}
