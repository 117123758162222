import colors from './colors';
import routes from './routes';
import login from './login';
import firebase from './firebase';
import files from './files';
import materials from './materials';

const Constants = {
  headerHeight: 80,
  colors,
  routes,
  login,
  firebase,
  files,
  materials,
  maxWidth: 1600,
  minWidth: 992,
};

export default Constants;
