import { actions } from '../actions/Authors';

const initialState = {
  item: {
    loading: false,
    data: null,
  },
};

const authorsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case actions.ITEM.GET:
      return {
        ...state,
        item: {
          loading: true,
          data: null,
        },
      };
    case actions.ITEM.PUT:
      return {
        ...state,
        item: {
          loading: false,
          data: action.payload,
        },
      };
    case actions.ITEM.PUT_STORIES:
      return {
        ...state,
        item: {
          ...state.item,
          data: {
            ...state.item.data,
            stories: action.payload,
          },
        },
      };
    case actions.ITEM.POSTS:
      return {
        ...state,
        item: {
          ...state.item,
          data: {
            ...state.item.data,
            posts: action.payload,
          },
        },
      };
    case actions.ITEM.RESET:
      return {
        ...state,
        item: {
          loading: false,
          data: null,
        },
      };
    case actions.RESET:
      return {
        ...state,
        loading: false,
        data: null,
      };
    default:
      return state;
  }
};

export default authorsReducer;
