import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { storage } from 'firebase';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ReaderChapterSelector from '../components/Reader/ReaderChapterSelector';
import useRouter from '../services/useRouter';
import {
  readerChapterGoto,
} from '../providers/redux/actions/Reader';
import Reader from '../components/Reader';
import Loading from '../components/Loading';
import Header from '../components/Header';
import {
  siteHeaderReset,
  siteHeaderSet,
} from '../providers/redux/actions/Site';
import StoryTitle from '../components/Story/StoryTitle';
import Button from '../components/Button';
import Constants from '../constants';
import AppText from '../components/AppText';
import SelectLanguage from '../components/Select/SelectLanguage';
import CommentsLightLayout from '../components/Comments/CommentsLightLayout';
import ReaderActionBar from '../components/Reader/ReaderActionBar';
import ResponsiveWrapper from '../components/ResponsiveWrapper';

const firebaseStorage = storage();

const styles = makeStyles((theme) => ({
  container: {
    marginLeft: 'auto',
    marginRight: 'auto',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  wrapper: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    marginRight: 46,
  },
  title: {
    flex: 1,
    marginLeft: theme.spacing(2),
    color: theme.palette.common.white,
    textAlign: 'center',
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  loaderContainer: {
    display: 'flex',
    padding: theme.spacing(4),
    alignItems: 'center',
    justifyContent: 'center',
  },
  reader: {
    width: '100%',
    margin: 'auto',
  },
  navHeader: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 644,
  },
  titleButton: {
    flex: 3,
  },
  navHeaderTitle: {
    fontSize: '1.6rem',
  },
  selectors: {
    marginTop: theme.spacing(),
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  story: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flexDirection: 'row',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  storyTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: theme.palette.common.white,
  },
  authors: {
    flex: 4,
    color: theme.palette.common.white,
    marginLeft: theme.spacing(2),
  },
  chapterSelector: {
    borderRadius: 4,
    padding: theme.spacing(),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    background: '#212121',
    flex: 3,
    marginRight: theme.spacing(2),
    color: theme.palette.common.white,
  },
  languageSelector: {
    padding: theme.spacing(),
    flex: 1,
    color: theme.palette.common.white,
  },
  iconClass: {
    color: theme.palette.common.white,
  },
  commentsContainer: {
    background: theme.palette.common.white,
  },
  commentsHeader: {
    fontWeight: 'bold',
    color: '#151515',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(5),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  commentInput: {
    borderBottom: '1px solid #E8E8E8',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  comments: {
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
}));

const ReaderScreen = () => {
  const { t } = useTranslation();
  const { match, history } = useRouter();
  const classes = styles();
  const { params: { storyId, chapterId } } = match;
  const dispatch = useDispatch();
  const [pdf, setPdf] = useState(null);
  const [language, setLanguage] = useState('English');

  const {
    previousChapter, chapter, nextChapter, story,
  } = useSelector((state) => ({
    previousChapter: state.readerReducer.previousChapter,
    chapter: state.readerReducer.chapter,
    nextChapter: state.readerReducer.nextChapter,
    story: state.readerReducer.story,
  }));

  const goToStory = () => history.push(`${Constants.routes.Story}/${story.id}`);

  useEffect(() => {
    dispatch(
      siteHeaderSet(() => (
        <div className={classes.navHeader}>
          { story && (
            <div className={classes.story}>
              <Button
                className={classes.titleButton}
                classes={{
                  label: classes.storyTitle,
                }}
                onPress={goToStory}
              >
                <StoryTitle
                  className={classes.navHeaderTitle}
                  title={story.title}
                />
              </Button>
              <AppText className={classes.authors}>
                {`By ${story.authorName}`}
              </AppText>
            </div>
          )}
          <div className={classes.selectors}>
            <ReaderChapterSelector
              className={classes.chapterSelector}
            />
            <SelectLanguage
              variant="standard"
              className={classes.languageSelector}
              iconClassName={classes.iconClass}
              onChange={setLanguage}
              value={language}
            />
          </div>
        </div>
      )),
    );

    return () => {
      dispatch(siteHeaderReset());
    };
  }, [story]);

  useEffect(() => {
    setPdf(null);
    if (chapter) {
      const getFile = async () => {
        const {
          source,
        } = chapter.data();
        const file = await firebaseStorage.ref(source).getDownloadURL();
        setPdf(file);
      };

      getFile();
    }
  }, [chapter]);

  useEffect(() => {
    dispatch(readerChapterGoto({ storyId, uuid: chapterId }));
  }, [dispatch, storyId, chapterId]);

  return (
    <div className={classes.container}>
      <Header />
      <div className={classes.content}>
        {!pdf && (
          <div className={classes.loaderContainer}>
            <Loading size={48} />
          </div>
        )}
        {pdf && (
          <ResponsiveWrapper>
            <Reader
              key={pdf}
              nextChapter={nextChapter}
              source={pdf}
              previousChapter={previousChapter}
            />
          </ResponsiveWrapper>
        )}
      </div>
      <ReaderActionBar author={story ? story.authorDocument : null} authorName={story ? story.authorName : ''} />
      <div className={classes.commentsContainer}>
          {chapter && (
              <CommentsLightLayout uuid={chapter.id} article={chapter.data()} />
          )}
      </div>
    </div>
  );
};

export default ReaderScreen;
