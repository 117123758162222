import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FormControlLabel, Radio, RadioGroup, makeStyles } from '@material-ui/core';

const styles = makeStyles(() => ({
  label: {
    fontFamily: '\'Montserrat\', sans-serif',
  }
}));

const InputEstimatedLength = ({ setValue, value }) => {
  const { t } = useTranslation();
  const classes = styles();
  const options = [
    t('publish.fields.chapter_opt1'),
    t('publish.fields.chapter_opt2'),
    t('publish.fields.chapter_opt3'),
    t('publish.fields.chapter_opt4'),
  ];
  return (
    <RadioGroup
      name="estimatedLength"
      onChange={(e) => setValue(e.target.value)}
      value={value}
    >
      {options.map((option, key) => (
        <FormControlLabel
          key={option}
          control={<Radio color="primary" />}
          label={option}
          value={key.toString()}
          classes={classes}
        />
      ))}
    </RadioGroup>
  );
};

InputEstimatedLength.defaultProps = {
  value: null,
};

InputEstimatedLength.propTypes = {
  setValue: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default InputEstimatedLength;
