import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { storage } from 'firebase';
import AppText from '../../AppText';
import useRouter from '../../../services/useRouter';
import Constants from '../../../constants';

const firebaseStorage = storage();

const styles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    marginRight: theme.spacing(1.5),
    cursor: 'pointer',
    border: '1px solid #383838',
    boxSizing: 'border-box',
  },
  image: {
    flex: 1,
  },
  storyTitle: {
    color: theme.palette.common.white,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('sm')]: {
        fontSize: '0.8rem',
        lineHeight: '1.28rem',
    },
  },
  chapterTitle: {
    color: theme.palette.secondary.main,
    [theme.breakpoints.down('sm')]: {
        fontSize: '0.8rem',
        lineHeight: '1.28rem',
    },
  },
  titleBox: {
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
  },
}));

const BookCarouselItem = ({ width, chapter }) => {
  const { t } = useTranslation();
  const { history } = useRouter();
  const classes = styles();
  const [story, setStory] = useState(null);
  const [img, setImage] = useState(null);
  const onPress = () => history.push(`${Constants.routes.Story}/${chapter.story.id}`);

  useEffect(() => {
    if (chapter && chapter.story) {
      const getStory = async () => {
        try {
          const chapterStory = await chapter.story.get();
          if (chapterStory.exists) {
            setStory(chapterStory.data());
          }
        } catch (error) {
          console.log(error);
        }
      };

      const getImage = async () => {
        const folder = await firebaseStorage.ref(
          `story_cover/${chapter.story.id}`,
        ).listAll();

        const coverImage = folder.items[0];
        if (coverImage) {
          const url = await coverImage.getDownloadURL();
          if (url) {
            setImage(url);
          }
        }
      };

      getImage();
      getStory();
    }
  }, [chapter]);

  return (
    <div
      role="button"
      onKeyDown={(event) => {
        if (event.keyCode === 13) {
          onPress();
        }
      }}
      tabIndex={-1}
      onClick={onPress}
      className={classes.container}
      style={{ width }}
    >
      <div
        className={classes.image}
        style={{
          width,
          backgroundImage: `url('${img}')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
        }}
      />
      <div className={classes.titleBox}>
        <AppText className={classes.storyTitle} variant="body1">
          {story ? story.story_title : ''}
        </AppText>
        <AppText className={classes.chapterTitle} variant="body1">
          {t('story.chapter', { number: chapter.chapter_number })}
        </AppText>
      </div>
    </div>
  );
};

BookCarouselItem.propTypes = {
  width: PropTypes.number.isRequired,
  chapter: PropTypes.object.isRequired,
};

export default BookCarouselItem;
