import { actions } from '../actions/SubGenres';

const initialState = {
  loading: true,
  data: null,
};

const subGenresReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case actions.GET:
      return {
        ...state,
        loading: true,
      };
    case actions.PUT:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case actions.RESET:
      return {
        ...state,
        loading: false,
        data: null,
      };
    default:
      return state;
  }
};

export default subGenresReducer;
