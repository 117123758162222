import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import StandardLayout from '../components/Layout/StandardLayout';
import StoryTitle from '../components/Story/StoryTitle';
import Loading from '../components/Loading';
import ButtonBack from '../components/Button/ButtonBack';
import StoryStretchGoal from '../components/Story/StoryStretchGoal';
import StorySummary from '../components/Story/StorySummary';
import { submissionsItemGet } from '../providers/redux/actions/Submissions';
import DraftImages from '../components/DraftImages';

const styles = makeStyles((theme) => ({
  container: {
    width: '100%',
    maxWidth: 1490,
    margin: 'auto',
    boxSizing: 'border-box',
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
  header: {
    marginTop: theme.spacing(5.5),
    paddingBottom: theme.spacing(14.5),
  },
  content: {
    backgroundColor: '#EFEFEF',
  },
  meta: {
    display: 'flex',
    marginTop: theme.spacing(5),
    flexDirection: 'row',
  },
  title: {
    marginTop: theme.spacing(1.5),
  },
  summary: {
    position: 'relative',
    top: -theme.spacing(12.5),
    marginBottom: theme.spacing(4),
  },
  chapters: {
    top: -theme.spacing(12.5),
  },
  images: {
    flex: 3,
    paddingRight: theme.spacing(9),
  },
  goal: {
    flex: 2,
  },
}));

const DraftScreen = ({
  match,
  story,
  loading,
  submissionsItemGet: submissionsItemGetAction,
}) => {
  const { params: { uuid } } = match;
  const classes = styles();

  useEffect(() => {
    submissionsItemGetAction({ uuid });
  }, [submissionsItemGetAction, uuid]);

  if (!story || loading) {
    return (
      <StandardLayout>
        <Loading size="large" />
      </StandardLayout>
    );
  }

  const {
    title, authorName, genres, synopsis, goal, pledged,
  } = story;
  return (
    <StandardLayout>
      <div className={classes.header}>
        <div className={classes.container}>
          <ButtonBack />
          <StoryTitle className={classes.title} title={title} />
          <div className={classes.meta}>
            <DraftImages
              className={classes.images}
              uuid={uuid}
              width={294}
              height={420}
            />
            <StoryStretchGoal
              className={classes.goal}
              days={7}
              amount={pledged}
              goal={goal}
              uuid={uuid}
            />
          </div>
        </div>
      </div>
      <div className={classes.content}>
        <div className={classes.container}>
          <StorySummary
            className={classes.summary}
            synopsis={synopsis}
            authorName={authorName}
            genres={genres}
          />
        </div>
      </div>
    </StandardLayout>
  );
};

DraftScreen.defaultProps = {
  story: null,
};

DraftScreen.propTypes = {
  story: PropTypes.object,
  match: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  submissionsItemGet: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.submissionsReducer.item.loading,
  story: state.submissionsReducer.item.data,
});

export default connect(mapStateToProps, { submissionsItemGet })(DraftScreen);
