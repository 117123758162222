import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ReaderNextChapterButton from './ReaderNextChapterButton';
import ReaderPreviousChapterButton from './ReaderPreviousChapterButton';
import ReaderPDF from './ReaderPDF';
import AppText from '../AppText';

const styles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    flexDirection: 'row',
  },
  action: {
    display: 'flex',
    flex: 1,
  },
  end: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    color: theme.palette.common.white,
    textAlign: 'center',
  },
}));

const Reader = ({
  className, source, nextChapter, previousChapter, useWindow,
}) => {
  const { t } = useTranslation();
  const [end, setEnd] = useState(false);
  const classes = styles();
  return (
    <div className={className}>
      { source && (
        <ReaderPDF
          source={source}
          setEnd={() => setEnd(true)}
          useWindow={useWindow}
        />
      )}
      {end && (
        <div className={classes.footer}>
          <div className={classes.end}>
            <AppText>{t("You've reached the end of this chapter.")}</AppText>
          </div>
          <div className={classes.actions}>
            <div className={classes.action}>
              { previousChapter ? <ReaderPreviousChapterButton /> : <div /> }
            </div>
            <div className={classes.action}>
              { nextChapter ? <ReaderNextChapterButton /> : <div /> }
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

Reader.defaultProps = {
  className: null,
  source: null,
  nextChapter: null,
  previousChapter: null,
  useWindow: true,
};

Reader.propTypes = {
  className: PropTypes.string,
  source: PropTypes.string,
  nextChapter: PropTypes.object,
  previousChapter: PropTypes.object,
  useWindow: PropTypes.bool,
};

export default Reader;
