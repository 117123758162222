export const actions = {
  GET: 'DISCUSSIONS_GET',
  PUT: 'DISCUSSIONS_PUT',
  RESET: 'DISCUSSIONS_RESET',
  ITEM: {
    PUT: 'DISCUSSIONS_ITEM_PUT',
    GET: 'DISCUSSIONS_ITEM_GET',
    RESET: 'DISCUSSIONS_ITEM_RESET',
  },
  CREATE: {
    REQUEST: 'DISCUSSIONS_CREATE_REQUEST',
    SUCCESS: 'DISCUSSIONS_CREATE_SUCCESS',
    ERRORS: 'DISCUSSIONS_CREATE_ERRORS',
    RESET: 'DISCUSSIONS_CREATE_RESET',
  },
};

export const getDiscussions = () => ({
  type: actions.GET,
});

export const putDiscussions = (discussions) => ({
  type: actions.PUT,
  payload: discussions,
});


export const resetDiscussions = () => ({
  type: actions.RESET,
});


export const discussionsItemGet = ({ uuid }) => ({
  type: actions.ITEM.GET,
  payload: uuid,
});

export const discussionsItemPut = (discussion) => ({
  type: actions.ITEM.PUT,
  payload: discussion,
});

export const discussionsItemReset = () => ({
  type: actions.ITEM.RESET,
});

export const discussionsCreateRequest = ({
  title,
  description,
  categories,
  materials,
}) => ({
  type: actions.CREATE.REQUEST,
  payload: {
    title,
    description,
    categories,
    materials,
  },
});

export const discussionsCreateSuccess = ({ id, message }) => ({
  type: actions.CREATE.SUCCESS,
  payload: {
    id,
    message,
  },
});

export const discussionsCreateErrors = ({ message, errors }) => ({
  type: actions.CREATE.ERRORS,
  payload: {
    message,
    errors,
  },
});


export const discussionsCreateReset = () => ({
  type: actions.CREATE.RESET,
});
