import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeStyles,
  FormControl,
  FormLabel,
  CardContent, FormHelperText,
} from '@material-ui/core';
import { KeyboardArrowRightRounded } from '@material-ui/icons';
import StandardLayout from '../components/Layout/StandardLayout';
import Input from '../components/Input/Input';
import Button from '../components/Button';
import Snackbar from '../components/Snackbar';
import Loading from '../components/Loading';
import useProtectRoute from '../providers/hooks/useProtectRoute';
import InputTextArea from '../components/Input/InputTextArea';
import GenresList from '../components/GenresList';
import AppText from '../components/AppText';
import Stepper from '../components/Stepper/Stepper';
import UploadChapters from '../components/UploadChapters';
import LayoutLight from '../components/Layout/LayoutLight';
import ResponsiveWrapper from '../components/ResponsiveWrapper';
import UploadImages from '../components/UploadImages';
import { storiesCreateRequest } from '../providers/redux/actions/Stories';
import InputEstimatedLength from '../components/Input/InputEstimatedLength';
import InputColor from '../components/Input/InputColor';
import SubGenresList from '../components/SubGenresList';
import Select from '../components/Select/Select';
import SelectLanguage from '../components/Select/SelectLanguage';

const styles = makeStyles((theme) => ({
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  header: {
    flex: 1,
    fontSize: 28,
    fontWeight: 500,
    color: theme.palette.common.white,
    marginTop: theme.spacing(),
  },
  subtitle: {
    marginTop: theme.spacing(2),
    fontSize: 18,
    color: '#8E8E8E',
  },
  stepperContainer: {
    marginTop: theme.spacing(9),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  footer: {
    borderTop: '1px solid #DEDEDE',
    marginTop: theme.spacing(5),
    paddingTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  stepper: {
    flex: 1,
    background: 'transparent',
  },
  page: {
    padding: theme.spacing(3),
  },
  input: {
    marginBottom: theme.spacing(4),
    '&::placeholder': {
      fontFamily: '\'Montserrat\', sans-serif',
    },
  },
  formControl: {
    display: 'flex',
  },
  back: {
    marginRight: theme.spacing(),
  },
  tnc: {
    flex: 1,
    paddingRight: theme.spacing(2),
    fontSize: 13,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  field: {
    flex: 1,
  },
  label: {
    fontFamily: '\'Montserrat\', sans-serif',
  },
  languageSelectInput: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

const labelStyles = makeStyles((theme) => ({
  root: {
    fontFamily: '\'Montserrat\', sans-serif',
    marginBottom: theme.spacing(2),
  },
}));

const buttonStyles = makeStyles({
  root: {
    backgroundColor: '#397C15',
    '&:hover': {
      backgroundColor: '#57bb21',
    },
  },
});

const SubmissionScreen = () => {
  useProtectRoute();

  const classes = styles();
  const buttonClasses = buttonStyles();

  const {
    message,
    processing,
    success,
    errors,
  } = useSelector((state) => ({
    processing: state.storiesReducer.create.processing,
    success: state.storiesReducer.create.success,
    message: state.storiesReducer.create.message,
    errors: state.storiesReducer.create.errors,
  }));

  const dispatch = useDispatch();

  const [currentStep, setCurrentStep] = useState(0);
  const [title, setTitle] = useState('');
  const [synopsis, setSynopsis] = useState('');
  const [coverImages, setCoverImages] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [estimatedLength, setEstimatedLength] = useState('0');
  const [color, setColor] = useState('0');
  const [genres, setGenres] = useState([]);
  const [subGenres, setSubGenres] = useState([]);
  const [contentRatings, setContentRatings] = useState('');
  const [language, setLanguage] = useState('');
  const [readingStyle, setReadingStyle] = useState('');
  // const [days, setDays] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (success) {
      setCurrentStep(2);
    }
  }, [success]);

  const {
    title: titleErrors,
    synopsis: synopsisErrors,
    estimated_chapters: estimatedLengthErrors,
    color: colorErrors,
  } = errors;

  const steps = [
    t('draft.steps.create'),
    t('draft.steps.upload'),
    t('draft.steps.submit'),
  ];

  const isChecked = (genre) => genres.includes(genre);

  const onCheck = (genre) => {
    if (isChecked(genre)) {
      setGenres(genres.filter((includedGenre) => includedGenre !== genre));
      return;
    }

    const newGenres = [...genres];
    newGenres.push(genre);
    setGenres(newGenres);
  };

  const onCheckSubGenres = (subGenre) => {
    if (isChecked(subGenre)) {
      setSubGenres(
        subGenres.filter((includedGenre) => includedGenre !== subGenre),
      );
      return;
    }

    const newSubGenres = [...subGenres];
    newSubGenres.push(subGenre);
    setSubGenres(newSubGenres);
  };

  const submit = () => dispatch(
    storiesCreateRequest({
      title,
      synopsis,
      genres,
      coverImages,
      chapters,
      estimatedLength,
      color,
    }),
  );

  const isValidStep = (step) => {
    if (step === 0) {
      if (!title
        || !synopsis
        || genres.length === 0
        || !estimatedLength
        || !color) {
        return false;
      }
    }

    return true;
  };

  const hasErrors = (step) => {
    if (step === 0) {
      return titleErrors
        || synopsisErrors
        || colorErrors
        || estimatedLengthErrors;
    }

    return false;
  };

  const isSubmitPage = currentStep === steps.length - 2;

  const isLastPage = currentStep === steps.length - 1;

  const onBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const onNext = () => {
    if (!isSubmitPage) {
      setCurrentStep(currentStep + 1);
      return;
    }

    submit();
  };

  return (
    <StandardLayout>
      <Snackbar
        variant={success ? 'success' : 'error'}
        message={message}
      />
      <LayoutLight
        header={(
          <div className={classes.headerContainer}>
            <AppText className={classes.header} header>
              {t('draft.title')}
            </AppText>
            <AppText className={classes.subtitle} subtitle>
              {t('draft.subtitle')}
            </AppText>
          </div>
        )}
      >
        <ResponsiveWrapper>
          <Stepper
            hasErrors={hasErrors}
            steps={steps}
            submit={submit}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          >
            <div className={classes.page}>
              <Input
                className={classes.input}
                label={t('Title')}
                placeholder={t('What is the name of your work?')}
                onChangeText={setTitle}
                value={title}
                error={titleErrors}
                required
                fullWidth
                invert
              />
              <FormControl className={classes.input}>
                <FormLabel className={classes.label}>{t('Cover Image (optional)')}</FormLabel>
                <UploadImages
                  setFiles={setCoverImages}
                  files={coverImages}
                  multiple
                />
              </FormControl>
              <InputTextArea
                variant="filled"
                className={classes.input}
                label={t('Plot Summary')}
                placeholder={t('What the title is about? Who are the protagonists and what they are trying to achieve in your story')}
                onChangeText={setSynopsis}
                value={synopsis}
                error={synopsisErrors}
                required
                filled
                fullWidth
                invert
              />
              <div className={classNames([classes.row, classes.input])}>
                <Select
                  className={classes.field}
                  label="Content Ratings"
                  onChange={setContentRatings}
                  options={['R-rated']}
                  placeholder="Select one"
                  value={contentRatings}
                />
                <SelectLanguage
                    label="Language"
                    className={
                        classNames([
                            classes.field,
                            classes.languageSelectInput,
                        ])
                    }
                    placeholder="Select one"
                    onChange={setLanguage}
                    value={language}
                />
                <Select
                  className={classes.field}
                  label="Reading Style"
                  onChange={setReadingStyle}
                  options={['Vertical scroll']}
                  placeholder="Select one"
                  value={readingStyle}
                />
              </div>
              <FormControl
                className={classNames([classes.formControl, classes.input])}
              >
                <FormLabel classes={labelStyles()} required>
                  {t('Genres')}
                </FormLabel>
                <GenresList values={genres} onCheck={onCheck} />
              </FormControl>
              <FormControl
                className={classNames([classes.formControl, classes.input])}
              >
                <FormLabel classes={labelStyles()}>
                  {t('Sub Genres')}
                </FormLabel>
                <SubGenresList values={subGenres} onCheck={onCheckSubGenres} />
              </FormControl>
              <div className={classNames([classes.row, classes.input])}>
                <FormControl
                  className={classes.field}
                  error={estimatedLengthErrors}
                >
                  <FormLabel classes={labelStyles()} required>{t('Estimated Length')}</FormLabel>
                  <InputEstimatedLength
                    setValue={setEstimatedLength}
                    value={estimatedLength}
                  />
                  <FormHelperText error={estimatedLengthErrors}>
                    {estimatedLengthErrors}
                  </FormHelperText>
                </FormControl>
                <FormControl className={classes.field} error={colorErrors}>
                  <FormLabel classes={labelStyles()} required>{t('Color')}</FormLabel>
                  <InputColor setValue={setColor} value={color} />
                  <FormHelperText error={colorErrors}>
                    {colorErrors}
                  </FormHelperText>
                </FormControl>
              </div>
            </div>
            <div className={classes.page}>
              <UploadChapters setFiles={setChapters} files={chapters} />
              {/* <Scheduler */}
              {/*  setTime={() => {}} */}
              {/*  setStartingChapter={() => {}} */}
              {/*  setDays={setDays} */}
              {/*  setDate={() => {}} */}
              {/*  setFrequency={() => {}} */}
              {/*  days={days} */}
              {/* /> */}
            </div>
            <div className={classes.page}>
              {t("You've successfully submitted your story!")}
            </div>
          </Stepper>
          { !isLastPage && (
            <CardContent className={classes.footer}>
              { isSubmitPage && (
                <AppText className={classes.tnc}>
                  <div>{t('draft.tnc.line1')}</div>
                  <div>{t('draft.tnc.line2')}</div>
                </AppText>
              )}
              {(!processing && currentStep > 0) && (
                <Button
                  className={classes.back}
                  onPress={onBack}
                  variant="contained"
                  color="secondary"
                >
                  {t('Back')}
                </Button>
              )}
              {processing
                ? <Loading size={48} />
                : (
                  <Button
                    onPress={onNext}
                    variant="contained"
                    disabled={!isValidStep(currentStep)}
                    classes={buttonClasses}
                  >
                    <AppText>{t(isSubmitPage ? 'Submit' : 'Next')}</AppText>
                    <KeyboardArrowRightRounded />
                  </Button>
                )}
            </CardContent>
          )}
        </ResponsiveWrapper>
      </LayoutLight>
    </StandardLayout>
  );
};

export default SubmissionScreen;
