import React from 'react';
import { makeStyles } from '@material-ui/core';
import Loading from './Loading';

const styles = makeStyles(() => ({
  container: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const AppLoading = () => {
  const classes = styles();
  return (
    <div className={classes.container}>
      <Loading size={50} />
    </div>
  );
};

export default AppLoading;
