import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import * as _ from 'lodash';
import { makeStyles } from '@material-ui/core';
import Checkbox from './Checkbox';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from "react-responsive";

const styles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  item: {
    width: '25%',
    [theme.breakpoints.down('sm')]: {
      width: '33%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '50%',
    },
  },
}));

const SubGenresList = ({ onCheck, values }) => {
  const { subGenres } = useSelector((state) => ({
    subGenres: state.subGenresReducer.data,
  }));
  
  const theme = useTheme();
  const classes = styles();

  let grid = 2;
  if (useMediaQuery({ minWidth: theme.breakpoints.width('sm') })) {
    grid = 3;
  }
  if (useMediaQuery({ minWidth: theme.breakpoints.width('md') })) {
    grid = 4;
  }

  const chunkedGenres = _.chunk(subGenres, grid);

  const isChecked = (genre) => values.includes(genre);

  return (
    <div className={classes.container}>
      {chunkedGenres && chunkedGenres.map((chunk, key) => (
        <div key={key.toString()} className={classes.row}>
          {
            chunk.map(
              ({ name }) => (
                <Checkbox
                  key={name}
                  className={classes.item}
                  label={name}
                  onChange={onCheck}
                  checked={isChecked(name)}
                  value={name}
                />
              ),
            )
          }
        </div>
      ))}
    </div>
  );
};

SubGenresList.propTypes = {
  onCheck: PropTypes.func.isRequired,
  values: PropTypes.array.isRequired,
};

export default SubGenresList;
