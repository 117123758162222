import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import useRouter from '../services/useRouter';
import { discussionsItemGet } from '../providers/redux/actions/Discussions';
import DiscussionCarousel from '../components/Discussion/DiscussionCarousel';
import LayoutLight from '../components/Layout/LayoutLight';
import StandardLayout from '../components/Layout/StandardLayout';
import Loading from '../components/Loading';
import ButtonBack from '../components/Button/ButtonBack';
import DiscussionAuthor from '../components/Discussion/DiscussionAuthor';
import BadgeCategory from '../components/Badge/BadgeCategory';
import AppText from '../components/AppText';
import ShareDialog from '../components/Share/ShareDialog';
import DiscussionStatsShare
  from '../components/Discussion/DiscussionStats/DiscussionStatsShare';
import CommentsLightLayout from '../components/Comments/CommentsLightLayout';

const useStyles = makeStyles((theme) => ({
  card: {
    position: 'relative',
    top: -theme.spacing(10),
    marginBottom: theme.spacing(4),
  },
  title: {
    marginTop: theme.spacing(3),
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
  },
  header: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(),
    },
  },
  row: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  rowItem: {
    flex: 1,
  },
  content: {
    color: theme.palette.common.white,
  },
  genres: {
    marginTop: theme.spacing(2),
    flex: 1,
    display: 'flex',
  },
  stats: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(4),
    },
  },
  commentInput: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    borderBottom: '1px solid #E8E8E8',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  commentsHeader: {
    fontWeight: 'bold',
    color: '#151515',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(5),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  comments: {
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  details: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  carousel: {
    [theme.breakpoints.up('sm')]: {
      flex: 3,
    },
  },
  info: {
    [theme.breakpoints.up('sm')]: {
      flex: 2,
      marginLeft: theme.spacing(6),
    },
  },
  shareButton: {
    border: '1px solid #8E8E8E',
    color: '#8E8E8E',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(5),
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  authors: {
    marginTop: theme.spacing(),
  },
}));

const DiscussionScreen = () => {
  const classes = useStyles();
  const { match } = useRouter();
  const { params: { uuid } } = match;
  const dispatch = useDispatch();
  const { loading, discussion } = useSelector((state) => ({
    loading: state.discussionsReducer.item.loading,
    discussion: state.discussionsReducer.item.data,
  }));
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const share = () => setOpen(true);

  useEffect(() => {
    dispatch(
      discussionsItemGet({ uuid }),
    );
  }, [uuid, dispatch]);

  if (loading || !discussion) {
    return (
      <StandardLayout>
        <Loading />
      </StandardLayout>
    );
  }

  const {
    title, description, author, createdAt, categories,
  } = discussion;

  return (
    <StandardLayout>
      <ShareDialog open={open} handleClose={handleClose} />
      <LayoutLight
        header={(
          <div>
            <div className={classes.header}>
              <ButtonBack white />
              <div className={classes.row}>
                <div>
                  <AppText className={classes.title} variant="h3" header>
                    {title}
                  </AppText>
                  <div className={classNames([classes.row, classes.authors])}>
                    <DiscussionAuthor
                      className={classes.rowItem}
                      createdAt={createdAt}
                      name={author.name}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.details}>
              <DiscussionCarousel className={classes.carousel} uuid={uuid} />
              <div className={classes.info}>
                <AppText className={classes.content}>
                  {description}
                </AppText>
                <div className={classes.genres}>
                  {categories.map((category) => (
                    <BadgeCategory text={category} />
                  ))}
                </div>
                <DiscussionStatsShare
                  className={classes.stats}
                  upVotes={287}
                  downVotes={13}
                  share={share}
                />
              </div>
            </div>
          </div>
        )}
        disableCard
      >
        <CommentsLightLayout uuid={discussion.uuid} article={discussion} />
      </LayoutLight>
    </StandardLayout>
  );
};

export default DiscussionScreen;
