import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Grid, Dialog, IconButton } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import AppText from '../AppText';
import { useTranslation } from 'react-i18next';
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon,
    LineShareButton,
    LineIcon,
} from "react-share";

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  title: {
    fontWeight: 700
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(0.5),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <AppText className={classes.title} variant="h6">{children}</AppText>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    borderTop: 'none',
    borderBottom: 'none'
  },
}))(MuiDialogContent);

const ShareDialog = withStyles(styles)(props => {
  const { open, classes, handleClose } = props;
  const { t } = useTranslation();
  const currentTitle = 'Komakaku (Beta)';
  //const currentUrl = 'https://komakaku.com';
  const currentUrl = window.location.href;

  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        {t('author.share')}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={3}>
              <FacebookShareButton url={currentUrl} title={currentTitle}>
                <FacebookIcon size={64} />
              </FacebookShareButton>
          </Grid>
          <Grid item xs={3}>
              <TwitterShareButton url={currentUrl} title={currentTitle}>
                  <TwitterIcon size={64} />
              </TwitterShareButton>
          </Grid>
          <Grid item xs={3}>
              <WhatsappShareButton url={currentUrl} title={currentTitle}>
                  <WhatsappIcon size={64} />
              </WhatsappShareButton>
          </Grid>
          <Grid item xs={3}>
              <LineShareButton url={currentUrl} title={currentTitle}>
                  <LineIcon size={64} />
              </LineShareButton>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
});

ShareDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ShareDialog;