import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import AppText from '../AppText';

const styles = makeStyles((theme) => ({
  text: {
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
  },
}));

const StoryTitle = ({ className, title }) => {
  const classes = styles();
  return (
    <AppText
      className={classNames([classes.text, className])}
      variant="h2"
      header
    >
      { title }
    </AppText>
  );
};

StoryTitle.defaultProps = {
  className: null,
};

StoryTitle.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  title: PropTypes.string.isRequired,
};

export default StoryTitle;
