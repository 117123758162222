export const actions = {
  GET: 'STORIES_GET',
  PUT: 'STORIES_PUT',
  RESET: 'STORIES_RESET',
  ITEM: {
    PUT: 'STORIES_ITEM_PUT',
    GET: 'STORIES_ITEM_GET',
    RESET: 'STORIES_ITEM_RESET',
  },
  CREATE: {
    REQUEST: 'STORIES_CREATE_REQUEST',
    SUCCESS: 'STORIES_CREATE_SUCCESS',
    ERRORS: 'STORIES_CREATE_ERRORS',
  },
  LAST_READ: 'STORIES_LAST_READ',
};

export const getStories = () => ({
  type: actions.GET,
});

export const putStories = (stories) => ({
  type: actions.PUT,
  payload: stories,
});


export const resetStories = () => ({
  type: actions.RESET,
});


export const storiesItemGet = ({ uuid }) => ({
  type: actions.ITEM.GET,
  payload: uuid,
});

export const storiesItemPut = (story) => ({
  type: actions.ITEM.PUT,
  payload: story,
});


export const storiesItemReset = () => ({
  type: actions.ITEM.RESET,
});

export const storiesCreateRequest = ({
  title,
  synopsis,
  genres,
  coverImages,
  chapters,
  estimatedLength,
  color,
  contentRatings,
  language,
  readingStyle,
}) => ({
  type: actions.CREATE.REQUEST,
  payload: {
    title,
    synopsis,
    genres,
    coverImages,
    chapters,
    estimatedLength,
    color,
    contentRatings,
    language,
    readingStyle,
  },
});

export const storiesCreateSuccess = () => ({
  type: actions.CREATE.SUCCESS,
});

export const storiesCreateErrors = ({ message, errors }) => ({
  type: actions.CREATE.ERRORS,
  payload: {
    message,
    errors,
  },
});

export const storiesLastRead = (chapter) => ({
  type: actions.LAST_READ,
  payload: chapter,
});
