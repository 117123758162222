import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import RatingStar from './RatingStar';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const Rating = ({ className, ratings, size }) => {
  const classes = useStyles();
  const filled = Math.floor(ratings);
  const max = 5;
  const half = ratings % 1 === 0.5;
  const empty = max - filled - (half ? 1 : 0);
  return (
    <div className={classNames([classes.container, className])}>
      {filled !== 0 && Array.from(Array(filled)).map((value, key) => (
        <RatingStar key={key.toString()} size={size} filled />
      ))}
      {half && <RatingStar size={size} half />}
      {empty !== 0 && Array.from(Array(empty)).map((value, key) => (
        <RatingStar key={key.toString()} />
      ))}
    </div>
  );
};

Rating.defaultProps = {
  className: null,
  size: 14,
};

Rating.propTypes = {
  className: PropTypes.string,
  ratings: PropTypes.number.isRequired,
  size: PropTypes.number,
};

export default Rating;
