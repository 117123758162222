import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Votes from '../../Votes/Votes';
import ButtonShareWithIcon from '../../Button/ButtonShareWithIcon';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'inline-flex',
    flexDirection: 'row',
    borderRadius: 8,
    background: '#212121',
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(3),
  },
  downVote: {
    marginLeft: theme.spacing(2),
  },
  share: {
    marginLeft: theme.spacing(2),
    flex: 3,
  },
  icon: {
    marginRight: theme.spacing(),
    color: '#8E8E8E',
  },
}));

const DiscussionStatsShare = ({
  className, upVotes, downVotes, share,
}) => {
  const classes = useStyles();
  return (
    <div className={classNames([classes.container, className])}>
      <Votes
        voteUp={() => {}}
        voteDown={() => {}}
        count={upVotes - downVotes}
        white
      />
      <ButtonShareWithIcon className={classes.share} onPress={share} />
    </div>
  );
};

DiscussionStatsShare.defaultProps = {
  className: null,
};

DiscussionStatsShare.propTypes = {
  className: PropTypes.string,
  upVotes: PropTypes.number.isRequired,
  downVotes: PropTypes.number.isRequired,
  share: PropTypes.func.isRequired,
};

export default DiscussionStatsShare;
