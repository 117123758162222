import {
  call, put, takeEvery, takeLatest, all, take, fork, select,
} from 'redux-saga/effects';
import fb, { firestore, storage } from 'firebase';

import firebase from '../../firebase';
import Constants from '../../../constants';

import {
  actions,
  profileSynced,
  loginSuccess,
  loginFailure,
  logoutSuccess,
  logoutFailure,
  userCreateSuccess,
  userCreateError,
  userUpdateSuccess,
  userUpdateError,
  updatePWSuccess,
  updatePWError,
  resetPWSuccess,
  resetPWError,
  storiesSynced, userPostsLoaded,
} from '../actions/User';

const getUser = (state) => ({
  user: state.userReducer.user,
});

const setCall = async (collection, object) => collection.set(object);

const storiesCollection = firestore()
  .collection(Constants.firebase.collections.stories);

const storiesTransformer = (stories) => {
  const data = [];
  stories.forEach((story) => {
    const {
      story_title: title,
      synopsis,
      genre: genres,
      cover,
    } = story.data();

    data.push({
      uuid: story.id,
      title,
      synopsis,
      genres,
      cover,
    });
  });

  return data;
};

const profileTransformer = (profile) => {
  const user = profile.data();

  if (!user) {
    return {
      firstName: '',
      middleName: '',
      lastName: '',
      dob: '',
      country: '',
      bio: '',
      bannerImage: '',
      profileImage: '',
    };
  }

  return {
    firstName: (typeof user.firstName !== 'undefined') ? user.firstName : '',
    middleName: (typeof user.middleName !== 'undefined') ? user.middleName : '',
    lastName: (typeof user.lastName !== 'undefined') ? user.lastName : '',
    dob: (typeof user.dob !== 'undefined') ? user.dob : '',
    country: (typeof user.country !== 'undefined') ? user.country : '',
    bio: (typeof user.bio !== 'undefined') ? user.bio : '',
    bannerImage: (typeof user.bannerImage !== 'undefined') ? user.bannerImage : '',
    profileImage: (typeof user.profileImage !== 'undefined') ? user.profileImage : '',
  };
};

const postTransformer = (discussions) => {
  const data = [];
  discussions.forEach(async (discussion) => {
    const {
      title,
      content,
      user,
      created_at: createdAt,
      updated_at: lastUpdated,
    } = discussion.data();

    data.push({
      uuid: discussion.id,
      title,
      content,
      user,
      createdAt,
      lastUpdated,
    });
  });

  return [...data];
};

/*
 * Getting all uploaded stories of user.
 */
function* getUserPosts({ payload: user }) {
  yield fork(
    firebase.firestore.syncCollection,
    firestore().collection(Constants.firebase.collections.discussion).where(
      'user.id',
      '==',
      firestore().doc(`profile/${user.uid}`),
    ),
    {
      successActionCreator: userPostsLoaded,
      transform: postTransformer,
    },
  );
}

/*
 * Getting all uploaded stories of user.
 */

function* syncUserStoriesSaga({ payload: user }) {
  yield fork(
    firebase.firestore.syncCollection,
    storiesCollection.where(
      'author.document',
      '==',
      firestore().doc(`profile/${user.uid}`),
    ),
    {
      successActionCreator: storiesSynced,
      transform: storiesTransformer,
    },
  );
}

function* syncProfileSaga({ payload: user }) {
  yield fork(
    firebase.firestore.syncDocument,
    `${Constants.firebase.collections.profile}/${user.uid}`,
    {
      successActionCreator: profileSynced,
      transform: profileTransformer,
    },
  );
}

/**
 * syncUserSaga
 */
function* syncUserSaga() {
  const channel = yield call(firebase.auth.channel);

  while (true) {
    const { user } = yield take(channel);
    if (user) {
      yield put(loginSuccess({ user }));
      yield fork(syncProfileSaga, { payload: user });
      yield fork(syncUserStoriesSaga, { payload: user });
      yield fork(getUserPosts, { payload: user });
    } else {
      yield put(logoutSuccess());
    }
  }
}

/**
 * loginSaga
 * @param username
 * @param password
 */
function* loginSaga({ username, password }) {
  try {
    yield call(firebase.auth.signInWithEmailAndPassword, username, password);
  } catch (error) {
    // TODO: Analytics track login failure.
    yield put(loginFailure(error.message));
  }
}

/**
 * registerSaga
 * @param email
 * @param newPassword
 * @param confirmPassword
 */
function* registerSaga({ email, newPassword, confirmPassword }) {
  try {
    if (newPassword !== confirmPassword) {
      yield put(userCreateError({ createError: 'Password should match Confirm Password.' }));
    }

    if (!newPassword.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/)) {
      yield put(userCreateError({ createError: 'Password must be at least one uppercase letter, one lowercase letter, one number, and at least 8 characters long.' }));
    }

    if (newPassword === confirmPassword && newPassword.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/)) {
      yield call(firebase.auth.createUserWithEmailAndPassword, email, newPassword);
      yield put(userCreateSuccess({ message: 'Account created successfully.' }));
    }
  } catch (error) {
    // TODO: Analytics track register failure.
    yield put(userCreateError({ createError: error.message }));
  }
}

/**
 * resetPasswordSaga
 * @param email
 */

function* resetPasswordSaga({ forgotEmail, domain }) {
  try {
    const actionSettings = {
      url: (domain.indexOf('localhost') > -1
        ? `http://${domain}`
        : `https://${domain}`) + Constants.routes.Login,
    };

    yield call(firebase.auth.sendPasswordResetEmail, forgotEmail, actionSettings);
    yield put(resetPWSuccess());
  } catch (error) {
    yield put(resetPWError({ forgotError: error.message }));
  }
}

/**
 * updatePasswordSaga
 * @param currentPassword
 * @param newPassword
 * @param confirmPassword
 */

function* updatePasswordSaga({ currentPassword, newPassword, confirmPassword }) {
  try {
    if (newPassword !== confirmPassword) {
      yield put(updatePWError({ error: 'New Password should match Confirm Password.' }));
    }

    if (!newPassword.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/)) {
      yield put(updatePWError({ error: 'New Password must be at least one uppercase letter, one lowercase letter, one number, and at least 8 characters long.' }));
    }

    if (newPassword === confirmPassword && newPassword.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/)) {
      const user = fb.auth().currentUser;
      if (user != null) {
        const { email } = user;
        yield call(firebase.auth.signInWithEmailAndPassword, email, currentPassword);
        yield call(firebase.auth.updatePassword, newPassword);
        yield put(updatePWSuccess());
      }
    }
  } catch (error) {
    yield put(updatePWError({ error: error.message }));
  }
}

/**
 * logoutSaga
 */
function* logoutSaga() {
  try {
    yield call(firebase.auth.signOut);
    yield put(logoutSuccess());
  } catch (error) {
    yield put(logoutFailure(error));
  }
}

const getCall = async (ref) => ref.get();

const saveCall = async (ref, file) => ref.put(file);

function* saveImageSaga(dest, file) {
  const ref = storage().ref(dest);

  yield call(saveCall, ref, file);
}

function* updateUserSaga({
  payload: {
    displayName, firstName, middleName, lastName, dob, country, bio, bannerImage, profileImage,
  },
}) {
  try {
    const user = fb.auth().currentUser;
    if (user != null) {
      // TODO: Update photo URL after uploading to Firebase storage.
      yield call(firebase.auth.updateProfile, { displayName });

      const updateUser = {
        firstName,
        middleName,
        lastName,
        dob,
        country,
        bio,
      };
      
      // Upload Banner Image
      if (bannerImage && bannerImage.length > 0) {
        const bannerDest = `/user_banner_image/${user.uid}/${bannerImage[0].name}`;
        yield call(
          saveImageSaga,
          bannerDest,
          bannerImage[0],
        );
        updateUser.bannerImage = bannerDest;
      }
      
      // Upload Banner Image
      if (profileImage && profileImage.length > 0) {
        const profileDest = `/user_profile_image/${user.uid}/${profileImage[0].name}`;
        yield call(
          saveImageSaga,
          profileDest,
          profileImage[0],
        );
        updateUser.profileImage = profileDest;
      }
      
      yield call(
        firebase.firestore.setDocument,
        `${Constants.firebase.collections.profile}/${user.uid}`,
        updateUser,
      );
      yield put(userUpdateSuccess({ message: 'Profile updated successfully.' }));
    }
  } catch (error) {
    yield put(userUpdateError({ errors: error }));
  }
}

function* createPublicProfileSaga() {
  const { user } = yield select(getUser);
  const {
    uid, displayName, firstName, lastName, bio,
  } = user;
  const profile = yield call(
    getCall,
    firestore().doc(`${Constants.firebase.collections.profile}/${uid}`),
  );

  if (!profile.exists) {
    yield call(
      setCall,
      firestore()
        .collection(`${Constants.firebase.collections.profile}`)
        .doc(uid),
      {
        name: displayName,
        first_name: firstName,
        last_name: lastName,
        bio,
      },
    );
  }
}

export default function* User() {
  yield all([
    takeLatest(actions.LOGIN.REQUEST, loginSaga),
    takeLatest(actions.LOGOUT.REQUEST, logoutSaga),
    takeLatest(actions.LOGOUT.REQUEST, logoutSaga),
    takeEvery(actions.SYNC_USER, syncUserSaga),
    takeLatest(actions.SYNC_PROFILE, syncProfileSaga),
    takeLatest(actions.UPDATE.REQUEST, updateUserSaga),
    takeLatest(actions.CREATE.REQUEST, registerSaga),
    takeLatest(actions.PROFILE.REQUEST, createPublicProfileSaga),
    takeLatest(actions.UPDATE_PW.REQUEST, updatePasswordSaga),
    takeLatest(actions.RESET.REQUEST, resetPasswordSaga),
    takeLatest(actions.SYNC_STORIES, syncUserStoriesSaga),
  ]);
}
