import React from 'react';
import AppText from './AppText';

const styles = {
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const Splash = () => (
  <div style={styles.container}>
    <AppText>Komakaku</AppText>
  </div>
);

export default Splash;
