import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { IconButton, makeStyles } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';

const styles = makeStyles(() => ({
  button: {
    borderRadius: '50%',
    backgroundColor: 'transparent',
  },
  icon: {
    width: 20,
    height: 20,
  },
}));

const ButtonRemove = ({ className, onPress }) => {
  const classes = styles();
  return (
    <IconButton
      href={null}
      className={classNames([classes.button, className])}
      onClick={onPress}
    >
      <CloseRounded className={classes.icon} />
    </IconButton>
  );
};

ButtonRemove.defaultProps = {
  className: null,
};

ButtonRemove.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onPress: PropTypes.func.isRequired,
};

export default ButtonRemove;
