import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles, MenuItem } from '@material-ui/core';
import { useSelector } from 'react-redux';
import Constants from '../../constants';
import useRouter from '../../services/useRouter';
import Select from '../Select/Select';
import Loading from '../Loading';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
  },
  icon: {
    color: theme.palette.common.white,
  },
}));

const ReaderChapterSelector = ({ className, itemClassName }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { history } = useRouter();
  const [chapters, setChapters] = useState([]);
  const {
    story,
    chapterCollection,
    chapterReference,
  } = useSelector((state) => ({
    story: state.readerReducer.story,
    chapterReference: state.readerReducer.chapterReference || null,
    chapterCollection: state.readerReducer.story
    && state.readerReducer.story.chapters
      ? state.readerReducer.story.chapters
      : null,
  }));

  const syncChapters = async () => {
    const chaptersSnapshot = await chapterCollection.orderBy('number').get();
    setChapters(chaptersSnapshot.docs.map((chapterSnapshot) => {
      const { title, number, document } = chapterSnapshot.data();
      return {
        uuid: chapterSnapshot.id, id: document.id, title, number,
      };
    }));
  };

  useEffect(() => {
    if (chapterCollection !== null) {
      syncChapters();
    }
  }, [chapterCollection]);


  const onChange = (uuid) => history.push(
    `${Constants.routes.Reader}/${story.uuid}/${uuid}`,
  );

  if (!chapterCollection || !chapterReference) {
    return <Loading />;
  }

  return (
    <Select
      className={className}
      onChange={onChange}
      options={chapters}
      value={chapterReference.id}
      renderItem={({ uuid, title, number }) => (
        <MenuItem key={uuid} className={itemClassName} value={uuid}>
          {`${t('story.chapter', { number })}: ${title}`}
        </MenuItem>
      )}
      classes={{
        root: classes.root,
        icon: classes.icon,
      }}
      disableUnderline
    />
  );
};

ReaderChapterSelector.defaultProps = {
  className: null,
  itemClassName: null,
};

ReaderChapterSelector.propTypes = {
  className: PropTypes.string,
  itemClassName: PropTypes.string,
};

export default ReaderChapterSelector;
