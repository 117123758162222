export const actions = {
  WITHDRAWAL: {
    REQUEST: 'CREATE_WITHDRAWAL_REQUEST',
    SUCCESS: 'CREATE_WITHDRAWAL_REQUEST_SUCCESS',
    ERROR: 'CREATE_WITHDRAWAL_REQUEST_FAILURE'
  },
  WITHDRAWAL_HISTORY: {
    REQUEST: 'CREATE_WITHDRAWAL_HISTORY_REQUEST',
    SUCCESS: 'CREATE_WITHDRAWAL_HISTORY_REQUEST_SUCCESS',
    ERROR: 'CREATE_WITHDRAWAL_HISTORY_REQUEST_FAILURE'
  }
};

export const createWithdrawalRequest = payload => ({
  type: actions.WITHDRAWAL.REQUEST,
  payload: {
    ...payload
  }
});

// export const createWithdrawalRequestSuccess = ({ bank_name, bank_number }) => ({
//   type: actions.WITHDRAWAL.SUCCESS,
//   payload: {
//     bank_name,
//     bank_number
//   }
// });

// export const createWithdrawalRequestFailure = error => ({
//   type: actions.WITHDRAWAL.ERROR,
//   payload: error
// });

export const createWithdrawalHistoryRequest = uid => ({
  type: actions.WITHDRAWAL_HISTORY.REQUEST,
  payload: uid
});

export const createWithdrawalHistoryRequestSuccess = data => ({
  type: actions.WITHDRAWAL_HISTORY.SUCCESS,
  payload: data
});

export const createWithdrawalHistoryRequestFailure = error => ({
  type: actions.WITHDRAWAL_HISTORY.ERROR,
  payload: error
});
