import React from 'react';
import { useSelector } from 'react-redux';
import DiscussionBoard
  from '../components/Discussion/DiscussionBoard/DiscussionBoard';
import Loading from '../components/Loading';
import StandardLayout from '../components/Layout/StandardLayout';

const DiscussionBoardScreen = () => {
  const { posts, loading } = useSelector((state) => ({
    loading: state.discussionsReducer.loading,
    posts: state.discussionsReducer.data,
  }));
  return (
    <StandardLayout>
      { loading ? <Loading /> : <DiscussionBoard posts={posts} /> }
    </StandardLayout>
  );
};

export default DiscussionBoardScreen;
