const firebase = {
  sync: {
    pause: 'PAUSE_SYNC',
    resume: 'RESUME_SYNC',
  },
  collections: {
    authors: 'profile',
    stories: 'story',
    genres: 'genre',
    subGenres: 'sub-genres',
    submission: 'submission',
    chapters: 'chapter',
    pages: 'page',
    bookmarks: 'bookmarks',
    profile: 'profile',
    discussion: 'discussion',
    transactions: 'transactions',
  },
  functions: {
    publishDraft: 'draft/publish',
    publishStory: 'story/publish',
  },
};

export default firebase;
