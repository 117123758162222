import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AppText from '../AppText';
import Input from '../Input/Input';
import UploadFilesRowTemplate from './UploadFilesRowTemplate';
import ButtonRemove from '../Button/ButtonRemove';
// import ButtonPreview from '../Button/ButtonPreview';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(2),
    background: '#FDFEFF',
    fontSize: 18,
  },
  alternate: {
    background: '#F4F8FA',
  },
  item: {
    flex: 1,
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  input: {
    '& .MuiOutlinedInput-root': {
      padding: '14px 14px',
    },
    '& .MuiInputBase-input': {
      padding: '3px 0 7px',
    },
    '& .MuiInputBase-root': {
      width: '100%',
    },
  },
  select: {
    paddingTop: 16,
    paddingBottom: 21,
  },
}));

const UploadFilesRow = ({
  file, alternate, onChange, remove, errors,
}) => {
  const {
    name, captions,
  } = file;
  const classes = useStyles();

  const onChangeCaptions = (newCaptions) => onChange({
    ...file,
    captions: newCaptions,
  });

  return (
    <UploadFilesRowTemplate
      className={
          classNames([
            classes.row,
            alternate && classes.alternate,
          ])
        }
      fileName={<AppText>{name}</AppText>}
      captions={(
        <div className={classes.inputContainer}>
          <Input
            className={classes.input}
            variant="outlined"
            onChangeText={onChangeCaptions}
            value={captions}
            error={errors.captions}
            fullWidth
            invert
          />
        </div>
        )}
      remove={<ButtonRemove onPress={remove} />}
    />
  );
};

UploadFilesRow.defaultProps = {
  alternate: false,
  errors: {},
};

UploadFilesRow.propTypes = {
  file: PropTypes.object.isRequired,
  alternate: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  errors: PropTypes.object,
};

export default UploadFilesRow;
