import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';

const style = {
  container: {
    padding: 8,
  },
};

const Loading = ({ size }) => (
  <div style={style.container}>
    <CircularProgress color="primary" size={size} />
  </div>
);

Loading.defaultProps = {
  size: 20,
};

Loading.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Loading;
