import React, { useState, useEffect } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Slide from '@material-ui/core/Slide';
import StandardLayout from '../components/Layout/StandardLayout';
import ResponsiveWrapper from '../components/ResponsiveWrapper';
import CenterCover from '../components/CenterCover';
import ButtonShareWithIcon from '../components/Button/ButtonShareWithIcon';
import ButtonSupport from '../components/Button/ButtonSupport';
import AppText from '../components/AppText';
import AkademyClasses from '../components/Akademy/AkademyClasses';
import LayoutLight from '../components/Layout/LayoutLight';
import CommentsLightLayout from '../components/Comments/CommentsLightLayout';
import ButtonClose from '../components/Button/ButtonClose';
import ButtonRateWithIcon from '../components/Button/ButtonRateWithIcon';
import Loading from '../components/Loading';
import ButtonDonate from '../components/Button/ButtonDonate';
import ShareDialog from '../components/Share/ShareDialog';
import { getPaymentMethods } from '../providers/redux/actions/Payment';
import constants from "../constants";

const MasterClass1 = constants.routes.FileStore + '/web_assets%2Fkoyama-masterclass.jpg?alt=media&token=15b86d71-84e2-4190-ab44-2d9deb140549';
const Class1 = constants.routes.FileStore + '/web_assets%2Fkoyama-class1.jpg?alt=media&token=2d6a7369-8f52-4e0e-9e39-4d97f0302503';
const Class2 = constants.routes.FileStore + '/web_assets%2Fkoyama-class2.jpg?alt=media&token=3ed13eac-e875-49b2-a3a0-743f886534ba';
const Class3 = constants.routes.FileStore + '/web_assets%2Fkoyama-class3.jpg?alt=media&token=6ed9b5a0-a7df-40a3-999f-f15d382b9e10';
const InterviewCover = constants.routes.FileStore + '/web_assets%2Fkoyama-video-cover.jpg?alt=media&token=132506f2-7251-428e-a552-badb09b575bb';

const useStyles = makeStyles((theme) => ({
  card: {
      background: 'top center / cover no-repeat',
      position: 'relative',
      display: 'flex',
      borderRadius: 0,
      width: '70%',
      maxHeight: '520px',
      marginLeft: 'auto',
      marginRight: 'auto',
      [theme.breakpoints.down('sm')]: {
          width: '100%',
          marginLeft: 0,
          marginRight: 0,
      },
  },
  gradient: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'linear-gradient(180deg, rgba(255,255,255,0) 30%, #151515  90%)',
      zIndex: 9,
  },
  header: {
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      height: 520,
    },
    [theme.breakpoints.down('sm')]: {
      height: 150,
      textAlign: 'center',
      paddingTop: theme.spacing(30),
      paddingBottom: theme.spacing(15),
    },
  },
  headerWrapper: {
    position: 'absolute',
    display: 'flex',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  details: {
    position: 'relative',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(3),
    },
    zIndex: 12,
  },
  title: {
    color: theme.palette.common.white,
  },
  subtitle: {
    fontFamily: "'Montserrat', sans-serif",
    color: '#8E8E8E',
    fontWeight: 'normal',
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(2),
  },
  support: {
    marginRight: theme.spacing(),
  },
  share: {
    background: '#212121',
  },
  close: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(4),
    color: '#8E8E8E',
  },
  info: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  infoDetails: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  infoActions: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  infoMaster: {
    textTransform: 'uppercase',
    color: theme.palette.common.white,
    fontWeight: 'bold',
    marginRight: theme.spacing(3),
  },
  infoTitle: {
    textTransform: 'uppercase',
    color: '#8E8E8E',
  },
  infoActionButton: {
    marginRight: theme.spacing(),
  },
  video: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const AkademyCreatorScreen = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const image = MasterClass1;

  const [open, setOpen] = useState(false);

  const handleClose = () => {
      setOpen(false);
  };

  const share = () => setOpen(true);

  const [selected, setSelected] = useState(null);

  const onClose = () => setSelected(null);

  const creator = {
    uuid: 'UOI3Eg5uTCfMz3pA2c0kZYru3lC2',
    title: 'Koyama Chuya'
  };

  const {
    paymentLoading, paymentMethodResponse,
  } = useSelector(
    (state) => ({
      paymentLoading: state.paymentsReducer.payment_loading,
      paymentMethodResponse: state.paymentsReducer.payment_methods,
    }),
  );

  useEffect(() => {
    dispatch(getPaymentMethods());
  }, []);

  if (paymentLoading) {
    return (
      <StandardLayout>
        <Loading />
      </StandardLayout>
    );
  }

  if (selected === null) {
    return (
      <StandardLayout>
        <ShareDialog open={open} handleClose={handleClose} />
        <div className={classes.header}>
          <ResponsiveWrapper className={classes.headerWrapper}>
            <div
                className={classes.card}
                style={{ backgroundImage: `url(${image})` }}
            >
                <div className={classes.details}>
                    <AppText className={classes.title} variant="h2">
                        Koyama Chuya
                    </AppText>
                    <AppText className={classes.subtitle} variant="h5">
                        Teaches the art of drawing
                    </AppText>
                    <div>
                        <ButtonDonate
                            authorId={'UOI3Eg5uTCfMz3pA2c0kZYru3lC2'}
                            authorName={'Koyama Chuya'}
                            paymentMethodResponse={paymentMethodResponse}
                        />
                        <ButtonShareWithIcon
                            className={classes.share}
                            onPress={share}
                        />
                    </div>
                </div>
                <div className={classes.gradient} />
            </div>
          </ResponsiveWrapper>
        </div>
        <ResponsiveWrapper>
          <AkademyClasses
            onPress={() => setSelected(null)}
            list={[
              {
                image: Class1,
                title: 'Class 1 (coming soon)',
                rating: 0,
                views: 0,
              },
              {
                image: Class2,
                title: 'Class 2 (coming soon)',
                rating: 0,
                views: 0,
              },
              {
                image: Class3,
                title: 'Class 3 (coming soon)',
                rating: 0,
                views: 0,
              },
            ]}
          />
        </ResponsiveWrapper>
      </StandardLayout>
    );
  }

  return (
    <StandardLayout>
      <ButtonClose
        className={classes.close}
        onPress={onClose}
        fontSize="large"
      />
      <Slide
        direction="up"
        in={selected !== null}
        mountOnEnter
        unmountOnExit
      >
        <LayoutLight
          header={(
            <ResponsiveWrapper>
              <div className={classes.video}>
                <img
                  src={InterviewCover}
                  alt="Koyama Chuya"
                />
              </div>
              <div className={classes.info}>
                <div className={classes.infoDetails}>
                  <AppText className={classes.infoMaster} variant="h6">
                      Koyama Chuya
                  </AppText>
                  <AppText className={classes.infoTitle} variant="h6">
                      Character Building
                  </AppText>
                </div>
                <div className={classes.infoActions}>
                  <ButtonDonate
                    authorId={'UOI3Eg5uTCfMz3pA2c0kZYru3lC2'}
                    authorName={'Koyama Chuya'}
                    paymentMethodResponse={paymentMethodResponse}
                  />
                  <ButtonShareWithIcon
                    className={classes.infoActionButton}
                    onPress={() => {}}
                  />
                  <ButtonRateWithIcon
                    onPress={() => {}}
                  />
                </div>
              </div>
            </ResponsiveWrapper>
          )}
          disableCard
        >
          <ResponsiveWrapper>
              <CommentsLightLayout uuid={creator.uuid} article={creator} />
          </ResponsiveWrapper>
        </LayoutLight>
      </Slide>
    </StandardLayout>
  );
};

export default AkademyCreatorScreen;
