import {
  fork, all, takeLatest,
} from 'redux-saga/effects';
import { firestore } from 'firebase';

import firebase from '../../firebase';
import Constants from '../../../constants';
import { actions, transactionsListSuccess } from '../actions/Transactions';
import transactionTransformer from '../../transformers/Transaction';

const collectionTransformer = (collection) => {
  const data = [];
  collection.forEach(
    (transaction) => data.push(
      transactionTransformer(transaction),
    ),
  );

  return [...data];
};

function* getTransactionsSaga() {
  yield fork(
    firebase.firestore.syncCollection,
    firestore().collection(Constants.firebase.collections.transactions)
      .orderBy(
        'timestamp',
        'desc',
      ),
    {
      successActionCreator: transactionsListSuccess,
      transform: collectionTransformer,
    },
  );
}

export default function* Transactions() {
  yield all([
    takeLatest(actions.LIST.REQUEST, getTransactionsSaga),
  ]);
}
