export const actions = {
  GET: 'CHAPTERS_GET',
  PUT: 'CHAPTERS_PUT'
};

export const getChapters = () => ({
  type: actions.GET,
});

export const putChapters = (chapters) => ({
  type: actions.PUT,
  payload: chapters,
});
