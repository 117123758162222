import React from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import { makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from 'react-responsive';
import Checkbox from './Checkbox';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  item: {
    width: '25%',
    [theme.breakpoints.down('sm')]: {
      width: '33%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '50%',
    },
  },
}));

const CategoriesList = ({ onCheck, values }) => {
  // const { categories } = useSelector((state) => ({
  //   categories: state.categoriesReducer.data,
  // }));
  const categories = [
    { name: 'Artwork' },
    { name: 'Cosplay' },
    { name: 'Help Needed' },
    { name: 'New Story Idea' },
    { name: 'Discussion' },
    { name: 'Misc' },
  ];

  const theme = useTheme();
  const classes = useStyles();

  let grid = 2;
  if (useMediaQuery({ minWidth: theme.breakpoints.width('sm') })) {
    grid = 3;
  }
  if (useMediaQuery({ minWidth: theme.breakpoints.width('md') })) {
    grid = 4;
  }

  const chunkedCategories = _.chunk(categories, grid);

  const isChecked = (category) => values.includes(category);

  return (
    <div className={classes.container}>
      {chunkedCategories && chunkedCategories.map((chunk, key) => (
        <div key={key.toString()} className={classes.row}>
          {
            chunk.map(
              ({ name }) => (
                <Checkbox
                  key={name}
                  className={classes.item}
                  label={name}
                  onChange={onCheck}
                  checked={isChecked(name)}
                  value={name}
                />
              ),
            )
          }
        </div>
      ))}
    </div>
  );
};

CategoriesList.propTypes = {
  onCheck: PropTypes.func.isRequired,
  values: PropTypes.array.isRequired,
};

export default CategoriesList;
