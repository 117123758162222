import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Step,
  StepConnector,
  StepIcon,
  StepLabel,
  Stepper as MaterialStepper,
} from '@material-ui/core';

const styles = makeStyles((theme) => ({
  stepperContainer: {
    maxWidth: 1000,
    marginTop: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  stepper: {
    flex: 1,
    background: 'transparent',
  },
  page: {
    padding: theme.spacing(3),
  },
  input: {
    marginBottom: theme.spacing(4),
  },
  formControl: {
    display: 'flex',
  },
}));

const stepLabelStyles = makeStyles(() => ({
  label: {
    fontFamily: '\'Source Sans Pro\', sans-serif',
    fontWeight: 600,
    color: '#8E8E8E',
    fontSize: '0.95em',
    paddingLeft: 10,
    paddingRight: 10
  },
  active: {
    color: '#151515 !important',
    fontWeight: '600 !important',
  },
}));

const stepLineStyles = makeStyles({
  line: {
    height: 1,
    border: 0,
    backgroundColor: '#CCCCCC',
  },
});

const stepIconStyles = makeStyles((theme) => ({
  root: {
    '& circle': {
      color: '#E5E5E5',
    },
    '& text': {
      color: '#8E8E8E',
      fontFamily: '\'Source Sans Pro\', sans-serif',
      fill: '#151515',
    },
  },
  active: {
    '& circle': {
      color: '#D4A367',
    },
    '& text': {
      fontFamily: '\'Source Sans Pro\', sans-serif',
      fontWeight: 600,
      fill: theme.palette.common.white,
    },
  },
  completed: {
    color: '#397C15 !important',
  },
}));

const Stepper = ({
  steps, hasErrors, children, currentStep,
}) => {
  const classes = styles();
  return (
    <>
      <div className={classes.stepperContainer}>
        <MaterialStepper
          className={classes.stepper}
          activeStep={currentStep}
          connector={<StepConnector classes={stepLineStyles()} />}
        >
          {steps.map((label, key) => (
            <Step key={label}>
              <StepLabel
                className={classes.stepLabel}
                error={hasErrors(key)}
                icon={(
                  <StepIcon
                    icon={key + 1}
                    active={currentStep === key}
                    completed={currentStep > key}
                    error={hasErrors(key)}
                    classes={stepIconStyles()}
                  />
                )}
                classes={stepLabelStyles()}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </MaterialStepper>
      </div>
      {React.Children.map(children, (child, key) => key === currentStep && (
        <div className={classes.page}>
          {child}
        </div>
      ))}
    </>
  );
};

Stepper.propTypes = {
  children: PropTypes.node.isRequired,
  steps: PropTypes.array.isRequired,
  hasErrors: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
};

export default Stepper;
