import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import AppText from './AppText';

const styles = makeStyles((theme) => ({
  value: {
    color: '#518435',
  },
  descriptor: {
    color: '#8E8E8E',
    marginBottom: theme.spacing(4),
  },
}));

const StatisticsCard = ({ className, value, description }) => {
  const classes = styles();
  return (
    <div className={className}>
      <AppText className={classes.value} subtitle variant="h3">{value}</AppText>
      <AppText className={classes.descriptor} subtitle variant="body1">
        {description}
      </AppText>
    </div>
  );
};

StatisticsCard.defaultProps = {
  className: null,
};

StatisticsCard.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  description: PropTypes.string.isRequired,
};

export default StatisticsCard;
