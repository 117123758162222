import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Grid, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShare } from '@fortawesome/free-solid-svg-icons';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import UserAvatar from '../User/UserAvatar';
import AppText from '../AppText';
import ButtonDonate from '../Button/ButtonDonate';
import Button from '../Button/Button';
import ShareDialog from '../Share/ShareDialog';
import classNames from "classnames";

const styles = makeStyles((theme) => ({
  container: {
    marginTop: -theme.spacing(3),
    marginBottom: theme.spacing(3),
    flexDirection: 'row',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
    borderBottom: '2px solid #3B3B3B',
    paddingBottom: theme.spacing(3),
  },
  avatar: {
    width: 200,
    height: 200,
    fontSize: '1.4rem',
    marginTop: -24,
    marginBottom: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: 160,
      height: 160,
    }
  },
  avatarContainer: {
    flex: 1,
    [theme.breakpoints.up('sm')]: {
      maxWidth: 200,
      marginRight: '25px',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '-115px',
      textAlign: '-webkit-center',
      marginBottom: theme.spacing(2)
    }
  },
  authorName: {
    flex: 1,
    [theme.breakpoints.down('xs')]: {
      textAlign: '-webkit-center'
    }
  },
  text: {
    marginTop: theme.spacing(2),
    display: 'inline-block',
    color: theme.palette.common.white,
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.5em',
    }
  },
  bio: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: 'justify',
    color: theme.palette.secondary.main,
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.05rem',
      height: 90,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    }
  },
  donation: {
    marginTop: theme.spacing(3),
  },
  button: {
    width: 120,
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: '#212121',
    '& .MuiButton-root': {
      color: theme.palette.common.white,
    },
    '& .MuiButton-label': {
      fontFamily: '\'Montserrat\', sans-serif',
    },
    borderRadius: 0,
    [theme.breakpoints.up('sm')]: {
      '& .MuiButton-label': {
        fontSize: '1rem',
      },
    }
  },
  buttonIcon: {
    paddingRight: theme.spacing(1),
    fontSize: '0.8em',
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.9em',
    }
  },
  iconFollow: {
    fontSize: '1em',
  },
  buttonFollowed: {
    width: 120,
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: '#427fed',
    '& .MuiButton-root': {
      color: theme.palette.common.white,
    },
    '& .MuiButton-label': {
      fontFamily: '\'Montserrat\', sans-serif',
    },
    borderRadius: 0,
    [theme.breakpoints.up('sm')]: {
      '& .MuiButton-label': {
        fontSize: '1rem',
      },
    }
  },
  btnDonate: {
    display: 'block',
    margin: 'auto',
  },
  creatorBox: {
    borderRadius: 4,
    border: '1px solid #d1ad77',
    padding: '1px 10px 3px 10px',
    color: '#d1ad77',
    fontSize: '0.7em',
    fontWeight: 600,
    marginLeft: '20px',
  },
  authorBox: {
    display: 'inline-block',
    position: 'relative',
    top: '-5px',
  },
  shareFollowArea: {
    display: 'block',
  },
  followBox: {
    float: 'right',
    display: 'inline-block',
  },
  follow: {
    color: '#B9B9B9',
    display: 'inline-block',
    marginRight: theme.spacing(1),
  },
  facebook: {
    color: '#3b5998',
    fontSize: '1em',
    marginRight: theme.spacing(1),
  },
  twitter: {
    color: '#38a1f3',
    fontSize: '1em',
    marginRight: theme.spacing(1),
  },
  instagram: {
    color: '#fff',
    borderRadius: 4,
    background: 'radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)',
    fontSize: '1em',
    marginRight: theme.spacing(1),
  },
}));

const AuthorProfile = ({
  id, name, image, bio, paymentMethodResponse
}) => {
  const classes = styles();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [following, setFollow] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleFollow = () => {
    setFollow(!following);
  };

  return (
    <div className={classes.container}>
      <div className={classes.avatarContainer}>
        <UserAvatar className={classes.avatar} name={name} image={image} />
        <ButtonDonate className={classes.btnDonate} authorName={name} paymentMethodResponse={paymentMethodResponse} authorId={id} />
        <ShareDialog open={open} handleClose={handleClose} />
      </div>
      <Grid item xs={12} sm={9} md={10} className={classes.authorName}>
        <AppText className={classes.text} variant="h2" header>{name}</AppText>
        <div className={classes.authorBox}>
          <Box className={classes.creatorBox} component="span" m={1}>
            Creator
          </Box>
        </div>
        <AppText className={classes.bio} variant="body1" subtitle>
          {bio}
        </AppText>
        <div className={classes.shareFollowArea}>
          <Button
            variant="contained"
            className={classes.button}
            onPress={handleClickOpen}
            color="secondary"
          >
            <FontAwesomeIcon icon={faShare} className={classes.buttonIcon} />
            {' '}
            {t('author.share')}
          </Button>
          <ShareDialog open={open} handleClose={handleClose} />
          <Button
            variant="contained"
            className={classes[`button${following ? 'Followed' : ''}`]}
            onPress={handleFollow}
            color="secondary"
          >
            {following ? null : (
              <AddRoundedIcon className={classNames([classes.buttonIcon, classes.iconFollow])} />
            )}
            { following ? t('author.following') : t('author.follow')}
          </Button>
        </div>
      </Grid>
    </div>
  );
};

AuthorProfile.defaultProps = {
  image: null,
  bio: null,
  paymentMethodResponse: null,
};

AuthorProfile.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  bio: PropTypes.string,
  image: PropTypes.string,
  paymentMethodResponse: PropTypes.object
};

export default AuthorProfile;
