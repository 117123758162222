export const actions = {
  LIST: {
    REQUEST: 'TRANSACTIONS_LIST_REQUEST',
    SUCCESS: 'TRANSACTIONS_LIST_SUCCESS',
    ERRORS: 'TRANSACTIONS_LIST_ERRORS',
    RESET: 'TRANSACTIONS_LIST_RESET',
  },
};

export const transactionsListRequest = () => ({
  type: actions.LIST.REQUEST,
});

export const transactionsListReset = () => ({
  type: actions.LIST.RESET,
});

export const transactionsListSuccess = (data) => ({
  type: actions.LIST.SUCCESS,
  payload: data,
});

export const transactionsListErrors = (errors) => ({
  type: actions.LIST.ERRORS,
  payload: errors,
});
