import { useMediaQuery } from 'react-responsive';
import AboutBackground from '../../assets/images/bg-about-us.png';
import AboutBackground2x from '../../assets/images/bg-about-us@2x.png';
import Constants from "../../constants";

const BackgroundAbout = () => {
  if (useMediaQuery({ query: '(min-width: '+Constants.minWidth+'px)' })) {
     return AboutBackground2x;
  }

  return AboutBackground;
};

export default BackgroundAbout;
