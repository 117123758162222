import { combineReducers } from 'redux';
import readerReducer from './Reader';
import userReducer from './User';
import storiesReducer from './Stories';
import chaptersReducer from './Chapters';
import submissionsReducer from './Submissions';
import genresReducer from './Genres';
import siteReducer from './Site';
import subGenresReducer from './SubGenres';
import discussionsReducer from './Discussions';
import authorsReducer from './Authors';
import paymentsReducer from './Payment';
import transactionsReducer from './Transactions';
import withdrawalReducer from './Withdrawal';

export default combineReducers({
  readerReducer,
  userReducer,
  storiesReducer,
  chaptersReducer,
  submissionsReducer,
  genresReducer,
  siteReducer,
  subGenresReducer,
  discussionsReducer,
  authorsReducer,
  paymentsReducer,
  transactionsReducer,
  withdrawalReducer
});
