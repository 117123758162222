import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';

const styles = makeStyles(() => ({
  text: {
    display: 'block',
    fontFamily: '\'Montserrat\', sans-serif',
  },
  headerText: {
    fontFamily: '\'Source Sans Pro\', sans-serif',
  },
  uppercaseText: {
    textTransform: 'uppercase',
  },
}));

const AppText = ({
  className, style, children, header, uppercase, variant, ...other
}) => {
  const classes = styles();
  return (
    <Typography
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
      className={classNames([
        classes.text,
        className,
        header && classes.headerText,
        uppercase && classes.uppercaseText,
      ])}
      style={style}
      variant={variant}
    >
      { children }
    </Typography>
  );
};

AppText.defaultProps = {
  style: null,
  className: null,
  header: false,
  uppercase: false,
  variant: 'body1',
};

AppText.propTypes = {
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]).isRequired,
  header: PropTypes.bool,
  uppercase: PropTypes.bool,
  variant: PropTypes.string,
};

export default AppText;
