import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  container: {
    overflow: 'hidden',
  },
  background: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    background: 'center 25% / cover no-repeat',
    [theme.breakpoints.up('sm')]: {
      bottom: 0,
    },
    [theme.breakpoints.down('sm')]: {
      bottom: 300,
    },
  },
  blur: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    [theme.breakpoints.up('sm')]: {
      background: 'radial-gradient(circle at 60% 80%, rgba(255,255,255,0) 0%, #15151537 10.6%, #151515 60%)',
      bottom: 0,
    },
    [theme.breakpoints.down('sm')]: {
      background: 'radial-gradient(circle, rgba(255,255,255,0) 0%, #15151537 10.6%, #151515 90%)',
      bottom: 300,
    },
  },
}));

const Cover = ({ className, image }) => {
  const classes = styles();
  return (
    <div
      className={classNames([classes.container, className])}
    >
      <div
        className={classes.background}
        style={{ backgroundImage: `url('${image}')` }}
      />
      <div className={classes.blur} />
    </div>
  );
};

Cover.defaultProps = {
  className: null,
  image: null,
};

Cover.propTypes = {
  className: PropTypes.string,
  image: PropTypes.string,
};

export default Cover;
