import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import StoryContentHeader from './StoryContentHeader';
import AppText from '../AppText';
import BadgeGenre from '../Badge/BadgeGenre';

const styles = makeStyles((theme) => ({
  header: {
    marginBottom: theme.spacing(),
  },
  text: {
    marginBottom: theme.spacing(2),
    textAlign: 'justify'
  },
}));

const StorySynopsis = ({ className, synopsis, genres }) => {
  const classes = styles();
  return (
    <div className={className}>
      <StoryContentHeader className={classes.header} text="story.synopsis" />
      <AppText className={classes.text} variant="body1">
        {synopsis}
      </AppText>
      <div>
        { genres.map((genre) => <BadgeGenre key={genre} text={genre} />)}
      </div>
    </div>
  );
};

StorySynopsis.defaultProps = {
  className: null,
};

StorySynopsis.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  synopsis: PropTypes.string.isRequired,
  genres: PropTypes.array.isRequired,
};

export default StorySynopsis;
