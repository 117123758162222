import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import LogoImage from '../../assets/images/komakaku-logo@2x.png';
import AppText from '../AppText';
import Constants from '../../constants';

const styles = makeStyles((theme) => ({
  container: {
    display: 'block',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    userSelect: 'none',
    webkitUserSelect: 'none',
    msUserSelect: 'none',
    textDecoration: 'none',
  },
  containerSpace: {
    padding: theme.spacing(2),
    marginLeft: theme.spacing(5.5),
    marginRight: theme.spacing(4.5),
  },
  containerSpaceM: {
    padding: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(0.5),
  },
  imageContainer: {
    height: 30,
    overflow: 'hidden',
  },
  main: {
    fontSize: 34,
    color: theme.palette.primary.main,
    letterSpacing: 6.8,
    textShadow: '0px 3px 6px #FFFFFF29',
  },
  subtitle: {
    fontSize: 11,
    color: theme.palette.common.white,
  },
}));

const HeaderLogo = () => {
  const classes = styles();
  const { t } = useTranslation();

  const isDesktop = useMediaQuery({ minWidth: Constants.minWidth });

  return (
    <NavLink
      className={classNames([
        classes.container,
        classes[`containerSpace${isDesktop ? '' : 'M'}`],
      ])}
      to={Constants.routes.Home}
      exact
    >
      <div className={classes.imageContainer}>
        <img src={LogoImage} alt="Logo" width={147} height={32} />
      </div>
      <AppText className={classes.subtitle}>
        {t('slogan')}
      </AppText>
    </NavLink>
  );
};

export default HeaderLogo;
