import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormLabel, makeStyles, Dialog } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { SettingsBackupRestore } from '@material-ui/icons';
import UploadChaptersZone from './UploadChaptersZone';
import UploadChaptersRow from './UploadChaptersRow';
import UploadChaptersHeader from './UploadChaptersHeader';
import UploadChaptersPreview from './UploadChaptersPreview';

const regex = /[0-9]{1,2}/g;

const chapterParser = (name) => {
  const found = name.match(regex);
  if (found != null) {
    return found[0] ? parseInt(found[0], 10).toString() : null;
  }

  return null;
};

const useStyles = makeStyles((theme) => ({
  upload: {
    marginTop: theme.spacing(2),
  },
  uploaded: {
    fontFamily: "'Montserrat', sans-serif",
    fontSize: '0.85em',
    color: '#151515',
    marginTop: theme.spacing(5),
  },
  header: {
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 600,
    fontSize: '0.85em',
    color: '#151515',
  },
  rows: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const UploadChapters = ({
  files, setFiles, errors, publishScreenCallback,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [preview, setPreview] = useState(null);
  const [filePageNum, setNumPages] = useState(1);

  useEffect(() => {
    publishScreenCallback(filePageNum);
    setNumPages(filePageNum);
  }, [filePageNum]);

  const onChangeFile = (newFile, index) => {
    const newFiles = [...files];
    newFiles[index] = newFile;
    setFiles(newFiles);
  };

  const removeFile = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  const callBackSetNumPages = (numPages) => setNumPages(numPages);
  
  return (
    <div>
      <Dialog
        onClose={() => setPreview(null)}
        open={preview !== null}
        scroll="body"
        maxWidth="lg"
        fullWidth
      >
        <div>{preview && <UploadChaptersPreview source={preview} />}</div>
      </Dialog>
      <FormLabel className={classes.header} required>
        {t('publish.fields.upload_chapter_content')}
      </FormLabel>
      <UploadChaptersZone
        filePageNum={filePageNum}
        className={classes.upload}
        setFiles={(newFiles) => {
          const newArray = newFiles.map((file) => ({
            name: file.name,
            url: URL.createObjectURL(file),
            number: chapterParser(file.name),
            title: '',
            language: 'English',
            file,
          }));
          setFiles(newArray);
        }}
      />
      {files.length > 0 && (
        <div className={classes.uploaded}>
          <FormLabel
            className={classes.header}
            style={{ fontSize: '1em' }}
            required
          >
            {t('publish.fields.upload_documents')}
          </FormLabel>
          <div className={classes.rows}>
            <UploadChaptersHeader />
            {files.map((file, index) => (
              <UploadChaptersRow
                key={file.name}
                index={index}
                file={file}
                onChange={(newFile) => onChangeFile(newFile, index)}
                remove={() => removeFile(index)}
                alternate={index % 2 === 1}
                errors={errors[index]}
                setPreview={setPreview}
                uploadChaptersCallbackFn={callBackSetNumPages}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

UploadChapters.defaultProps = {
  errors: {},
};

UploadChapters.propTypes = {
  publishScreenCallback: PropTypes.func.isRequired,
  setFiles: PropTypes.func.isRequired,
  files: PropTypes.array.isRequired,
  errors: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default UploadChapters;
