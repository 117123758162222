import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useRouter from '../../services/useRouter';
import Constants from '../../constants';

const useProtectRoute = () => {
  const { history } = useRouter();
  const loggedIn = useSelector((state) => state.userReducer.loggedIn);

  useEffect(() => {
    if (!loggedIn) {
      history.push(Constants.routes.Login);
    }
  }, [loggedIn, history]);
};

export default useProtectRoute;
