import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import AppText from '../AppText';

const useStyles = makeStyles((theme) => ({
  text: {
    display: 'inline-block',
    color: '#8E8E8E',
  },
  author: {
    color: theme.palette.common.white,
  },
}));

const DiscussionAuthor = ({ className, name, createdAt }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const created = moment(createdAt.seconds * 1000);
  return (
    <div className={className}>
      <AppText className={classes.text}>
        {`${t('discussions.author')} `}
        <AppText className={classNames([classes.text, classes.author])}>
          {name}
        </AppText>
        {`, ${created.fromNow()}`}
      </AppText>
    </div>
  );
};

DiscussionAuthor.defaultProps = {
  className: null,
};

DiscussionAuthor.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  createdAt: PropTypes.object.isRequired,
};

export default DiscussionAuthor;
