import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Snackbar as MaterialSnackbar } from '@material-ui/core';
import SnackbarContent from './SnackbarContent';

const Snackbar = ({
  variant, message,
}) => {
  const [open, setOpen] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (message) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [message]);

  return (
    <MaterialSnackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <SnackbarContent
        onClose={() => setOpen(false)}
        message={message}
        variant={variant}
      />
    </MaterialSnackbar>
  );
};

Snackbar.defaultProps = {
  variant: 'info',
  message: null,
};

Snackbar.propTypes = {
  message: PropTypes.string,
  variant: PropTypes.string,
};

export default Snackbar;
