import React, { Suspense } from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import './App.css';
import Splash from './components/Splash';
import Boot from './Boot';
import store from './providers/store';
import './providers/i18n';
import Navigator from './routes/Navigator';
import theme from './styles/theme';

const App = () => (
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <Suspense fallback={<Splash />}>
        <Boot>
          <Navigator />
        </Boot>
      </Suspense>
    </MuiThemeProvider>
  </Provider>
);

export default App;
