import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  makeStyles,
  TextField,
  InputBase,
  InputAdornment
} from '@material-ui/core';
import NumberFormat from 'react-number-format';

const styles = makeStyles((theme) => ({
  labelRoot: {
    fontFamily: '\'Montserrat\', sans-serif',
  },
  root: {
    '& label': {
      color: theme.palette.common.white,
    },
    '& input': {
      color: theme.palette.common.white,
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: theme.palette.common.white,
    },
  },
  fillWhite: {
    backgroundColor: `${theme.palette.common.white} !important`,
  },
  noErrors: {
    marginBottom: theme.spacing() + theme.typography.fontSize,
  },
}));

/* eslint-disable react/jsx-props-no-spreading */
const NumberFormatComponent = ({
  inputRef, onChange, placeholder, ...other
}) => (
  <NumberFormat
    {...other}
    prefix="$"
    getInputRef={inputRef}
    placeholder={placeholder}
    onValueChange={(values) => {
      onChange({
        target: {
          value: values.value,
        },
      });
    }}
    decimalScale={2}
    fixedDecimalScale
    thousandSeparator
  />
);

NumberFormatComponent.defaultProps = {
  placeholder: null,
};

NumberFormatComponent.propTypes = {
  inputRef: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

const Input = ({
  className,
  label,
  value,
  onChangeText,
  invert,
  secure,
  date,
  onKeyPress,
  error,
  price,
  fullWidth,
  required,
  variant,
  placeholder,
  adornment,
  ...other
}) => {
  const classes = styles();
  let type = 'text';
  if (secure) {
    type = 'password';
  }

  if (date) {
    type = 'date';
  }

  return (
    <TextField
      {...other}
      className={
        classNames(
          !error && classes.noErrors,
          className,
        )
      }
      variant={variant}
      type={type}
      onKeyPress={onKeyPress}
      label={label}
      InputProps={{
        inputComponent: price ? NumberFormatComponent : InputBase,
        startAdornment: adornment ? (<InputAdornment position="start">{adornment}</InputAdornment>) : null,
        classes: {
          root: (invert && variant === 'filled') && classes.fillWhite,
          focused: (invert && variant === 'filled') && classes.fillWhite,
        },
      }}
      InputLabelProps={{
        classes: {
          root: classes.labelRoot,
        },
      }}
      onChange={(e) => onChangeText(e.target.value)}
      value={value}
      classes={{
        root: !invert && classes.root,
      }}
      error={!!error}
      helperText={error}
      fullWidth={fullWidth}
      required={required}
      placeholder={placeholder}
      margin="normal"
    />
  );
};

Input.defaultProps = {
  className: null,
  label: null,
  placeholder: null,
  invert: false,
  secure: false,
  price: false,
  onKeyPress: null,
  error: null,
  fullWidth: false,
  required: false,
  onChangeText: null,
  variant: 'standard',
  date: false,
  adornment: null,
};

Input.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChangeText: PropTypes.func,
  onKeyPress: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  error: PropTypes.string,
  invert: PropTypes.bool,
  secure: PropTypes.bool,
  price: PropTypes.bool,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
  variant: PropTypes.string,
  date: PropTypes.bool,
  adornment: PropTypes.string,
};

export default Input;
