import { useEffect, useState } from 'react';
import { storage } from 'firebase';

const firebaseStorage = storage();

const useStoryCovers = (uuid, coverPosition = 1) => {
  // TODO: This makes a lot of requests every time a pane or story is loaded.
  //  Best is if we change this to call within the saga.
  const [cover, setCover] = useState(null);

  useEffect(() => {
    const getCovers = async () => {
      const folder = await firebaseStorage.ref(
        `story_cover/${uuid}`,
      )
        .listAll();

      const coverImage = folder.items[coverPosition - 1] || folder.items[0];
      if (coverImage) {
        const url = await coverImage.getDownloadURL();
        setCover(url);
      }
    };

    getCovers();
  }, [uuid, coverPosition]);

  return cover;
};

export default useStoryCovers;
