import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Button from './Button';

const styles = makeStyles((theme) => ({
  container: {
    background: '#5D9140',
    '&:hover': {
      backgroundColor: '#6bb047',
    },
  },
  text: {
    fontSize: '1rem',
    lineHeight: '1.38rem',
    color: theme.palette.common.white,
  },
}));

const ButtonSupport = ({ className, onPress }) => {
  const classes = styles();
  const { t } = useTranslation();
  return (
    <Button
      variant="contained"
      className={classNames([classes.container, className])}
      textClassName={classes.text}
      onPress={onPress}
    >
      {t('donation.support')}
    </Button>
  );
};

ButtonSupport.defaultProps = {
  className: null,
};

ButtonSupport.propTypes = {
  className: PropTypes.string,
  onPress: PropTypes.func.isRequired,
};

export default ButtonSupport;
