import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AppText from '../AppText';
import Button from '../Button';
import useRouter from '../../services/useRouter';
import Constants from '../../constants';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  header: {
    marginBottom: theme.spacing(3),
    textAlign: 'center',
  },
  button: {
    display: 'inline-block',
    padding: 0,
    textAlign: 'left',
    minWidth: 0,
  },
  name: {
    color: theme.palette.common.white,
  },
  text: {
    display: 'inline-block',
    marginRight: theme.spacing(),
    textAlign: 'left',
    color: '#8E8E8E',
  },
  author: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
  },
  authorText: {
    textAlign: 'left',
  },
  avatar: {
    marginRight: theme.spacing(3),
  },
  and: {
    color: '#8E8E8E',
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  },
}));

const StoryAuthor = ({ authorName, authorDocument }) => {
  const { history } = useRouter();

  const navigate = (document) => history.push(
    `${Constants.routes.Author}/${document.id}`,
  );

  const classes = useStyles();
  return (
    <Button
      key={authorName}
      textClassName={classes.authorText}
      className={classNames([classes.button])}
      onPress={() => navigate(authorDocument)}
    >
      {authorName}
    </Button>
  );
};

StoryAuthor.propTypes = {
  authorName: PropTypes.string.isRequired,
  authorDocument: PropTypes.string.isRequired,
};

const StoryAuthors = ({
  className, authors,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const localAuthors = [...authors];

  const lastAuthor = localAuthors.pop();
  const secondLastAuthor = localAuthors.pop();

  return (
    <div className={classNames([classes.container, className])}>
      <AppText className={classes.text} variant="body1">
        {t('creators')}
      </AppText>
      {!secondLastAuthor && localAuthors.length === 0
        ? (
          <StoryAuthor
            authorName={lastAuthor.authorName}
            authorDocument={lastAuthor.authorDocument}
          />
        )
        : (
          <>
            {localAuthors.map(({ authorName, authorDocument }) => (
              <>
                <StoryAuthor
                  authorName={authorName}
                  authorDocument={authorDocument}
                />
                <AppText className={classes.text} variant="body1">,</AppText>
              </>
            ))}
            <StoryAuthor
              authorName={secondLastAuthor.authorName}
              authorDocument={secondLastAuthor.authorDocument}
            />
            <AppText className={classes.and}>{t('and')}</AppText>
            <StoryAuthor
              authorName={lastAuthor.authorName}
              authorDocument={lastAuthor.authorDocument}
            />
          </>
        )}
    </div>
  );
};

StoryAuthors.defaultProps = {
  className: null,
};

StoryAuthors.propTypes = {
  className: PropTypes.string,
  authors: PropTypes.array.isRequired,
};

export default StoryAuthors;
