const postTransformer = (discussion) => {
  const {
    title,
    description,
    author: {
      display_name: name,
      document,
    },
    categories,
    created_at: createdAt,
    updated_at: lastUpdated,
  } = discussion.data();

  return {
    uuid: discussion.id,
    title,
    description,
    author: {
      name,
      document,
    },
    categories,
    createdAt,
    lastUpdated,
  };
};

export default postTransformer;
