import React from 'react';
import PropTypes from 'prop-types';
import { Page } from 'react-pdf/dist/entry.webpack';
import { makeStyles } from '@material-ui/core';
import Loading from '../Loading';

const styles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  page: {
    position: 'relative',
    '& > canvas, & > .react-pdf__Page__svg > svg, & > div': {
      width: '100% !important',
      height: 'auto !important',
    },
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const ReaderPDFPage = ({ onLoad, index }) => {
  const classes = styles();
  return (
    <Page
      className={classes.page}
      key={`page_${index + 1}`}
      pageNumber={index + 1}
      onRenderSuccess={onLoad}
      loading={(
        <div className={classes.loadingContainer}>
          <Loading size={20} />
        </div>
      )}
    />
  );
};

ReaderPDFPage.defaultProps = {
  onLoad: null,
};

ReaderPDFPage.propTypes = {
  onLoad: PropTypes.func,
  index: PropTypes.number.isRequired,
};

export default ReaderPDFPage;
