import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '../Button';
import useRouter from '../../services/useRouter';
import Constants from '../../constants';

const useStyles = makeStyles((theme) => ({
  card: {
    background: 'center / cover no-repeat',
    position: 'relative',
    display: 'flex',
    height: 471,
    borderRadius: 0,
    width: '100%',
  },
  gradient: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'linear-gradient(180deg, rgba(255,255,255,0) 31%, #151515  100%)',
  },
  details: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    padding: theme.spacing(4),
  },
  title: {
    color: theme.palette.common.white,
  },
  caption: {
    marginTop: theme.spacing(),
    color: '#8E8E8E',
  },
}));

const AkademyMasterCard = ({
  uuid, image, title, caption,
}) => {
  const classes = useStyles();
  const { history } = useRouter();

  const navigate = () => history.push(
    `${Constants.routes.AkademyCreator}/${uuid}`,
  );

  return (
    <Button
      onPress={navigate}
      className={classes.card}
      style={{ backgroundImage: `url(${image})` }}
    >
      <div className={classes.gradient} />
      <div className={classes.details}>
        <Typography className={classes.title} variant="h4">
          {title}
        </Typography>
        <Typography className={classes.caption} variant="body1">
          {caption}
        </Typography>
      </div>
    </Button>
  );
};

AkademyMasterCard.propTypes = {
  uuid: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
};

export default AkademyMasterCard;
