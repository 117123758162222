import { createMuiTheme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

const fontFaceMain = {
  fontFamily: [
    '\'Source Sans Pro\', sans-serif',
  ],
};

const fontFaceAlt = {
  fontFamily: [
    '\'Montserrat\', sans-serif',
  ],
};

const theme = createMuiTheme({
  status: {
    success: green,
  },
  palette: {
    primary: {
      main: '#d4a367',
    },
    secondary: {
      main: '#8E8E8E',
    },
    header: {
      main: '#151515',
      contrastText: '#fafafa',
    },
    icon: {
      main: '#818181',
    },
    dark: {
      main: '#333333',
    },
    text: {
      primary: '#414040',
      secondary: '#b2b2b2',
    },
    page: {
      main: '#efefef',
    }
  },
  border: {
    light: '#e2e2e2',
  },
  images: {
    background: '#d7d7d7',
  },
  typography: {
    fontSize: 13,
    h1: {
      ...fontFaceMain,
      fontSize: '3rem',
      lineHeight: '3.5rem',
      fontWeight: 'bold',
      letterSpacing: 0,
    },
    h2: { ...fontFaceMain, fontWeight: 700, fontSize: '2.8rem' },
    h3: { ...fontFaceMain, fontWeight: 600, fontSize: '2.25rem' },
    h4: {
      ...fontFaceMain,
      fontWeight: 600,
      fontSize: '1.75rem',
    },
    h5: { ...fontFaceMain, fontWeight: 400, fontSize: '1.4rem' },
    h6: { ...fontFaceMain, fontWeight: 300, fontSize: '1.2rem' },
    body1: {
      ...fontFaceAlt,
      fontSize: '1rem',
      lineHeight: '1.38rem',
    },
    body2: {
      ...fontFaceAlt,
      fontWeight: 200,
      fontSize: '1.1rem',
      lineHeight: '1.88rem',
    },
    subtitle1: {
      ...fontFaceAlt,
      fontWeight: 200,
      fontSize: '0.8rem',
    },
  },
  icon: {
    width: '1em',
    height: '1em',
    display: 'inline-block',
    fontSize: 20,
    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    userSelect: 'none',
    flexShrink: 0,
  },
  card: {
    position: 'relative',
    boxShadow: 'none',
    borderRadius: 40,
    overflow: 'visible',
    '&::before': {
      content: "''",
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: -1,
      boxShadow: '0 3px 6px rgba(0,0,0,.10)',
      borderRadius: 40,
    },
  },
  overrides: {
      MuiTab: {
          root: {
              borderBottom: '#b2b2b2 1px solid',
          },
      },
  },
});

export default theme;
