import React from 'react';
import PropTypes from 'prop-types';
import AuthorPublishedStoriesCard from './AuthorPublishedStoriesCard';

const AuthorPublishedStories = ({ stories, theme, editable }) => (
  <div>
    {stories.map(({
      uuid, title, reviewing, genres, synopsis,
    }) => (
      <AuthorPublishedStoriesCard
        key={title}
        id={uuid}
        name={title}
        reviewing={reviewing}
        genres={genres}
        synopsis={synopsis}
        themeId={theme}
        editable={editable}
      />
    ))}
  </div>
);

AuthorPublishedStories.defaultProps = {
  stories: [],
  theme: 'dark',
  editable: false,
};

AuthorPublishedStories.propTypes = {
  stories: PropTypes.array,
  theme: PropTypes.string,
  editable: PropTypes.bool,
};

export default AuthorPublishedStories;
