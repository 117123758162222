import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import AppText from '../AppText';

const styles = makeStyles((theme) => ({
  badge: {
    display: 'inline-block',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1.25),
    paddingRight: theme.spacing(1.25),
    borderRadius: 4,
    backgroundColor: '#E8E8E8',
    marginRight: theme.spacing(),
    userSelect: 'none',
    webkitUserSelect: 'none',
    msUserSelect: 'none',
    textDecoration: 'none',
  },
  text: {
    color: '#1A1A1A',
    textTransform: 'capitalize',
  },
}));

const BadgeGenre = ({ className, text }) => {
  const classes = styles();
  return (
    <div className={classNames([classes.badge, className])}>
      <AppText className={classes.text} variant="subtitle1">{ text }</AppText>
    </div>
  );
};

BadgeGenre.defaultProps = {
  className: null,
};

BadgeGenre.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  text: PropTypes.string.isRequired,
};

export default BadgeGenre;
