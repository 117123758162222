import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import DiscussionPost from '../DiscussionPost';

const useStyles = makeStyles(() => ({
  container: {
    borderBottom: '1px solid #484848',
  },
}));

const DiscussionBoardPost = ({
  uuid, user, title, description, createdAt,
}) => {
  const classes = useStyles();
  return (
    <DiscussionPost
      className={classes.container}
      user={user}
      description={description}
      uuid={uuid}
      title={title}
      createdAt={createdAt}
    />
  );
};

DiscussionBoardPost.propTypes = {
  uuid: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
};

export default DiscussionBoardPost;
