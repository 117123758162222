import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import BookCarousel from './BookCarousel';

const styles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    marginBottom: theme.spacing(2),
    userSelect: 'none',
    webkitUserSelect: 'none',
    msUserSelect: 'none',
    boxSizing: 'border-box',
  },
  carousel: {
    flex: 1,
  },
}));

const BookList = ({
  className, chapters, width, height,
}) => {
  const classes = styles();
  const [index, setIndex] = useState(0);

  chapters.sort(() => 0.5 - Math.random());

  const previous = () => index > 0 && setIndex(index - 1);
  const next = () => index < chapters.length && setIndex(index + 1);

  return (
    <div
      className={classNames([classes.container, className])}
      style={{ height }}
    >
      <BookCarousel
        className={classes.carousel}
        width={width}
        height={height}
        next={next}
        previous={previous}
        index={index}
        chapters={chapters}
      />
    </div>
  );
};

BookList.defaultProps = {
  className: null,
  width: 145,
  height: 236,
};

BookList.propTypes = {
  chapters: PropTypes.array.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
};

export default BookList;
