import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  container: {
    height: '100%',
    marginRight: theme.spacing(2),
    cursor: 'pointer',
  },
}));

const CarouselItem = ({ width, onPress, children }) => {
  const classes = styles();
  return (
    <div
      role="button"
      onKeyDown={(event) => {
        if (event.keyCode === 13) {
          onPress();
        }
      }}
      tabIndex={-1}
      onClick={onPress}
      className={classes.container}
      style={{ width }}
    >
      {children}
    </div>
  );
};

CarouselItem.propTypes = {
  width: PropTypes.number.isRequired,
  onPress: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default CarouselItem;
