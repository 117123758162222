import {
  fork, all, takeLatest, call, select, put,
} from 'redux-saga/effects';
import { firestore, storage } from 'firebase';

import firebase from '../../firebase';
import {
  actions,
  putDiscussions,
  discussionsCreateErrors,
  discussionsCreateSuccess,
  discussionsItemPut,
} from '../actions/Discussions';
import Constants from '../../../constants';
import { userProfileRequest } from '../actions/User';
import postTransformer from '../../transformers/Post';

const getUser = (state) => ({
  user: state.userReducer.user,
});

const addCall = async (collection, object) => collection.add(object);

const saveCall = async (ref, file) => ref.put(file);

const transformer = (discussions) => {
  const data = [];
  discussions.forEach((discussion) => data.push(postTransformer(discussion)));
  return [...data];
};

const collection = firestore().collection(
  Constants.firebase.collections.discussion,
);

function* saveMaterialSaga(draftFolder, material) {
  const { name, file } = material;
  const source = `${draftFolder}/${name}`;
  const ref = storage().ref(source);
  yield call(saveCall, ref, file);
}

/**
 * This is only the logic for processing the published document. No errors or
 * exceptions are expected within this block.
 *
 * If there are any, they will be caught by the exception handler in
 * createDiscussionSaga.
 *
 * @param title
 * @param description
 * @param categories
 * @param materials
 */
function* addDocumentSaga({
  title,
  description,
  categories,
  materials,
}) {
  yield put(userProfileRequest());
  const { user } = yield select(getUser);
  const { uid, displayName } = user;

  const draftDocument = firestore()
    .collection(Constants.firebase.collections.discussion);

  const ref = yield call(
    addCall,
    draftDocument,
    {
      title,
      author: {
        display_name: displayName,
        document: `${Constants.firebase.collections.profile}/${uid}`,
      },
      categories,
      description,
      reviewing: true,
      created_at: new Date(),
      updated_at: new Date(),
    },
  );

  const draftFolder = `/discussions/${ref.id}`;
  yield all(
    materials.map(
      (material) => call(
        saveMaterialSaga,
        draftFolder,
        material,
      ),
    ),
  );

  return ref.id;
}

/**
 * @param uuid
 */
function* getDiscussionSaga({ payload: uuid }) {
  yield fork(
    firebase.firestore.syncDocument,
    `${Constants.firebase.collections.discussion}/${uuid}`,
    {
      successActionCreator: discussionsItemPut,
      transform: postTransformer,
    },
  );
}

function* createDiscussionSaga({
  payload: {
    title,
    categories,
    description,
    materials,
  },
}) {
  try {
    const id = yield call(
      addDocumentSaga,
      {
        title,
        description,
        categories,
        materials,
      },
    );

    yield put(
      discussionsCreateSuccess({
        id,
        message: 'Created a new post.',
      }),
    );
  } catch (e) {
    let errors = {};
    let message = null;
    if (e.response && e.response.data) {
      message = e.response.data.message;
      errors = e.response.data.errors;
    }

    yield put(
      discussionsCreateErrors({
        message: message || 'An unknown error has occurred.',
        errors,
      }),
    );
  }
}

export default function* Discussions() {
  yield all([
    fork(
      firebase.firestore.syncCollection,
      collection.orderBy('created_at', 'desc'),
      {
        successActionCreator: putDiscussions,
        transform: transformer,
      },
    ),
    takeLatest(actions.ITEM.GET, getDiscussionSaga),
    takeLatest(actions.CREATE.REQUEST, createDiscussionSaga),
  ]);
}
