import React from 'react';
import PropTypes from 'prop-types';
import {
  Menu,
  MenuList,
  MenuItem,
  makeStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const styles = makeStyles((theme) => ({
  selector: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    textAlign: 'right',
    maxWidth: 124,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  menu: {
    '& .MuiMenu-paper': {
      backgroundColor: '#151515',
      color: '#fff',
      border: '1px solid #707070'
    },
    '& .MuiMenuItem-root': {
      fontFamily: '\'Source Sans Pro\', sans-serif',
      fontSize: '0.85em',
    },
    '& .MuiList-root': {
      paddingTop: 8,
      paddingBottom: 8,
      outline: 'none',
    },
  },
}));

const LanguageSelector = ({
  anchorEl,
  open,
  setClose,
}) => {
  const { i18n } = useTranslation();
  const classes = styles();

  const onSwitch = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <Menu
      className={classes.menu}
      elevation={2}
      anchorEl={anchorEl}
      open={open}
      onClose={setClose}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      keepMounted
    >
      <MenuList>
        <MenuItem onClick={() => onSwitch('en')}>
              English
        </MenuItem>
        <MenuItem onClick={() => onSwitch('jp')}>
              日本語
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

LanguageSelector.defaultProps = {
  anchorEl: null,
};

LanguageSelector.propTypes = {
  anchorEl: PropTypes.object,
  open: PropTypes.bool.isRequired,
  setClose: PropTypes.func.isRequired,
};

export default LanguageSelector;
