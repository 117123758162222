import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Collapse,
  makeStyles,
  IconButton,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ArrowBack from '@material-ui/icons/ArrowBack';
import StandardLayout from '../components/Layout/StandardLayout';
import AppText from '../components/AppText';
import constants from '../constants';
import Input from '../components/Input/Input';
import Loading from '../components/Loading';
import ErrorBox from '../components/ErrorBox';
import Button from '../components/Button';
import useRouter from '../services/useRouter';
import { login, userCreateRequest, userResetPWRequest } from '../providers/redux/actions/User';
import KomaBook from '../assets/images/login/Komakaku@2x.png';
import background from '../assets/images/login/bg@2x.png';

const TNCDoc = constants.routes.FileStore + '/web_assets%2F20190913_komakaku_user_tnc.pdf?alt=media&token=b532c412-1eb3-43a7-97f0-a55d5540e9af';

const styles = makeStyles((theme) => ({
  loginPanel: {
    backgroundColor: '#2B2B2B',
    height: '100vh',
    marginTop: '-16px',
  },
  title: {
    color: '#D4A367',
    marginTop: theme.spacing(4),
  },
  noAccountLabel: {
    textAlign: 'center',
    fontSize: 14,
    color: '#8e8e8e',
  },
  forgotPassword: {
    marginTop: theme.spacing(2),
    textAlign: 'right',
    fontSize: 14,
    color: '#8e8e8e',
  },
  input: {
    marginTop: theme.spacing(2),
    '& .MuiInput-root': {
      fontSize: 14,
      color: '#8E8E8E',
      fontFamily: '\'Montserrat\', sans-serif',
      marginTop: theme.spacing(2),
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#8E8E8E',
    },
    '& .MuiInputLabel-root': {
      fontSize: 14,
      color: '#8E8E8E',
      fontFamily: '\'Montserrat\', sans-serif',
    },
    '& input': {
      color: '#8E8E8E',
      padding: '0 0 7px',
    },
  },
  bg: {
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    textAlign: 'center',
  },
  book: {
    maxWidth: '70%',
  },
  checkBox: {
    '& .MuiTypography-root': {
      fontSize: 13,
      color: '#b9b9b9',
      fontFamily: '\'Montserrat\', sans-serif',
    },
    '& .MuiCheckbox-root': {
      fontSize: 13,
      color: '#b9b9b9',
      fontFamily: '\'Montserrat\', sans-serif',
    },
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(1),
  },
  forgotPasswordGrid: {
    textAlign: 'right',
    marginTop: 13,
  },
  button: {
    fontFamily: '\'Montserrat\', sans-serif',
    textTransform: 'uppercase',
    flex: 1,
    '& .MuiButton-label': {
      fontSize: 13,
    },
  },
  registerButton: {
    flex: 1,
    fontFamily: '\'Montserrat\', sans-serif',
    textTransform: 'uppercase',
    textAlign: 'center',
    '& .MuiButton-label': {
      fontSize: 13,
    },
    '& .MuiButton-label > span': {
      color: '#b9b9b9',
    },
  },
  tnc: {
    marginTop: theme.spacing(1),
    fontFamily: '\'Montserrat\', sans-serif',
    fontSize: 10,
    color: '#b9b9b9',
    textAlign: 'center',
  },
  backIcon: {
    color: theme.palette.common.white,
    width: '1em',
    height: '1em',
  },
  backButton: {
    marginLeft: -15,
    marginTop: theme.spacing(3),
  },
}));

const LoginScreen = ({
  error,
  createError,
  forgotError,
  loading,
  processing,
  loggedIn,
  login: loginAction,
  userCreateRequest: userCreateRequestAction,
  userResetPWRequest: userResetPWRequestAction,
}) => {
  const { t } = useTranslation();
  const { history } = useRouter();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const domain = window.location.host;

  const [forgotEmail, setForgotEmail] = useState('');

  const [openRegister, setOpenRegister] = useState(false);
  const [openForgotPassword, setOpenForgotPassword] = useState(false);

  const classes = styles();

  useEffect(() => {
    if (loggedIn) {
      history.push(constants.routes.Home);
    }
  }, [loggedIn, history]);

  const handleRegisterOpen = () => {
    setOpenRegister((prev) => !prev);
  };

  const handleForgotPasswordOpen = () => {
    setOpenForgotPassword((prev) => !prev);
  };

  const submit = () => loginAction({ username, password });
  const submitRegister = () => userCreateRequestAction({ email, newPassword, confirmPassword });
  const submitForgotPassword = () => userResetPWRequestAction({ forgotEmail, domain });

  const onKeyPress = (e) => {
    if (!loading && !(username === '' || password === '')) {
      if (e.key === 'Enter') {
        loginAction({ username, password });
      }
    }
  };

  const onRegisterKeyPress = (e) => {
    if (!processing && !(email === '' || newPassword === '' || confirmPassword === '')) {
      if (e.key === 'Enter') {
        userCreateRequestAction({ email, newPassword, confirmPassword });
      }
    }
  };

  const onForgotKeyPress = (e) => {
    if (!processing && forgotEmail !== '') {
      if (e.key === 'Enter') {
        userResetPWRequestAction({ forgotEmail, domain });
      }
    }
  };

  return (
    <StandardLayout>
      <Grid container spacing={3}>
        <Hidden smDown>
          <Grid className={classes.bg} item md={8} xs={12}>
            <img src={KomaBook} className={classes.book}></img>
          </Grid>
        </Hidden>
        <Grid item md={4} xs={12} className={classes.loginPanel}>
          <Container>
            <Collapse in={!openRegister && !openForgotPassword}>
              <AppText className={classes.title} style={{ marginTop: 72 }} header uppercase variant="h5">{t('login.subtitle')}</AppText>
              <Input
                label={t('forms.user.email')}
                className={classes.input}
                onKeyPress={onKeyPress}
                onChangeText={setUsername}
                value={username}
                fullWidth
              />
              <Input
                label={t('forms.user.password')}
                className={classes.input}
                onKeyPress={onKeyPress}
                onChangeText={setPassword}
                value={password}
                fullWidth
                secure
              />

              <Grid className={classes.linkContainer}>
                <Grid xs={6}>
                  <FormControlLabel className={classes.checkBox} control={<Checkbox value="checkedC" />} label={t('login.remember_me')} />
                </Grid>
                <Grid className={classes.forgotPasswordGrid} xs={6}>
                  <Link className={classes.forgotPassword} onClick={handleForgotPasswordOpen}>{t('login.forgot_password')}</Link>
                </Grid>
              </Grid>

              <div className={classes.buttonContainer}>
                { loading
                  ? <Loading size={48} />
                  : (
                    <Button
                      className={classes.button}
                      variant="contained"
                      onPress={submit}
                      disabled={username === '' || password === ''}
                      color="primary"
                      fullWidth
                    >
                      {t('login.title')}
                    </Button>
                  )}
              </div>
              <ErrorBox className={classes.error} error={error} center />
              <AppText className={classes.noAccountLabel}>{t('login.no_account')}</AppText>
              <div className={classes.buttonContainer}>
                <Button
                  variant="outlined"
                  className={classes.registerButton}
                  onPress={handleRegisterOpen}
                >
                  {t('register.title')}
                </Button>
              </div>
            </Collapse>
            <Collapse in={openRegister}>
              <IconButton className={classes.backButton} onClick={handleRegisterOpen} aria-label="back">
                <ArrowBack className={classes.backIcon} />
              </IconButton>
              <AppText className={classes.title} header uppercase variant="h5">{t('register.title')}</AppText>
              <Input
                label={t('forms.user.email')}
                className={classes.input}
                onKeyPress={onRegisterKeyPress}
                onChangeText={setEmail}
                value={email}
                fullWidth
                invert
              />
              <Input
                label={t('forms.user.password')}
                className={classes.input}
                onKeyPress={onRegisterKeyPress}
                onChangeText={setNewPassword}
                value={newPassword}
                secure
                fullWidth
                invert
              />
              <Input
                label={t('forms.user.confirm_password')}
                className={classes.input}
                onKeyPress={onRegisterKeyPress}
                onChangeText={setConfirmPassword}
                value={confirmPassword}
                secure
                fullWidth
                invert
              />
              <AppText className={classes.tnc}>
                  {t('register.tncPre')}
                  <a className={classes.tnc} href={TNCDoc} target="_blank">{t('register.tncLinkText')}</a>
              </AppText>
              <div className={classes.buttonContainer}>
                {processing
                  ? <Loading />
                  : (
                    <Button
                      variant="contained"
                      className={classes.button}
                      onPress={submitRegister}
                      disabled={email === '' || newPassword === '' || confirmPassword === ''}
                    >
                      {t('register.submit')}
                    </Button>
                  )}
              </div>
              <ErrorBox error={createError} center />
            </Collapse>

            <Collapse in={openForgotPassword}>
              <IconButton className={classes.backButton} onClick={handleForgotPasswordOpen} aria-label="back">
                <ArrowBack className={classes.backIcon} />
              </IconButton>
              <AppText className={classes.title} header uppercase variant="h5">{t('login.forgot_password')}</AppText>
              <Input
                label={t('forms.user.email')}
                className={classes.input}
                onKeyPress={onForgotKeyPress}
                onChangeText={setForgotEmail}
                value={forgotEmail}
                fullWidth
              />
              <div className={classes.buttonContainer}>
                {processing
                  ? <Loading />
                  : (
                    <Button
                      variant="contained"
                      className={classes.button}
                      onPress={submitForgotPassword}
                      disabled={forgotEmail === ''}
                    >
                      {t('login.send_reset_link')}
                    </Button>
                  )}
              </div>
              <ErrorBox error={forgotError} center />
            </Collapse>
          </Container>
        </Grid>
      </Grid>
    </StandardLayout>
  );
};

LoginScreen.defaultProps = {
  error: null,
  createError: null,
  forgotError: null,
};

LoginScreen.propTypes = {
  processing: PropTypes.bool.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  message: PropTypes.string,
  error: PropTypes.string,
  createError: PropTypes.string,
  forgotError: PropTypes.string,
  login: PropTypes.func.isRequired,
  userCreateRequest: PropTypes.func.isRequired,
  userResetPWRequest: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  processing: state.userReducer.create.processing,
  success: state.userReducer.create.success,
  message: state.userReducer.create.message,
  loading: state.userReducer.loading,
  loggedIn: state.userReducer.loggedIn,
  error: state.userReducer.error,
  createError: state.userReducer.createError,
  forgotError: state.userReducer.forgotError,
});

export default connect(mapStateToProps, { login, userCreateRequest, userResetPWRequest })(LoginScreen);
