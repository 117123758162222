import React, { forwardRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles, Button as MaterialButton } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Constants from '../../constants';
import AppText from '../AppText';
import { siteLoginOpen } from '../../providers/redux/actions/Site';

const styles = makeStyles((theme) => ({
  button: {
    padding: 8,
    paddingLeft: 16,
    paddingRight: 16,
    alignItems: 'center',
    justifyContent: 'center',
    userSelect: 'none',
    webkitUserSelect: 'none',
    msUserSelect: 'none',
    cursor: 'pointer',
    textTransform: 'none',
    fontWeight: 'normal',
    textAlign: 'left',
  },
  text: {
    textAlign: 'center',
    color: theme.palette.common.white,
  },
  buttonInactive: {
    backgroundColor: Constants.colors.inactive,
  },
  buttonInactiveText: {
    color: Constants.colors.inactiveText,
  },
}));

const Button = forwardRef(({
  onPress,
  style,
  children,
  disabled,
  textStyle,
  className,
  textClassName,
  controls,
  hasPopup,
  color,
  variant,
  classes: classesProp,
  authentication,
  headerText,
  component,
}, ref) => {
  const classes = styles();
  const dispatch = useDispatch();
  const { loggedIn } = useSelector((state) => ({
    loggedIn: state.userReducer.loggedIn,
  }));

  const navigate = (e) => {
    if (!authentication) {
      onPress(e);
      return;
    }

    if (loggedIn) {
      onPress(e);
      return;
    }

    dispatch(siteLoginOpen());
  };
  return (
    <MaterialButton
      ref={ref}
      component={component}
      href={null}
      className={
        classNames([
          classes.button,
          disabled && classes.buttonInactive,
          className,
        ])
      }
      style={style}
      onClick={!disabled ? navigate : null}
      aria-controls={controls}
      aria-haspopup={hasPopup}
      disabled={disabled}
      variant={variant}
      color={color}
      classes={classesProp}
    >
      { typeof children === 'string'
        ? (
          <AppText
            className={classNames([
              classes.text,
              disabled ? classes.buttonInactiveText : null,
              textClassName])}
            style={textStyle}
            header={headerText}
            variant="body1"
          >
            { children }
          </AppText>
        )
        : children }
    </MaterialButton>
  );
});

Button.defaultProps = {
  style: null,
  textStyle: null,
  className: null,
  textClassName: null,
  disabled: false,
  controls: null,
  hasPopup: false,
  color: 'secondary',
  variant: 'text',
  classes: null,
  authentication: false,
  headerText: false,
  component: 'button',
};

Button.propTypes = {
  onPress: PropTypes.func.isRequired,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  textStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  textClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  disabled: PropTypes.bool,
  controls: PropTypes.string,
  hasPopup: PropTypes.bool,
  color: PropTypes.string,
  variant: PropTypes.string,
  classes: PropTypes.object,
  authentication: PropTypes.bool,
  headerText: PropTypes.bool,
  component: PropTypes.string,
};

export default Button;
