export const actions = {
  STORY: {
    LOAD: 'READER_STORY_LOAD',
    LOADED: 'READER_STORY_LOADED',
  },
  CHAPTER: {
    REFERENCE: 'READER_CHAPTER_REFERENCE',
    GOTO: 'READER_CHAPTER_GOTO',
    LOAD: 'READER_CHAPTER_LOAD',
  },
};

export const readerStoryLoad = (story) => ({
  type: actions.STORY.LOAD,
  payload: story,
});

export const readerStoryLoaded = () => ({
  type: actions.STORY.LOADED,
});

export const readerChapterGoto = ({ storyId, uuid }) => ({
  type: actions.CHAPTER.GOTO,
  payload: {
    storyId,
    uuid,
  },
});

export const readerChapterLoad = ({
  chapterReference,
  previousChapter,
  chapter,
  nextChapter,
}) => ({
  type: actions.CHAPTER.LOAD,
  payload: {
    chapterReference,
    previousChapter,
    chapter,
    nextChapter,
  },
});

export const readerChapterLoadFromReference = ({ storyChapter }) => ({
  type: actions.CHAPTER.REFERENCE,
  payload: storyChapter,
});
