import { all } from 'redux-saga/effects';
import Reader from './Reader';
import User from './User';
import Stories from './Stories';
import Chapters from './Chapters';
import Submissions from './Submissions';
import Genres from './Genres';
import SubGenres from './SubGenres';
import Discussions from './Discussions';
import Authors from './Authors';
import Payment from './Payment';
import Transactions from './Transactions';
import Withdrawal from './Withdrawal';

export default function* rootSaga() {
  yield all([
    Reader(),
    User(),
    Chapters(),
    Stories(),
    Submissions(),
    Genres(),
    SubGenres(),
    Discussions(),
    Authors(),
    Payment(),
    Transactions(),
    Withdrawal()
  ]);
}
