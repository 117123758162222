import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeStyles,
  FormControl,
  FormLabel,
  Grid,
} from '@material-ui/core';
import StandardLayout from '../components/Layout/StandardLayout';
import Input from '../components/Input/Input';
import Button from '../components/Button';
import ButtonBack from '../components/Button/ButtonBack';
import Snackbar from '../components/Snackbar';
import UploadFiles from '../components/UploadFiles';
import useProtectRoute from '../providers/hooks/useProtectRoute';
import InputTextArea from '../components/Input/InputTextArea';
import AppText from '../components/AppText';
import LayoutLight from '../components/Layout/LayoutLight';
import ResponsiveWrapper from '../components/ResponsiveWrapper';
import CategoriesList from '../components/CategoriesList';
import useRouter from '../services/useRouter';
import Constants from '../constants';
import {
  discussionsCreateRequest,
  discussionsCreateReset,
} from '../providers/redux/actions/Discussions';
import Loading from '../components/Loading';
// import Scheduler from '../components/Scheduler';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    paddingLeft: theme.spacing(8.5),
    paddingRight: theme.spacing(8.5),
    [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
  },
  container: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  buttonContainer: {
    paddingBottom: theme.spacing()
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing()
  },
  header: {
    flex: 1,
    color: theme.palette.common.white,
    marginTop: theme.spacing(),
  },
  subtitle: {
    marginTop: theme.spacing(2),
    color: '#8E8E8E',
  },
  footer: {
    borderTop: '1px solid #DEDEDE',
    marginTop: theme.spacing(5),
    paddingTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  page: {
    padding: theme.spacing(3),
  },
  input: {
    marginBottom: theme.spacing(4),
    '&::placeholder': {
      fontFamily: '\'Montserrat\', sans-serif',
    },
  },
  tnc: {
    flex: 1,
    paddingRight: theme.spacing(2),
    fontSize: 13,
  },
  tncLine: {
    display: 'block',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  field: {
    flex: 1,
  },
  selectField: {
    flex: 1,
    '& .MuiInputLabel-formControl': {
      top: -8,
      left: 12,
      backgroundColor: '#fff',
      zIndex: 1,
      padding: 4,
    },
    '& .MuiInputBase-input': {
      fontFamily: '\'Montserrat\', sans-serif',
      fontSize: '0.85em',
    },
  },
  label: {
    fontFamily: '\'Montserrat\', sans-serif',
    fontWeight: 600,
    fontSize: '0.85em',
    color: '#151515',
  },
  languageSelectInput: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  successText: {
    textAlign: 'center',
  },
  create: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
}));

const labelStyles = makeStyles((theme) => ({
  root: {
    fontFamily: '\'Montserrat\', sans-serif',
    marginBottom: theme.spacing(2),
    fontWeight: 600,
    fontSize: '0.85em',
    color: '#151515',
  },
}));

const PostCreateScreen = () => {
  useProtectRoute();
  const { history } = useRouter();
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);

  const classes = useStyles();

  const {
    id,
    processing,
    message,
    success,
    errors,
  } = useSelector((state) => ({
    id: state.discussionsReducer.create.id,
    processing: state.discussionsReducer.create.processing,
    success: state.discussionsReducer.create.success,
    message: state.discussionsReducer.create.message,
    errors: state.discussionsReducer.create.errors,
  }));

  useEffect(() => {
    if (success) {
      dispatch(discussionsCreateReset());
      history.push(`${Constants.routes.Discussion}/${id}`);
    }
  }, [success]);

  const [title, setTitle] = useState('');
  const [categories, setCategories] = useState([]);
  const [description, setDescription] = useState('');
  const { t } = useTranslation();

  const backToDiscussion = () => history.push(Constants.routes.Discussion);

  const {
    title: titleErrors,
    description: descriptionError,
  } = errors;

  const isChecked = (category) => categories.includes(category);

  const onCheck = (category) => {
    if (isChecked(category)) {
      setCategories(categories.filter((includedGenre) => includedGenre !== category));
      return;
    }

    const newCategories = [...categories];
    newCategories.push(category);
    setCategories(newCategories);
  };

  const submit = () => dispatch(
    discussionsCreateRequest({
      title,
      description,
      categories,
      materials: files,
    }),
  );

  return (
    <StandardLayout>
      <Snackbar
        variant={success ? 'success' : 'error'}
        message={message}
      />
      <LayoutLight
        header={(
          <ResponsiveWrapper>
            <div className={classes.buttonContainer}>
                <ButtonBack className={classes.back} white />
            </div>
            <div className={classes.content}>
              <AppText className={classes.header} header variant="h3">
                {t('discussions.share_koma')}
              </AppText>
              <AppText className={classes.subtitle} subtitle variant="h6">
                {t('discussions.showcase')}
              </AppText>
            </div>
          </ResponsiveWrapper>
        )}
      >
        <ResponsiveWrapper className={classes.wrapper}>
          <Grid className={classes.container} spacing={1} container>
            <Grid item md={12} sm={12} xs={12}>
              <Input
                variant="outlined"
                className={classes.input}
                label={t('publish.fields.title')}
                placeholder={t('publish.fields.title_placeholder')}
                onChangeText={setTitle}
                value={title}
                error={titleErrors}
                required
                fullWidth
                invert
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} style={{ paddingTop: 24 }}>
              <FormControl
                className={classNames([classes.input])}
                fullWidth
              >
                <FormLabel classes={labelStyles()} required>
                  {t('discussions.categories')}
                </FormLabel>
                <CategoriesList
                  values={categories}
                  onCheck={onCheck}
                />
              </FormControl>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <UploadFiles
                files={files}
                setFiles={setFiles}
                warning={t('discussions.create.warning')}
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <InputTextArea
                variant="outlined"
                className={classes.input}
                label={t('description')}
                onChangeText={setDescription}
                value={description}
                error={descriptionError}
                required
                filled
                fullWidth
                invert
              />
            </Grid>
            <Grid className={classes.create} item md={12}>
              { processing
                ? <Loading />
                : (
                  <Button
                    onPress={submit}
                    variant="contained"
                    color="primary"
                  >
                    {t('create')}
                  </Button>
                )}
            </Grid>
          </Grid>
        </ResponsiveWrapper>
      </LayoutLight>
    </StandardLayout>
  );
};

export default PostCreateScreen;
