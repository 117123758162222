import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles, Grid, Hidden } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import StandardLayout from '../components/Layout/StandardLayout';
// import Introduction from '../components/Introduction';
import BookList from '../components/BookList';
import Loading from '../components/Loading';
import AppText from '../components/AppText';
import constants from '../constants';

const BannerIntro = constants.routes.FileStore + '/web_assets%2Fchildren-of-kujalle-banner.jpg?alt=media&token=9baf2a6a-d364-4260-b394-b4adb6a00523';
const BannerIntroMobile = constants.routes.FileStore + '/web_assets%2Fchildren-of-kujalle-banner-mobile.jpg?alt=media&token=55550bf8-2bd2-4b8b-94aa-dfd98c031b8e';
const InterviewCover = constants.routes.FileStore + '/web_assets%2Fkoyama-video-cover.jpg?alt=media&token=132506f2-7251-428e-a552-badb09b575bb';
const InterviewCoverMobile = constants.routes.FileStore + '/web_assets%2Fkoyama-video-cover-mobile.jpg?alt=media&token=731d4dfe-a035-4e0c-b769-7008183d9fae';

const styles = makeStyles((theme) => ({
  container: {
    display: 'block',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerContainer: {
    display: 'block',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(),
    marginLeft: theme.spacing(1.8),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  headerText: {
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
        fontSize: '1.25rem'
    },
  },
  responsive: {
    width: '100%',
    height: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  videoContainer: {
    marginBottom: theme.spacing(1),
  },
}));

const HomeScreen = ({ chapters }) => {
  const classes = styles();
  const { t } = useTranslation();

  return (
    <StandardLayout>
      {/* <Introduction /> */}
      <Grid container className={classes.videoContainer}>
        <Grid item md={12} sm={12} xs={12}>
          <Hidden smDown>
            <img src={BannerIntro} alt="Space" className={classes.responsive} />
          </Hidden>
          <Hidden mdUp>
            <img src={BannerIntroMobile} alt="Space" className={classes.responsive} />
          </Hidden>
        </Grid>
      </Grid>
      {/* <div className={classes.videoContainer}
        style={{
          background: `url('${BannerIntro}') center top/contain no-repeat`,
        }}
      >
      </div> */}
      <div className={classes.headerContainer}>
        <AppText className={classes.headerText} header variant="h5">
          {t('home.new')}
        </AppText>
      </div>
      <div className={classes.container}>
        <Hidden xsDown>
          { chapters
            ? <BookList chapters={chapters} width={195} height={341} />
            : <Loading size={48} /> }
        </Hidden>
        <Hidden smUp>
          { chapters
            ? <BookList chapters={chapters} width={100} height={175} />
            : <Loading size={24} /> }
        </Hidden>
      </div>
      <div className={classes.headerContainer}>
          <AppText className={classes.headerText} header variant="h5">
              {t('home.trending')}
          </AppText>
      </div>
      <div className={classes.container}>
          <Hidden xsDown>
              { chapters
                  ? <BookList chapters={chapters} width={240} height={420} />
                  : <Loading size={48} /> }
          </Hidden>
          <Hidden smUp>
              { chapters
                  ? <BookList chapters={chapters} width={160} height={280} />
                  : <Loading size={24} /> }
          </Hidden>
      </div>

      <Grid container className={classes.videoContainer}>
        <Grid item md={3} sm={1}></Grid>
        <Grid item md={6} sm={10} xs={12}>
            <a href="/akademy/creator/koyama-chuya">
                <Hidden smDown>
                    <img src={InterviewCover} alt="Space" className={classes.responsive} />
                </Hidden>
                <Hidden mdUp>
                    <img src={InterviewCoverMobile} alt="Space" className={classes.responsive} />
                </Hidden>
            </a>
        </Grid>
        <Grid item md={3} sm={1}></Grid>
      </Grid>

      <div className={classes.headerContainer}>
        <AppText className={classes.headerText} header variant="h5">{t('home.recommendation')}</AppText>
      </div>
      <div className={classes.container}>
        <Hidden xsDown>
          { chapters
            ? <BookList chapters={chapters} width={195} height={341} />
            : <Loading size={48} /> }
        </Hidden>
        <Hidden smUp>
          { chapters
            ? <BookList chapters={chapters} width={100} height={175} />
            : <Loading size={24} /> }
        </Hidden>
      </div>
    </StandardLayout>
  );
};

HomeScreen.defaultProps = {
  chapters: null,
};

HomeScreen.propTypes = {
  chapters: PropTypes.array,
};

const mapStateToProps = (state) => ({
  chapters: state.chaptersReducer.data,
});

export default connect(mapStateToProps)(HomeScreen);
