import { actions } from '../actions/Transactions';

const initialState = {
  loading: true,
  data: [],
};

const transactionsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case actions.LIST.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.LIST.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case actions.LIST.RESET:
      return {
        ...state,
        loading: false,
        data: null,
      };
    default:
      return state;
  }
};

export default transactionsReducer;
