import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import CarouselSlider from './CarouselSlider';
import CarouselItem from './CarouselItem';
import {
  POSITION_END,
  POSITION_MIDDLE,
  POSITION_START,
} from './PositionConstants';
import CarouselViewer from './CarouselViewer';
import ButtonArrowLeft from '../Button/ButtonArrowLeft';
import ButtonArrowRight from '../Button/ButtonArrowRight';

const styles = makeStyles(() => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
  },
  sliderContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    overflow: 'hidden',
  },
  iconContainer: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconContainerLeft: {
    left: 0,
    bottom: 0,
    top: 0,
  },
  iconContainerRight: {
    right: 0,
    bottom: 0,
    top: 0,
  },
  icon: {
    fontSize: 50,
    color: '#B9B9B9',
  },
  iconDisabled: {
    color: '#414141',
  },
  image: {
    height: '100%',
  },
  iconButton: {
    borderRadius: 0,
    backgroundColor: 'rgba(0,0,0,.61)',
  },
}));

const Carousel = ({
  className, index, next, previous, width, images,
}) => {
  const [open, setOpen] = useState(false);

  let startingPosition;
  if (index === 0) {
    startingPosition = POSITION_START;
  } else if (index === images.length) {
    startingPosition = POSITION_END;
  } else {
    startingPosition = POSITION_MIDDLE;
  }

  const [location, setLocation] = useState(startingPosition);
  const [viewing, setViewing] = useState(null);

  const classes = styles();
  const sliderContainer = useRef(null);

  const openViewer = (image) => {
    setViewing(image);
    setOpen(true);
  };

  const closeViewer = () => setOpen(false);

  return (
    <div
      key={images.length}
      className={classNames([classes.container, className])}
    >
      <CarouselViewer
        setClose={closeViewer}
        open={open}
        position={viewing}
        images={images}
      />
      <div ref={sliderContainer} className={classes.sliderContainer}>
        <CarouselSlider
          container={sliderContainer}
          index={index}
          width={width}
          setLocation={setLocation}
        >
          {images.map((image, key) => (
            <CarouselItem
              key={key.toString()}
              onPress={() => openViewer(key)}
              width={width}
            >
              <div
                className={classes.image}
                style={{ width, backgroundImage: `url('${image}')`, backgroundSize: 'cover' }}
              />
            </CarouselItem>
          ))}
        </CarouselSlider>
      </div>
      {!(!previous || location === POSITION_START) && (
        <div
          className={
            classNames([
              classes.iconContainer,
              classes.iconContainerLeft,
            ])
          }
        >
          <ButtonArrowLeft
            className={classes.iconButton}
            onPress={() => location !== POSITION_START && previous()}
            disabled={!previous || location === POSITION_START}
          />
        </div>
      )}
      { !(!next || location === POSITION_END) && (
        <div
          className={
          classNames([
            classes.iconContainer,
            classes.iconContainerRight,
          ])
        }
        >
          <ButtonArrowRight
            className={classes.iconButton}
            onPress={() => location !== POSITION_END && next()}
            disabled={!next || location === POSITION_END}
          />
        </div>
      )}
    </div>
  );
};

Carousel.defaultProps = {
  className: null,
};

Carousel.propTypes = {
  className: PropTypes.string,
  index: PropTypes.number.isRequired,
  next: PropTypes.func.isRequired,
  previous: PropTypes.func.isRequired,
  images: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
};

export default Carousel;
