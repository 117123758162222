import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { KeyboardArrowLeftRounded } from '@material-ui/icons';
import Button from '../Button';
import useRouter from '../../services/useRouter';
import Constants from '../../constants';
import AppText from '../AppText';

const styles = makeStyles((theme) => ({
  button: {
    flex: 1,
    margin: theme.spacing(),
    borderRadius: theme.spacing(),
    justifyContent: 'flex-start',
  },
  icon: {
    color: theme.palette.common.white,
    marginLeft: theme.spacing(),
  },
}));

const ReaderPreviousChapterButton = () => {
  const classes = styles();
  const { history } = useRouter();
  const { story, previousChapter } = useSelector((state) => ({
    story: state.readerReducer.story,
    previousChapter: state.readerReducer.previousChapter,
  }));

  const navigate = () => history.push(
    `${Constants.routes.Reader}/${story.uuid}/${previousChapter.id}`,
  );

  const { t } = useTranslation();
  return (
    <Button
      className={classes.button}
      onPress={navigate}
    >
      <KeyboardArrowLeftRounded className={classes.icon} />
      <AppText>
        {t('Previous Chapter')}
      </AppText>
    </Button>
  );
};

export default ReaderPreviousChapterButton;
