import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Constants from '../constants';

const styles = makeStyles((theme) => ({
  container: {
    width: '100%',
    maxWidth: Constants.maxWidth,
    boxSizing: 'border-box',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  containerSpacer: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  }
}));

const ResponsiveWrapper = ({ className, children, maxWidth }) => {
  const classes = styles();
  return (
    <div
      className={
        classNames([
          className,
          classes.container,
          classes.containerSpacer,
        ])
      }
      style={{ maxWidth }}
    >
      { children }
    </div>
  );
};

ResponsiveWrapper.defaultProps = {
  className: null,
  maxWidth: Constants.maxWidth,
};

ResponsiveWrapper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  maxWidth: PropTypes.number,
};

export default ResponsiveWrapper;
