import React from 'react';
import PropTypes from 'prop-types';
import { Button, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const styles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.common.white,
    fontSize: 18,
    fontFamily: '\'Montserrat\', sans-serif',
    padding: theme.spacing(2),
    color: theme.palette.common.black,
  },
}));

const ButtonShare = ({ onPress }) => {
  const classes = styles();
  const { t } = useTranslation();
  return (
    <Button
      variant="contained"
      href={null}
      onClick={onPress}
      classes={{
        root: classes.root,
      }}
    >
      {t('Share')}
    </Button>
  );
};

ButtonShare.propTypes = {
  onPress: PropTypes.func.isRequired,
};

export default ButtonShare;
