import React from 'react';
import PropTypes from 'prop-types';
import Select from './Select';

const SelectLanguage = ({
  label, className, variant, onChange, value, iconClassName, placeholder,
}) => (
  <Select
    label={label}
    className={className}
    variant={variant}
    onChange={onChange}
    value={value}
    classes={{
      icon: iconClassName,
    }}
    placeholder={placeholder}
    options={[
      'English',
      'Japanese',
      'Chinese',
      'Korean',
      'French',
      'Spanish',
      'Bahasa Indonesia',
    ]}
  />
);

SelectLanguage.defaultProps = {
  label: null,
  className: null,
  iconClassName: null,
  variant: 'outlined',
};

SelectLanguage.propTypes = {
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  variant: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default SelectLanguage;
