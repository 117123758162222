import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppLoading from './components/AppLoading';
import { syncUser } from './providers/redux/actions/User';
import { getGenres } from './providers/redux/actions/Genres';
import { getSubGenres } from './providers/redux/actions/SubGenres';

const Boot = ({ children }) => {
  const [loaded, setLoaded] = useState(false);
  const { initialSync, genres, subGenres } = useSelector((state) => ({
    initialSync: state.userReducer.initialSync,
    genres: state.genresReducer.data,
    subGenres: state.subGenresReducer.data,
  }));

  const dispatch = useDispatch();
  useEffect(() => {
    if (!initialSync && genres && subGenres) {
      setLoaded(true);
    }

    dispatch(syncUser());
    dispatch(getGenres());
    dispatch(getSubGenres());
  }, [initialSync, genres, subGenres, dispatch]);

  if (!loaded) {
    return <AppLoading />;
  }

  return children;
};

export default Boot;
