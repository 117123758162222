import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import 'pdfjs-dist/webpack';
import InfiniteScroll from 'react-infinite-scroller';
import { Document } from 'react-pdf/dist/entry.webpack';
import { makeStyles } from '@material-ui/core';
import Loading from '../Loading';
import ReaderPDFPage from './ReaderPDFPage';

const rowsPerPage = 3;

const styles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  document: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  page: {
    position: 'relative',
    '& > canvas, & > .react-pdf__Page__svg > svg, & > div': {
      width: '100% !important',
      height: 'auto !important',
    },
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const ReaderPDF = ({ source, setEnd, useWindow }) => {
  const classes = styles();
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [pages, setPages] = useState(0);
  const [pagesLoaded, setPagesLoaded] = useState(0);

  useEffect(() => {
    if (pagesLoaded === rowsPerPage) {
      setLoading(false);
    }
  }, [pagesLoaded]);

  useEffect(() => {
    if (pages === totalPages && totalPages !== 0) {
      setEnd();
    }
  }, [pages, totalPages]);

  const onSuccess = (document) => {
    const { numPages } = document;
    setLoading(false);
    setTotalPages(numPages);
  };

  const load = () => {
    if (!loading) {
      setPagesLoaded(0);
      setLoading(true);

      const nextPages = pages + rowsPerPage;
      setPages(nextPages < totalPages ? nextPages : totalPages);
    }
  };

  return (
    <div className={classes.container}>
      <Document
        className={classes.document}
        file={source}
        onLoadSuccess={onSuccess}
        renderMode="svg"
        loading={(
          <div className={classes.loadingContainer}>
            <Loading size={48} />
          </div>
        )}
      >
        <InfiniteScroll
          pageStart={0}
          loadMore={load}
          hasMore={pages < totalPages}
          loader={<Loading key={0} />}
          threshold={800}
          useWindow={useWindow}
        >
          {Array.from(
            new Array(pages),
            (page, index) => (
              <ReaderPDFPage
                key={`page_${index + 1}`}
                onLoad={() => setPagesLoaded(pagesLoaded + 1)}
                index={index}
              />
            ),
          )}
        </InfiniteScroll>
      </Document>
    </div>
  );
};

ReaderPDF.defaultProps = {
  useWindow: true,
};

ReaderPDF.propTypes = {
  source: PropTypes.string.isRequired,
  setEnd: PropTypes.func.isRequired,
  useWindow: PropTypes.bool,
};

export default ReaderPDF;
