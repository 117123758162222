import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeStyles,
  FormControl,
  FormLabel,
  CardContent,
  FormHelperText,
  Grid
} from '@material-ui/core';
import { KeyboardArrowRightRounded } from '@material-ui/icons';
import StandardLayout from '../components/Layout/StandardLayout';
import Input from '../components/Input/Input';
import Button from '../components/Button';
import Snackbar from '../components/Snackbar';
import Loading from '../components/Loading';
import useProtectRoute from '../providers/hooks/useProtectRoute';
import InputTextArea from '../components/Input/InputTextArea';
import GenresList from '../components/GenresList';
import AppText from '../components/AppText';
import Stepper from '../components/Stepper/Stepper';
import UploadChapters from '../components/UploadChapters';
import LayoutLight from '../components/Layout/LayoutLight';
import ResponsiveWrapper from '../components/ResponsiveWrapper';
import UploadImages from '../components/UploadImages';
import { storiesCreateRequest } from '../providers/redux/actions/Stories';
import InputEstimatedLength from '../components/Input/InputEstimatedLength';
import InputColor from '../components/Input/InputColor';
import SubGenresList from '../components/SubGenresList';
import Select from '../components/Select/Select';
import SelectLanguage from '../components/Select/SelectLanguage';
// import Scheduler from '../components/Scheduler';

const styles = makeStyles(theme => ({
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center'
  },
  header: {
    flex: 1,
    color: theme.palette.common.white,
    marginTop: theme.spacing()
  },
  subtitle: {
    marginTop: theme.spacing(2),
    color: '#8E8E8E'
  },
  footer: {
    borderTop: '1px solid #DEDEDE',
    marginTop: theme.spacing(5),
    paddingTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  page: {
    padding: theme.spacing(3)
  },
  input: {
    marginBottom: theme.spacing(4),
    '&::placeholder': {
      fontFamily: "'Montserrat', sans-serif"
    }
  },
  back: {
    marginRight: theme.spacing(),
    '& .MuiButton-label > p': {
      color: '#151515'
    }
  },
  tnc: {
    flex: 1,
    paddingRight: theme.spacing(2),
    fontSize: 13
  },
  tncLine: {
    display: 'block'
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  field: {
    flex: 1
  },
  selectField: {
    flex: 1,
    '& .MuiInputLabel-formControl': {
      top: -8,
      left: 12,
      backgroundColor: '#fff',
      zIndex: 1,
      padding: 4
    },
    '& .MuiInputBase-input': {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: '0.85em'
    }
  },
  label: {
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 600,
    fontSize: '0.85em',
    color: '#151515'
  },
  languageSelectInput: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  buttonText: {
    color: '#fff'
  },
  successText: {
    textAlign: 'center'
  }
}));

const labelStyles = makeStyles(theme => ({
  root: {
    fontFamily: "'Montserrat', sans-serif",
    marginBottom: theme.spacing(2),
    fontWeight: 600,
    fontSize: '0.85em',
    color: '#151515'
  }
}));

const PublishScreen = () => {
  useProtectRoute();

  const classes = styles();

  const { message, processing, success, errors } = useSelector(state => ({
    processing: state.storiesReducer.create.processing,
    success: state.storiesReducer.create.success,
    message: state.storiesReducer.create.message,
    errors: state.storiesReducer.create.errors
  }));

  const dispatch = useDispatch();

  const [currentStep, setCurrentStep] = useState(0);
  const [title, setTitle] = useState('');
  const [synopsis, setSynopsis] = useState('');
  const [coverImages, setCoverImages] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [estimatedLength, setEstimatedLength] = useState('0');
  const [color, setColor] = useState('0');
  const [genres, setGenres] = useState([]);
  const [subGenres, setSubGenres] = useState([]);
  const [contentRatings, setContentRatings] = useState('');
  const [language, setLanguage] = useState('');
  const [readingStyle, setReadingStyle] = useState('Vertical scroll');
  const [personalThoughts, setPersonalThoughts] = useState('');
  const [pageNum, setPageNumFromChild] = useState(1);
  // const [days, setDays] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (success) {
      setCurrentStep(2);
    }
  }, [success]);

  const {
    title: titleErrors,
    synopsis: synopsisErrors,
    color: colorErrors,
    chapters: chaptersErrors
  } = errors;
  const steps = [
    t('publish.steps.create'),
    t('publish.steps.upload'),
    t('publish.steps.review')
  ];

  const maxPages = 50;
  const maxPagesMsg = 'Maximum '+ maxPages +' pages per chapter';

  const isSubmitPage = currentStep === steps.length - 2;

  const isLastPage = currentStep === steps.length - 1;

  const isChecked = genre => genres.includes(genre);

  const isSubGenreChecked = genre => subGenres.includes(genre);

  const onCheck = genre => {
    if (isChecked(genre)) {
      setGenres(genres.filter(includedGenre => includedGenre !== genre));
      return;
    }

    const newGenres = [...genres];
    newGenres.push(genre);
    setGenres(newGenres);
  };

  const onCheckSubGenres = subGenre => {
    if (isSubGenreChecked(subGenre)) {
      setSubGenres(
        subGenres.filter(includedGenre => includedGenre !== subGenre)
      );
      return;
    }

    const newSubGenres = [...subGenres];
    newSubGenres.push(subGenre);
    setSubGenres(newSubGenres);
  };

  const submit = () =>
    dispatch(
      storiesCreateRequest({
        title,
        synopsis,
        genres,
        coverImages,
        chapters,
        estimatedLength,
        color,
        personalThoughts
      })
    );

  const isValidStep = step => {
    if (step === 0) {
      if (
        !title ||
        !synopsis ||
        genres.length === 0 ||
        !color
      ) {
        return false;
      }
    }

    if (isSubmitPage) {
      if (chapters.length === 0) {
        return false;
      }
    }

    return true;
  };

  const hasErrors = step => {
    if (step === 0) {
      return (
        titleErrors || synopsisErrors || colorErrors
      );
    }

    return false;
  };

  const onBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const onNext = () => {
    if (!isSubmitPage) {
      setCurrentStep(currentStep + 1);
      return;
    }

    submit();
  };

  const setPublishScreenPageNum = pageNumber => setPageNumFromChild(pageNumber);

  return (
    <StandardLayout>
      <Snackbar variant={success ? 'success' : 'error'} message={message} />
      <LayoutLight
        header={
          <div className={classes.headerContainer}>
            <AppText className={classes.header} header variant="h3">
              {t('publish.title')}
            </AppText>
            <AppText className={classes.subtitle} variant="h6">
              {t('publish.subtitle')}
            </AppText>
          </div>
        }
      >
        <ResponsiveWrapper>
          <Stepper
            hasErrors={hasErrors}
            steps={steps}
            submit={submit}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          >
            <Grid container spacing={1}>
              <Grid item md={12} sm={12} xs={12}>
                <Input
                  variant="outlined"
                  className={classes.input}
                  label={t('publish.fields.title')}
                  placeholder={t('publish.fields.title_placeholder')}
                  onChangeText={setTitle}
                  value={title}
                  error={titleErrors}
                  required
                  fullWidth
                  invert
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <FormControl className={classes.input}>
                  <FormLabel className={classes.label}>
                    {t('publish.fields.cover_image')}
                  </FormLabel>
                  <UploadImages
                    setFiles={setCoverImages}
                    files={coverImages}
                    multiple
                  />
                </FormControl>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <InputTextArea
                  variant="outlined"
                  className={classes.input}
                  label={t('publish.fields.plot_summary')}
                  placeholder={t('publish.fields.plot_summary_placeholder')}
                  onChangeText={setSynopsis}
                  value={synopsis}
                  error={synopsisErrors}
                  required
                  fullWidth
                  invert
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <FormControl fullWidth>
                  <Select
                    variant="outlined"
                    className={classes.selectField}
                    label={t('publish.fields.content_ratings')}
                    onChange={setContentRatings}
                    options={[
                      'All ages',
                      'Moderate (13 - 20)',
                      'Adult (21 and above)'
                    ]}
                    placeholder={t(
                      'publish.fields.content_ratings_placeholder'
                    )}
                    value={contentRatings}
                    fullWidth
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <FormControl fullWidth>
                  <SelectLanguage
                      label={t('publish.fields.language')}
                      className={classNames([classes.selectField])}
                      placeholder={t('publish.fields.language_placeholder')}
                      onChange={setLanguage}
                      value={language}
                      fullWidth
                      required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <FormControl fullWidth>
                  <Select
                    variant="outlined"
                    className={classes.selectField}
                    label={t('publish.fields.reading_style')}
                    onChange={setReadingStyle}
                    options={[
                      // 'Left to right',
                      // 'Right to left',
                      // 'Page by page',
                      'Vertical scroll'
                    ]}
                    // placeholder="Select one"
                    value={readingStyle}
                    disabled
                    fullWidth
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} style={{ paddingTop: 24 }}>
                <FormControl className={classNames([classes.input])} fullWidth>
                  <FormLabel classes={labelStyles()} required>
                    {t('publish.fields.genres')}
                  </FormLabel>
                  <GenresList values={genres} onCheck={onCheck} />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <FormControl className={classNames([classes.input])} fullWidth>
                  <FormLabel classes={labelStyles()} required>
                    {t('publish.fields.sub_genres')}
                  </FormLabel>
                  <SubGenresList
                    values={subGenres}
                    onCheck={onCheckSubGenres}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl
                  className={classes.field}
                  fullWidth
                >
                  <FormLabel classes={labelStyles()}>
                    {t('publish.fields.estimated_chapters')}
                  </FormLabel>
                  <InputEstimatedLength
                    setValue={setEstimatedLength}
                    value={estimatedLength}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl className={classes.field} error={colorErrors}>
                  <FormLabel classes={labelStyles()} required>
                    {t('publish.fields.color')}
                  </FormLabel>
                  <InputColor setValue={setColor} value={color} />
                  <FormHelperText error={colorErrors}>
                    {colorErrors}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <InputTextArea
                  variant="outlined"
                  className={classes.input}
                  label={t('publish.fields.personal_thought')}
                  onChangeText={setPersonalThoughts}
                  value={personalThoughts}
                  fullWidth
                  invert
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item md={12} sm={12} xs={12}>
                <UploadChapters
                  setFiles={setChapters}
                  files={chapters}
                  errors={chaptersErrors}
                  publishScreenCallback={setPublishScreenPageNum}
                />
                {/* <Scheduler */}
                {/*  setTime={() => {}} */}
                {/*  setStartingChapter={() => {}} */}
                {/*  setDays={setDays} */}
                {/*  setDate={() => {}} */}
                {/*  setFrequency={() => {}} */}
                {/*  days={days} */}
                {/* /> */}
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item md={12} sm={12} xs={12}>
                <AppText className={classes.successText} variant="body1">
                  {t('publish.success')}
                </AppText>
              </Grid>
            </Grid>
          </Stepper>
          {!isLastPage && (
            <CardContent className={classes.footer}>
              {isSubmitPage && (
                <AppText className={classes.tnc}>
                  <span className={classes.tncLine}>{t('publish.tnc')}</span>
                </AppText>
              )}
              {!processing && currentStep > 0 && (
                <Button className={classes.back} onPress={onBack}>
                  {t('publish.back')}
                </Button>
              )}
              {processing ? (
                <Loading size={48} />
              ) : (
                <>
                  {pageNum > maxPages ? (
                    <Snackbar
                      variant="error"
                      message={maxPagesMsg}
                    />
                  ) : null}
                  <Button
                    onPress={onNext}
                    variant="contained"
                    disabled={!isValidStep(currentStep) || pageNum > maxPages}
                    color="primary"
                  >
                    <AppText className={classes.buttonText}>
                      {t(
                        isSubmitPage ? t('publish.submit') : t('publish.next')
                      )}
                    </AppText>
                    <KeyboardArrowRightRounded className={classes.buttonText} />
                  </Button>
                </>
              )}
            </CardContent>
          )}
        </ResponsiveWrapper>
      </LayoutLight>
    </StandardLayout>
  );
};

export default PublishScreen;
