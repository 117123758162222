import React, {
  useCallback, useRef,
} from 'react';
import classNames from 'classnames';
import { CloudUploadRounded } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import AppText from './AppText';
import Constants from '../constants';

/* eslint-disable react/jsx-props-no-spreading */
const styles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 200,
    borderRadius: 8,
    color: '#598BA2',
    background: '#E9F1F8',
    overflow: 'hidden',
  },
  dragZone: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  dragging: {
    backgroundColor: 'rgba(0,255,255,.2)',
    border: '1px solid rgba(0,255,255, 1.0)',
  },
  icon: {
    color: '#598BA2',
    fontSize: 40,
  },
  text: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    textAlign: 'center',
  },
  input: {
    display: 'none',
  },
}));

const UploadZone = ({ className, setFiles, mimeTypes }) => {
  const onDrop = useCallback((acceptedFiles) => {
    const files = Object.values(acceptedFiles)
      .filter(({ type }) => mimeTypes.includes(type));
    setFiles(files);
  }, [setFiles]);
  const input = useRef();
  const zone = useRef();

  const onClick = () => input.current && input.current.click();
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const classes = styles();
  const { t } = useTranslation();

  return (
    <div
      tabIndex={-1}
      role="button"
      className={
        classNames([
          classes.container,
          isDragActive ? classes.dragging : null,
          className,
        ])
      }
      onClick={onClick}
      onKeyDown={onClick}
      {...getRootProps()}
    >
      <input
        className={classes.input}
        type="file"
        ref={input}
        {...getInputProps()}
        accept={mimeTypes.join(', ')}
        multiple
      />
      <div
        className={classes.dragZone}
        ref={zone}
      />
      <CloudUploadRounded className={classes.icon} />
      <AppText className={classes.text}>
        {t('publish.fields.upload_chapter_instruction')}
      </AppText>
    </div>
  );
};

UploadZone.defaultProps = {
  className: null,
  mimeTypes: Constants.files,
};

UploadZone.propTypes = {
  className: PropTypes.string,
  setFiles: PropTypes.func.isRequired,
  mimeTypes: PropTypes.array,
};

export default UploadZone;
