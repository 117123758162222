import React from 'react';
import PropTypes from 'prop-types';
import Reader from '../Reader';

const UploadChaptersPreview = ({ source }) => (
  <Reader
    source={source}
    useWindow={false}
  />
);

UploadChaptersPreview.propTypes = {
  source: PropTypes.string.isRequired,
};

export default UploadChaptersPreview;
