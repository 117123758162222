import { actions } from '../actions/Site';

const initialState = {
  login: false,
  header: null,
  updatePW: false,
};

const siteReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case actions.LOGIN.OPEN:
      return {
        ...state,
        login: true,
      };
    case actions.LOGIN.CLOSE:
      return {
        ...state,
        login: false,
      };
    case actions.LOGIN.TOGGLE:
      return {
        ...state,
        login: !state.login,
      };
    case actions.UPDATE_PW.OPEN:
      return {
        ...state,
        updatePW: true,
      };
    case actions.UPDATE_PW.CLOSE:
      return {
        ...state,
        updatePW: false,
      };
    case actions.UPDATE_PW.TOGGLE:
      return {
        ...state,
        updatePW: !state.updatePW,
      };
    case actions.HEADER.SET:
      return {
        ...state,
        header: action.payload,
      };
    case actions.HEADER.RESET:
      return {
        ...state,
        header: null,
      };
    default:
      return state;
  }
};

export default siteReducer;
