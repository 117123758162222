import {
  all, takeLatest, call, put,
} from 'redux-saga/effects';
import axios from 'axios';
import {
  actions,
  putPaymentMethods,
  transactionCreateSuccess,
} from '../actions/Payment';

const MIPayInstance = axios.create({
  baseURL: process.env.REACT_APP_MASSIVE_PAY_URL,
  headers: {
    'x-api-key': process.env.REACT_APP_MASSIVE_PAY_API_KEY,
  },
});

const APIInstance = axios.create({
  baseURL: process.env.REACT_APP_KOMAKAKU_API_URL,
});

const getPaymentMethodsCall = async () => MIPayInstance.get(
  `/adyen/komakaku/payment-methods?country_code=${process.env.REACT_APP_ADYEN_COUNTRY_CODE
  }&currency=${process.env.REACT_APP_ADYEN_CURRENCY
  }&amount=${process.env.REACT_APP_ADYEN_MAX_AMOUNT}`,
);

function* getPaymentMethodsSaga() {
  const response = yield call(getPaymentMethodsCall);
  yield put(putPaymentMethods(response.data.data));
}

const createTransactionCall = async ({
  payerId, amount, paymentMethodData, comment, payeeId,
}) => APIInstance.post(
  `/user/${payerId}/transaction`,
  {
    amount,
    currency_code: process.env.REACT_APP_ADYEN_CURRENCY,
    payment_method: paymentMethodData,
    comment,
    payeeId,
  },
);

function* createTransactionSaga({
  payload: {
    payerId, amount, paymentMethodData, comment, payeeId,
  },
}) {
  const response = yield call(createTransactionCall, {
    payerId, amount, paymentMethodData, comment, payeeId,
  });
  yield put(transactionCreateSuccess(response.data.results));
}

export default function* Payment() {
  yield all([
    takeLatest(actions.GET_PAYMENT_METHODS, getPaymentMethodsSaga),
    takeLatest(actions.TRANSACTION.REQUEST, createTransactionSaga),
  ]);
}
