import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      jp: {
        // eslint-disable-next-line global-require
        translation: require('../assets/translations/jp.json'),
      },
      en: {
        // eslint-disable-next-line global-require
        translation: require('../assets/translations/en.json'),
      },
    },
    // TODO: Replace with user's firebase settings.
    lng: 'en',
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false,
    },
  });
