import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import StandardLayout from '../components/Layout/StandardLayout';
import AkademyMasters from '../components/Akademy/AkademyMasters';
import MasterClass2 from '../assets/placeholders/masterclass-cover.jpg';
import ResponsiveWrapper from '../components/ResponsiveWrapper';
import constants from "../constants";

const MasterClass1 = constants.routes.FileStore + '/web_assets%2Fkoyama-masterclass.jpg?alt=media&token=15b86d71-84e2-4190-ab44-2d9deb140549';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(7),
  },
  title: {
    color: theme.palette.common.white,
  },
  subtitle: {
    color: '#8E8E8E',
    marginTop: theme.spacing(2),
  },
}));

const AkademyScreen = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <StandardLayout>
      <ResponsiveWrapper>
        <div className={classes.header}>
          <Typography className={classes.title} variant="h3">
            {t('akademy.title')}
          </Typography>
          <Typography className={classes.subtitle} variant="body1">
            {t('akademy.subtitle')}
          </Typography>
        </div>
        <div className={classes.content}>
          <AkademyMasters
            list={[
              {
                image: MasterClass1,
                uuid: 'koyama-chuya',
                name: 'Koyama Chuya',
                caption: 'Teaches the art of drawing',
              },
              {
                image: MasterClass2,
                uuid: 'master-placeholder',
                name: 'Upcoming Masterclasses',
                caption: 'in 2020',
              },
            ]}
          />
        </div>
      </ResponsiveWrapper>
    </StandardLayout>
  );
};

export default AkademyScreen;
