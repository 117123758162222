import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowDropDown } from '@material-ui/icons';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import UserAvatar from '../User/UserAvatar';
import Button from '../Button';
import HeaderLoginForm from './HeaderLoginForm';
import HeaderNavigationItem from './HeaderNavigation/HeaderNavigationItem';
import HeaderUserDropdown from './HeaderUserDropdown';

import {
  siteLoginClose,
} from '../../providers/redux/actions/Site';
import Constants from '../../constants';

const styles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    userSelect: 'none',
    webkitUserSelect: 'none',
    msUserSelect: 'none',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  button: {
    background: 'transparent',
  },
}));

const HeaderUser = ({ user, loggedIn, profile }) => {
  const dispatch = useDispatch();
  const classes = styles();
  const { t } = useTranslation();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const isDesktop = useMediaQuery({ minWidth: Constants.minWidth });

  useEffect(() => {
    if (loggedIn) {
      dispatch(siteLoginClose());
    }
  }, [loggedIn, dispatch]);

  if (!loggedIn || !user) {
    return (
      <div
        className={classNames([
          classes.container,
          classes[`containerSpace${isDesktop ? '' : 'M'}`],
        ])}
      >
        <HeaderNavigationItem
          link={Constants.routes.Login}
          text={t('login.title')}
        />
        <HeaderLoginForm />
      </div>
    );
  }

  const { displayName, email } = user;
  // Profile collection cannot be immediately retrieved during login or registration
  let profileImage = '';
  if(profile != null) {
    profileImage = profile.profileImage;
  }
  return (
    <div
      ref={anchorRef}
      className={classNames([
        classes.container,
        classes[`containerSpace${isDesktop ? '' : 'M'}`],
      ])}
    >
      <Button className={classes.button} onPress={() => setOpen(true)}>
        <UserAvatar name={displayName || email} image={profileImage} />
        <ArrowDropDown />
      </Button>
      <HeaderUserDropdown
        setClose={() => setOpen(false)}
        open={open}
        anchorRef={anchorRef}
      />
    </div>
  );
};

HeaderUser.defaultProps = {
  user: null,
  profile: null,
};

HeaderUser.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  user: PropTypes.object,
  profile: PropTypes.object,
};

const mapStateToProps = (state) => ({
  loggedIn: state.userReducer.loggedIn,
  user: state.userReducer.user,
  profile: state.userReducer.profile,
});

export default connect(mapStateToProps)(HeaderUser);
