export const actions = {
  ITEM: {
    PUT: 'AUTHORS_ITEM_PUT',
    PUT_STORIES: 'AUTHORS_ITEM_PUT_STORIES',
    POSTS: 'AUTHORS_ITEM_POSTS',
    GET: 'AUTHORS_ITEM_GET',
    RESET: 'AUTHORS_ITEM_RESET',
  },
};

export const authorsItemGet = ({ uuid }) => ({
  type: actions.ITEM.GET,
  payload: uuid,
});

export const authorsItemPut = (author) => ({
  type: actions.ITEM.PUT,
  payload: author,
});

export const authorsItemPutStories = (stories) => ({
  type: actions.ITEM.PUT_STORIES,
  payload: stories,
});

export const authorsItemPosts = (posts) => ({
  type: actions.ITEM.POSTS,
  payload: posts,
});

export const authorsItemReset = () => ({
  type: actions.ITEM.RESET,
});
